.overlay-breadcrumbs{
    position: absolute;
    top: calc( var(--header__secondary-nav-height) + var(--header__main-nav-height) + var(--breadcrumbs-height) );
    bottom: 0;
    left: 0;
    right: 0;

    visibility: hidden;
    pointer-events: none;

    z-index: 50;

    @media screen and (max-width: 1024px) { 
        top: calc( var(--header__main-nav-height) + var(--breadcrumbs-height) );
    }

    &__container{
        @extend .x-padding-180;
        background-color: white;
        position: relative;
        width: 100%;
        height: 0;
        max-width: 600px;
        overflow: hidden;
        z-index: 2;
    }

    &__transition{
        opacity: 0;
        transform: translate(0, 20px);
        transition: transform 0.3s $easeOutCubic 0.4s, opacity 0.3s $easeOutCubic 0.4s;
    }

    &__menu{
        padding-top: 35px;
        padding-bottom: 45px;
    }

    &__item{
        &:not(:first-child){
            padding-top: 15px;
        }
    }

    &__link{
        font-size: 1.4em;
        color: var(--color-primary);
        text-decoration: none;
        box-shadow: inset 0 -0.1em 0 0 var(--color-secondary);
        transition: box-shadow 0.3s;
        &:hover{
            box-shadow: inset 0 -1.1em 0 0 var(--color-secondary);
        }
        &--active{
            box-shadow: inset 0 -1.1em 0 0 var(--color-secondary);
        }
    }
}