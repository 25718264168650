:root{
    --back-to-top__size: 55px;
    --back-to-top__icon-size: 25px;
    --back-to-top__distance: 10px;
}


.back-to-top{

    position: absolute;
    top: 0;
    bottom: calc( var(--back-to-top__size) / -2 );
    width: calc(var(--back-to-top__size) + var(--back-to-top__distance));
    right: 0;
    z-index: 20;
    pointer-events: none;

    display: flex;
    align-items: flex-start;

    opacity: 0;
    transition: opacity 0.3s ease;

    &__button{
        width: var(--back-to-top__size);
        height: var(--back-to-top__size);
        position: sticky;
        bottom: 70px;
        align-self: flex-end;
        cursor: pointer;
        pointer-events: none;

        &::after{
            content: '';
            position: absolute;
            background-color: white;
            border: 1px solid var(--color-line);
            border-radius: 50%;
            transform: translate(-50%,-50%) scale(1);
            transition: transform 0.3s;
            left: 50%;
            top: 50%;
            width: 100%;
            height: 100%;
            z-index: 0;
            will-change: transform;
        }

        &:hover{
            &::after{
                transform: translate(-50%,-50%) scale(1.1);
            }
        }
    }

    &__svg{
        position: absolute;
        top: 50%;
        left: 50%;
        width: var(--back-to-top__icon-size);
        height: var(--back-to-top__icon-size);
        fill: var(--color-primary);
        transform: translate(-50%,-50%) rotate(-90deg);
        z-index: 1;
    }


}
