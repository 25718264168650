:root {
    // Bouton primaire
    --button-color: var(--color-primary);
    --button-round-size: 64px;

    // Bouton secondaire
    --button-secondary-color: var(--color-primary);
    --button-secondary-color-hover: var(--color-secondary);

    @media screen and (max-width: 768px) {
        --button-round-size: 48px;
    }
}

.dark{
    --button-color: white;
}

.btn,
.btn--secondary,
.btn--back,
.btn--map{
    color: var(--button-color);
    position: relative;
    font-weight: bold;
    font-size: 1.4rem;
    padding: 22px 32px;
    transition: color 0.3s, background-color 0.3s, border 0.3s;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    line-height: 1.3em;
    text-transform: uppercase;
    cursor: pointer;
    background: transparent;
    border: 0;

    @media screen and (max-width: 768px) {
        padding: 15px 25px;
    }

    span{
        position: relative;
        z-index: 1;
    }

    &:not(.btn--map) {
        &[target="_blank"],
        &[href$=".pdf"],
        &[href$=".doc"],
        &[href$=".zip"]{
            svg{
                fill: var(--button-color);
                display: inline-block;
                margin-left: 10px;
                transition: fill 0.3s;
                width: 11px;
                height: 11px;
                flex-shrink: 0;
                z-index: 1;
                position: relative;
            }
        }
    }

    &[href$=".pdf"],
    &[href$=".doc"],
    &[href$=".zip"]{
        svg{
            width: 12px;
            height: 12px;
        }
    }

    &::before{
        content: '';
        position: absolute;
        height: var(--button-round-size);
        width: var(--button-round-size);
        left: 0;
        border-radius: 100px;
        background-color: var(--color-tertiary);
        z-index: 0;
        transition: width 0.3s $easeInOutCubic, background-color 0.3s $easeInOutCubic;
    }

    &:hover{
        &::before{
            width: 100%;
        }
    }

    &.disabled {
        pointer-events: none;

        &::before{
            background-color: #696e79;
        }
    }
}

.btn--back{
    padding-left: 20px;
    .btn__back{
        display: inline-block;
        margin-right: var(--button-round-size);
        position: relative;
    }
    .btn__back-svg{
        position: absolute;
        fill: white;
        width: 25px;
        height: 22px;
        transform: translate(0,-50%) rotate(180deg);
        left: 0;
        top: 50%;

        @media screen and (max-width: 768px) {
            left: -6px;
            width: 19px;
            height: 16px;
        }
    }
}

.btn--map{
    padding-left: 20px;
    .btn__back{
        display: inline-block;
        margin-right: 30px;
        position: relative;
        width: 25px;
        height: 6px;

        @media screen and (max-width: 768px) { margin-right: 10px; }
    }
    .btn__back-svg{
        position: absolute;
        fill: white;
        width: 23px;
        height: 30px;
        transform: translate(-50%,-50%);
        left: 50%;
        top: 50%;

        @media screen and (max-width: 768px) {
            left: 4px;
            width: 25px;
            height: 23px;
        }
    }
}

// Bouton secondaire
.btn--secondary{
    color: var(--button-secondary-color);
    background-color: var(--button-secondary-background);
    padding: 22px 0;

    &::before{
        content: normal;
    }

    &:not([target="_blank"]):not([href$=".pdf"]):not([href$=".doc"]):not([href$=".zip"]){
        svg{
            fill: var(--color-secondary);
            display: inline-block;
            margin-left: 10px;
            transition: fill 0.3s, transform 0.3s;
            width: 17px;
            height: 17px;
            flex-shrink: 0;
            z-index: 1;
            position: relative;
        }
        &:hover{
            svg{
                transform: translate(10px, 0);
            }
        }
    }

    &:hover{
        color: var(--button-secondary-color-hover);
        svg{
            fill: var(--button-secondary-color-hover);
        }
    }
}

.hover-btn:hover{
    .btn,
    .btn--secondary,
    .btn--back,
    .btn--map{
        &::before{
            width: 100%;
        }
    }
}

// Bouton signalement
.button-online-requests {
    --button-online-requests-color-rgb: 255, 255, 255;
    display: flex;
    align-items: center;
    gap: 0 16px;
    padding: 10px 30px 10px 10px;
    width: fit-content;
    border-radius: 100px;
    border: 1px solid rgba(var(--button-online-requests-color-rgb), 0.3);
    text-decoration: none;
    transition: background-color 300ms, border-color 300ms;

    &:hover {
        background-color: var(--color-secondary);
        border-color: var(--color-secondary);
    }

    &__circle {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 38px;
        height: 38px;
        background-color: var(--color-secondary);
        border-radius: 50%;
        transition: background-color 300ms;
    }

    &:hover &__circle {
        background-color: rgb(255, 255, 255);
    }

    &__circle-svg {
        width: 20px;
        height: 20px;
        stroke: var(--color-primary);
    }

    &__text {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.2em;
        color: rgb(var(--button-online-requests-color-rgb));
        transition: color 300ms;
    }

    &:hover &__text {
        color: var(--color-primary);
    }
}
