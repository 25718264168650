:root{
    --address-search-field__height: 70px;
    --address-search-field__field-padding: 0 30px;
    --address-search-field__number-field-width: 180px;
    --address-search-field__submit-field-width: 70px;
    --address-search-field__font-size: 2.2rem;
    --address-search-field__icon-size: 25px;

    @media screen and (max-width: 1024px) { 
        --address-search-field__height: 60px;
        --address-search-field__font-size: 1.8rem;
        --address-search-field__icon-size: 22px;
    }

    @media screen and (max-width: 600px) { 
        --address-search-field__number-field-width: 130px;
    }
}

.address-search-field{
    width: 100%;
    position: relative;
    &__grid{
        display: flex;
        height: var(--address-search-field__height);
        border: 1px solid var(--color-primary);
        border-radius: var(--address-search-field__height);
        overflow: hidden;
        width: 100%;
    }

    &__number,
    &__street{
        .field__error{
            position: absolute;
            @media screen and (max-width: 1024px) { 
                font-size: 1rem;
            }
        }
    }

    &__number{
        width: var(--address-search-field__number-field-width);
        border-right: 1px solid var(--color-line);
    }

    &__street{
        flex-grow: 1;
        width: 0;
        padding-right: 0;
    }

    &__number-input,
    &__street-input,
    &__submit{
        background-color: white;
        height: 100%;
        border: 0;
        font-family: var(--font-main);
        color: var(--color-primary);
        font-size: var(--address-search-field__font-size);
        padding: var(--address-search-field__field-padding);
    }

    &__number-input,
    &__street-input{
        width: 100%;
    }

    &__submit{
        cursor: pointer;
        width: var(--address-search-field__submit-field-width);
        position: relative;
    }

    &__submit-svg{
        position: absolute;
        left: 50%;
        top: 50%;
        fill: var(--color-primary);
        height: var(--address-search-field__icon-size);
        width: var(--address-search-field__icon-size);
        transform: translate(-50%,-50%);
        transition: opacity 0.3s ease;
        opacity: 1;
    }

    &__submit:hover &__submit-svg{
        opacity: 0.6;
    }

    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--color-primary);
        opacity: 1;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 100px white inset !important;
        -webkit-text-fill-color: var(--color-primary) !important;
    }
}

.autocomplete-wrapper {

    // position: relative;

    .autocomplete-suggestions {

        text-align: left;
        background: #fff;
        overflow: auto;
        border: 1px solid black;

        @media screen and (max-width: 1300px) { 
            left: 0px;
            right: 0px;
            min-width: 100%;
        }
    }
    .autocomplete-suggestion {

        font: rem(20)/1 var(--font-main);
        padding: 7px 5px;
        white-space: nowrap;
        overflow: hidden;
        cursor: pointer;

        strong {
            color: var(--color-primary);
        }
    }
    .autocomplete-selected {

        background: #F0F0F0;
    }
}