:root{
    --event-info__icon-size: 25px;
    --event-info__margin: 95px;

    @media screen and (max-width: 1300px){
        --event-info__margin: 60px;
    }

    @media screen and (max-width: 1024px){
        --event-info__margin: 40px;
    }

    @media screen and (max-width: 600px){
        --event-info__margin: 50px;
    }
}

.event-info{
    border-top: 1px solid var(--color-primary);
    color: var(--color-primary);
    overflow: hidden;

    &__grid{
        display: flex;
        margin: 0 calc(var(--event-info__margin) / -2);

        @media screen and (max-width: 600px){
            flex-wrap: wrap;
        }
    }

    &__item{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 calc(var(--event-info__margin) / 2);
        padding-top: 50px;
        flex-grow: 1;
        flex-basis: 0;

        @media screen and (max-width: 768px){
            padding-top: 30px;
        }

        @media screen and (max-width: 600px){
            width: 50%;
            flex-basis: auto;
        }
    }

    &__icon{
        width: var(--event-info__icon-size);
        height: var(--event-info__icon-size);
        position: relative;
        fill: var(--color-primary);
    }

    &__text{
        font-size: 1.6rem;
        line-height: 1.375em; //22/16
        font-weight: 500;
        padding-top: 20px;
        text-align: center;
    }
}