:root{
    --pagination__color: var(--color-primary);
    --pagination__padding-top: var(--y-padding-130);
}

.dark{
    --pagination__color: white;
}

.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: var(--pagination__padding-top);

    &__item{
        margin: 0 7px;
    }

    &__button,
    &__text{
        color: var(--pagination__color);
        text-decoration: none;
        font-size: 1.8rem;
        font-weight: 500;
        min-width: 14px;
        position: relative;
        display: block;
        text-align: center;
        padding: 6px 0;

        &::after{
            content: '';
            background-color: transparent;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 2px;
            transition: background-color 0.3s ease;
        }

        &:hover:not(span),
        &--active{
            &::after{
                background-color: var(--color-secondary);
            }
        }
    }

    &__button {
        cursor: pointer;
    }


    &__svg{
        width: 15px;
        height: 13px;
        fill: var(--pagination__color);
    }

    &__item--prev{
        margin-right: 16px;
    }
    &__item--next{
        margin-left: 16px;
    }
    &__item--prev .pagination__svg{
        transform: rotate(180deg);
    }

    &__button--inactive {
        cursor: default;

        .pagination__svg{
            opacity: 0.3;
        }
    }

    &--no-top-padding-mobile{
        @media screen and (max-width: 768px) {
            padding-top: 0;
        }
    }
}