:root{
    --news-list__margin-x: 50px; 
    --news-list__margin-y: 90px;

    @media screen and (max-width: 1300px){
        --news-list__margin-x: 30px; 
        --news-list__margin-y: 60px;
    }

    @media screen and (max-width: 768px){
        --news-list__margin-x: 0;
    }
}

.news-list{
    --card__ratio: 142%; //506px 355px
    @media screen and (max-width: 768px){
        --card__ratio: 110%;
    }

    &__offset{
        margin-top: -435px;
    }
    &__flex{
        display: flex;
        margin: 0 calc(var(--news-list__margin-x) / -2);
        margin-top: calc(var(--news-list__margin-y) * -1);
        flex-wrap: wrap;
    }
    &__item{
        width: 33.3333%;
        padding: 0 calc(var(--news-list__margin-x) / 2);
        padding-top: var(--news-list__margin-y);

        @media screen and (max-width: 1024px){
            width: 50%;
        }

        @media screen and (max-width: 768px){
            width: 100%;
        }

        @media screen and (max-width: 500px){
            &:first-child{
                padding-top: 0;
            }
        }
    }
}