.footer{

    --footer__margin: 2%;

    --logo__color-1: #59873a;
    --logo__color-2: #044775;
    --logo__color-3: #24272a;

    @extend .x-padding-40;
    @extend .top-padding-80;
    @extend .bottom-padding-40;
    color: var(--color-primary);
    border-top: 1px solid var(--color-line);

    &__grid{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 50px 0;
        margin-left: calc(var(--footer__margin) * -1);
        margin-right: calc(var(--footer__margin) * -1);
        align-items: center;

        @media screen and (max-width: 1300px) {
            flex-direction: column;
            align-items: normal;
            justify-content: normal;
        }
    }

    &__left,
    &__right{
        padding: 0 calc(var(--footer__margin));
    }

    &__left {
        display: flex;
        align-items: flex-end;
        gap: 40px var(--x-padding-50);

        @media screen and (max-width: 1300px)  {
            order: 2;
        }

        @media screen and (max-width: 600px)  {
            flex-direction: column;
            align-items: normal;
        }
    }

    &__logo-link{
        display: inline-block;
    }

    &__logo-svg{
        width: 283px;
        height: 71px;
        fill: var(--color-primary);

        @media screen and (max-width: 768px)  {
            width: 225px;
            height: 54px;
        }
    }

    &__logo-2-link {
        display: block;
        vertical-align: bottom;
        width: 340px;

        @media screen and (max-width: 768px)  {
            width: 260px;
        }
    }

    &__logo-2-img {
        max-width: 100%;
    }

    &__contact-button{
        //@extend .top-padding-40;
        margin-right: 30px;
        margin-top: auto;
        padding: 20px 0;

        @media screen and (max-width: 768px)  {
            margin-right: 0;
            //margin-bottom: 30px;
            width: 100%;
        }
    }

    &__social{
        //@extend .top-padding-40;
    }

    &__newsletter-over-title{
       font-size: 1.6em;
       margin-bottom: 25px;
    }

    &__newsletter-title{
        @extend .large-title;
        font-weight: normal;
        max-width: 390px;
    }

    &__newsletter-form{
        @extend .top-padding-50;
    }

    &__subfooter{
        @extend .top-padding-80;
        font-size: 1.2em;
        display: flex;
        flex-wrap: wrap;
    }

    &__subfooter-copyright, &__subfooter-author{
        margin-right: 60px;
        @media screen and (max-width: 768px)  {
            width: 100%;
            padding-bottom: 8px;
        }
    }

    &__subfooter-link{
        text-decoration: none;
        color: var(--color-primary);
        opacity: 1;
        transition: opacity 0.3s;
        &:hover{
            opacity: 0.6;
        }
    }

    &__right-grid{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

}
