:root{
    --filter__font-size: 3rem;
    --filter__padding-x: 50px;
    --filter__padding-y: 28px;
    --filter__icon-size: 75px;
    --filter__icon-svg-size: 30px;

    @media screen and (max-width: 1024px){
        --filter__icon-size: 60px;
        --filter__icon-svg-size: 26px;
    }
    @media screen and (max-width: 768px){
        --filter__font-size: 2.5rem;
        --filter__padding-x: 30px;
    }
    @media screen and (max-width: 600px){
        --filter__font-size: 1.5rem;
    }
}

.filter-container{
    overflow-x: scroll;
    overflow-y: hidden;

    margin-left: calc(var(--filter__padding-x) / -2);
    margin-right: calc(var(--filter__padding-x) / -2);

    &::-webkit-scrollbar { height: 0px !important; }
}

.filter{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    //margin: calc(var(--filter__padding-y) /-2) calc(var(--filter__padding-x) /-2);

    &__item{
        padding: calc(var(--filter__padding-y) /2) calc(var(--filter__padding-x) /2);
    }

    &__link{
        color: var(--color-primary);
        font-size: var(--filter__font-size);
        text-decoration: none;
        position: relative;
        display: block;
        cursor: none;
        padding-bottom: 6px;
        transition: color 0.3s;

        &--active::after{
            content: '';
            position: absolute;
            left: 0;
            bottom: -5px;
            height: 3px;
            width: 100%;
            background-color: var(--color-secondary);
        }

        &:hover .filter__link-icon{
            opacity: 1;
            &::after{
                transform: translate(-50%,-50%) scale(1);
            }
        }
    }

    &__link-icon{
        position: absolute;
        display: block;
        width: var(--filter__icon-size);
        height: var(--filter__icon-size);
        left: calc(var(--filter__icon-size)/-2);
        top: calc(var(--filter__icon-size)/-2);
        opacity: 0;
        transition: opacity 0.3s ease;
        pointer-events: none;
        z-index: 10;

        &::after{
            content: '';
            position: absolute;
            display: block;
            width: 99%;
            height: 99%;
            transform: translate(-50%,-50%) scale(0.9);
            transition: transform 0.2s $easeInOutBack;
            border-radius: 50%;
            left: 50%;
            top: 50%;
            background-color: var(--color-tertiary);
        }
    }

    &__svg{
        width: var(--filter__icon-svg-size);
        height: var(--filter__icon-svg-size);
        position:absolute;
        left: 50%;
        top: 50%;
        // fill: var(--color-primary);
        transform: translate(-50%,-50%);
        z-index: 11;

        fill: transparent;
        stroke: var(--color-primary);
        stroke-width: 5px;
    }


    &--alt-style{
        --filter__font-size: 1.8rem;
        --filter__padding-x: 45px;
        justify-content: flex-start;
        /*margin-left: calc(var(--filter__padding-x) / -2);
        /*margin-right: calc(var(--filter__padding-x) / -2);*/
        white-space: nowrap;
        flex-wrap: nowrap;
    }

    &--alt-style &__item{
        padding-bottom: 0;
    }

    &--alt-style &__link{
        cursor: pointer;
        padding-bottom: 20px;
        font-weight: 500;
        &::after{
            bottom: 0;
        }
        &:hover{
            color: rgba(var(--color-primary-rgb),0.5);
        }
    }

    &__prev,
    &__next{
        position: absolute;
        transform: translate(0, calc(-50% + 30px));
        left: 0;
        top: 50%;
        width: 40px;
        height: 40px;
        z-index: 1;
        pointer-events: none;
        opacity: 0;
        transition: opacity 0.3s;
        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background-color: var(--color-primary);
            border-radius: 50%;
            cursor: pointer;
            transform: scale(1);
            transition: transform 0.3s, opacity 300ms;
        }

        &:hover{
            &::after{
                transform: scale(1.1); 
            }
        }

        @media screen and (max-width: 1024px){
            transform: translate(0, calc(-50% + 20px));
        }
    }

    &__prev--is-visible,
    &__next--is-visible{
        pointer-events: all !important;
        opacity: 1 !important;
    }

    &__next{
        left: auto;
        right: 0;
    }
    &__arrow{
        position: absolute;
        left: 50%;
        top: 50%;
        width: 20px;
        height: 20px;
        fill: white;
        transform: translate(-50%,-50%);
        z-index: 2;
        pointer-events: none;
    }
    &__prev &__arrow{
        transform: translate(-50%,-50%) rotate(180deg);
    }

}