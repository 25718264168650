/*
|--------------------------------------------------------------------------
| RUBRIQUE OUVERTE
|--------------------------------------------------------------------------
*/
.rubric.js-accordion-opened {

    .rubric__transition {
        opacity: 1;
        transform: none;
        transition: opacity 700ms $easeInOutQuart 100ms, transform 700ms $easeInOutQuart 100ms;
    }

    .rubric__share {
        opacity: 1;
        transition: opacity 700ms $easeInOutQuart 200ms;
    }

    .rubric__plus {
        transform: translateY(-50%) rotate(-45deg);
    }
}