:root{
    --share__size: 60px;
    --share__icon-size: 22px;

    @media screen and (max-width: 1024px) {
        --share__size: 50px;
        --share__icon-size: 18px;
    }

    @media screen and (max-width: 768px) {
        --share__size: 45px;
        --share__icon-size: 16px;
    }
}

.share{
    display: block;
    position: relative;
    cursor: pointer;
    width: var(--share__size);
    height: var(--share__size);

    &__text{
        white-space: nowrap;
        position: absolute;
        visibility: hidden;
    }

    &__icon{
        position: absolute;
        width: var(--share__icon-size);
        height: var(--share__icon-size);
        fill: var(--color-primary);
        transition: fill 0.3s;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) scale(1);
        z-index: 2;
    }

    &::after{
        content: '';
        width: 100%;
        height: 100%;
        background-color: white;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        transition: background-color 0.3s, transform 0.3s;
        z-index: 1;
        will-change: transform;
    }

    &:hover{
        .share__icon{
            fill: white;
        }
        &::after{
            background-color: var(--color-primary);
            transform: translate(-50%,-50%) scale(1.1);
        }
    }
}