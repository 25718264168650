:root{
    --calendar-button__height: 90px;
    --calendar-button__padding: 15px;
    --calendar-button__font-size: 1.4em;
    --calendar-button__icon-size: 22px;

    @media screen and (max-width: 768px) {
        --calendar-button__height: 60px;
        --calendar-button__padding: 7px;
        --calendar-button__font-size: 1em;
        --calendar-button__icon-size: 16px;
    }
}

.calendar-button{

    border: 1px solid var(--color-line);
    border-radius: 45px;
    background-color: white;
    height: var(--calendar-button__height);
    padding: var(--calendar-button__padding);
    display: flex;
    align-items: center;
    font-size: var(--calendar-button__font-size);
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    color: var(--color-primary);
    cursor: pointer;

    &__icon{
        display: block;
        width: calc(var(--calendar-button__height) - (var(--calendar-button__padding) * 2));
        position: relative;
        margin-right: var(--calendar-button__padding);
    }

    &__icon-wrapper{
        display: block;
        width: 100%;
        padding-top: 100%;
        position: relative;
        background-color: var(--color-background);
        border-radius: 50%;
        transition: background-color 0.3s ease;
    }

    &__svg{
        left: 50%;
        top: 50%;
        position: absolute;
        fill: var(--color-primary);
        width: var(--calendar-button__icon-size);
        height: var(--calendar-button__icon-size);
        transform: translate(-50%,-50%);
        opacity: 1;
        transition: opacity 0.3s ease;

    }

    &__close{
        left: 50%;
        top: 50%;
        position: absolute;
        width: var(--calendar-button__icon-size);
        height: var(--calendar-button__icon-size);
        transform: translate(-50%,-50%);
        opacity: 0;
        transition: opacity 0.3s ease;

        &::before,
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) rotate(0);
            background-color: var(--color-primary);
            width: var(--calendar-button__icon-size);
            height: 2px;
            transition: transform 0.3s ease;
            will-change: transform;
        }
    }

    &__text{
        margin-right: var(--calendar-button__padding);
        white-space: nowrap;
        position: relative;
    }

    &__text-show{
        opacity: 1;
        transition: opacity 0.3s ease;
    }

    &__text-hide{
        position: absolute;
        left: 0;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    &:hover &__icon-wrapper{
        background-color: var(--color-tertiary);
    }

}