.breadcrumbs--open{

    .breadcrumbs__current{
        border: 1px solid transparent;
        box-shadow: none !important;
        border-bottom: 1px solid var(--color-line)!important;
        border-radius: 8px 8px 0 0;
    }

    .breadcrumbs__current-arrow{
        transform: translate(-50%, -50%) rotate(270deg);
    }

    .breadcrumbs__drop-container{
        box-shadow: var(--breadcrumbs__shadow);
    }

}