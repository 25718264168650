:root{
    --jobs-top__title-size: 10rem;
}

.jobs-top{
    @extend .x-padding-40;
    @extend .top-padding-40;
    position: relative;
    z-index: 2;
    border-bottom: 1px solid var(--color-line);

    &__wrapper{
        @extend .content-wrap;
    }

    &__text{
        position: relative;
    }

    &__content{
        @extend .bottom-padding-50;
        padding-right: 180px;
        @media screen and (max-width: 768px) {
            padding-right: 0;
        }
    }

    &__call-to-action{
        position: absolute;
        right: -120px;
        top: 0;

        @media screen and (max-width: 1450px){ right: -60px; }
        @media screen and (max-width: 1300px){ right: -30px; }
        @media screen and (max-width: 1250px){ right: 0; }
        @media screen and (max-width: 768px) { display: none; }
    }

    &__title{
        @extend .huge-title;
        @extend .bottom-padding-60;
        color: var(--color-primary);
        font-size: var(--jobs-top__title-size);
        @media screen and (max-width: 1024px) { font-size: 6rem; }
        @media screen and (max-width: 768px) { font-size: 5rem; }
        @media screen and (max-width: 425px) { font-size: 4rem; }
    }

    &__filter-title{
        font-size: 1.2rem;
        text-transform: uppercase;
        color: var(--color-primary);
    }

}