:root{
    --overlay-menu__top-spacing: 105px;
    --overlay-menu__item-height: 70px;
    --overlay-menu__transition-time: 0.6s;
}

.overlay-menu {

    --x-padding: 40px;
    @media screen and (max-width: 800px) { --x-padding: 30px; }

    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    visibility: hidden;
    pointer-events: none;
    z-index: 10;

    &__container {
        position: absolute;
        width: 100vw;
        padding-top: var(--overlay-menu__top-spacing);
        top: 0;
        left: -100vw;
        bottom: 0;
        background: var(--color-primary);
        overflow-y: auto;
		overflow-x: hidden;
        scrollbar-width: none;
        transition: left 800ms $easeInOutQuart;

        &::-webkit-scrollbar { display: none; }
    }

    &__container-inner{
        width: 200%;
        display: flex;
        height: 100%;
        transition: transform var(--overlay-menu__transition-time) $easeInOutQuart;
    }

    &__level-1,
    &__level-2{
        width: 100%;
        height: 100%;
        padding: 0 var(--x-padding-40);
        background-color: var(--color-primary);
        overflow: scroll;
        &::-webkit-scrollbar { display: none; }
    }

    &__level-1{
        display: flex;
        flex-direction: column;
    }

    &__level-1-top{
        flex-grow: 1;
    }

    &__level-1-nav{
        padding: 50px 0;
    }

    &__level-1-nav-item{
        border-bottom: 1px solid rgba(255,255,255,0.3);
        &:not(:first-child){
            padding-top: 20px;
        }
    }

    &__level-1-nav-link{
        color: white;
        font-size: 3rem;
        display: flex;
        cursor: pointer;
        align-items: center;
        height: var(--overlay-menu__item-height);
    }

    &__level-1-nav-text{
        padding-right: 15px;
    }

    &__level-1-nav-icon{
        fill: white;
        width: 15px;
        height: 13px;
        margin-left: auto;
    }

    &__button-online-requests {
        padding-bottom: 50px;
    }

    &__level-1-bottom{
        background-color: white;
        padding: var(--y-padding-30) var(--x-padding-40);
        margin: 0 calc(var(--y-padding-30)*-1);
        flex-shrink: 0;
    }
    &__cta{
        margin-top: var(--y-padding-30);
    }
    &__cta-item{
        &:not(:first-child){margin-top: 16px;}
    }
    &__cta-link{
        font-size: 1.1em;
        color: var(--color-primary);
        font-weight: bold;
        letter-spacing: 0.1em;
        text-decoration: none;
        text-transform: uppercase;
        display: flex;
        align-items: center;
    }

    &__cta-icon{
        fill: var(--color-primary);
        width: 12px;
        height: 12px;
    }

    &__cta-text{
        margin-left: 6px;
    }

    &__level-2{
        padding-top: 30px;
    }

    &__level-2-wrapper{
        position: relative;
    }

    &__level-2-section{
        visibility: hidden;
        position: absolute;
        transition: visibility 0s ease var(--overlay-menu__transition-time), position 0s ease var(--overlay-menu__transition-time), height 0s ease var(--overlay-menu__transition-time);
        width: 100%;
        top: 0;
        height: 0;
        overflow: hidden;
    }

    &__active-section{
        font-size: 3rem;
        color: white;
        padding-bottom: 25px;
    }

    &__back{
        display: inline-flex;
        align-items: center;
        cursor: pointer;
    }

    &__back-text{
        color: white;
        font-size: 1.5rem;
        font-weight: 500;
        padding-left: 20px;
    }

    &__back-icon{
        fill: var(--color-secondary);
        width: 15px;
        height: 13px;
        transform: rotate(-180deg);
    }

    &__level-2-nav{
        padding-top: 30px;
        padding-bottom: 50px;
    }

    &__level-2-sub-section{
        &:not(:first-child){margin-top: 50px;}
    }

    &__level-2-sub-section-link{
        text-decoration: none;
    }

    &__level-2-sub-section-title{
        color: white;
        font-size: 2rem;
        font-weight: 500;
        padding-bottom: 10px;
        border-bottom: 2px solid rgba(255,255,255,0.2);
    }

    &__level-2-sub-section-list{
        margin-top: 30px;
    }

    &__level-2-nav-item{
        &:not(:first-child){ padding-top: 20px; }
    }

    &__level-2-nav-link{
        font-size: 1.5rem;
        color: white;
        text-decoration: none;
    }
}
