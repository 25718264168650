:root{
    --header-menu-color: var(--color-primary);
    --header-logo-color: var(--color-primary);

    --header-buttons-color: var(--color-primary);
    --header-buttons-background: white;
    --header-buttons-color-hover: white;
    --header-buttons-background-hover: var(--color-primary);

    --header-buttons-size: 55px;

    @media screen and (max-width: 1024px) {
        --header-buttons-size: 45px;
    }
}

.show-overlay-dropdown{

    --logo__color-1: white;
    --logo__color-2: white;
    --logo__color-3: white;

    --header-menu-color: white;
    --header-buttons-color: white;

    --header-buttons-color: white;
    --header-buttons-background: var(--color-primary);
    --header-buttons-color-hover: var(--color-primary);
    --header-buttons-background-hover: white;
}

.dark-header{

    --logo__color-1: white;
    --logo__color-2: white;
    --logo__color-3: white;

    @media screen and (max-width: 1024px) {
        --header-menu-color: white;
        --header-buttons-color: white;

        --header-buttons-color: white;
        --header-buttons-background: var(--color-primary);
        --header-buttons-color-hover: var(--color-primary);
        --header-buttons-background-hover: white;
    }
}

.show-overlay-search{
    .dark-header{
        @media screen and (max-width: 1024px) {

            --logo__color-1: #59873a;
            --logo__color-2: #044775;

            --header-menu-color: var(--color-primary);

            --hamburger-line-color: var(--color-primary);
            --hamburger-line-color-hover: white;

            --header-buttons-background: white;
            --header-buttons-color-hover: white;
            --header-buttons-background-hover: var(--color-primary);
        }
    }
}

.show-overlay-menu,
.show-overlay-menu .dark-header{
    @media screen and (max-width: 1024px) {

        --logo__color-1: white;
        --logo__color-2: white;
        --logo__color-3: white;

        --header-menu-color: white;

        --hamburger-line-color: white;
        --hamburger-line-color-hover: var(--color-primary);

        --header-buttons-background: var(--color-primary);
        --header-buttons-color-hover: var(--color-primary);
        --header-buttons-background-hover: white;

        --hamburger-line-color: white !important;
        --hamburger-line-color-hover: var(--color-primary) !important;
    }
}

.header{

    @extend .x-padding-40;
    @extend .y-padding-40;
    position: relative;
    z-index: 75; //Les overlays ont a peu près un z-index de 50
    transition: background-color 0.3s ease 0.3s;

    &__container{
        display: flex;
        align-items: center;
    }

    &__mobile-menu{
        display: none;
        @media screen and (max-width: 768px) {
            display: block;
        }
    }

    &__logo{
        display: flex;
        align-items: center;
        margin-right: 30px;
        @media screen and (max-width: 768px) {
            margin-left: 20px;
            margin-right: 20px;
            flex-grow: 1;
            justify-content: center;
        }
    }

    &__logo-link{
        display: block;
        display: flex;
    }

    &__logo-svg,
    &__logo-svg-mobile{
        transition: fill 0.3s;
        path{ transition: fill 0.3s ease; }
    }

    &__logo-svg{
        display: block;
        width: 240px;
        max-width: 100%;
        @media screen and (max-width: 768px) { display: none; }
    }
    &__logo-svg-mobile{
        display: none;
        width: 60px;
        @media screen and (max-width: 768px) {
            display: block;
            height: var(--header-buttons-size);
        }
    }

    &__button-online-requests {
        flex-shrink: 0;
        align-self: flex-end;
        transition: opacity 0.3s ease;
        margin-right: 30px;

        @media screen and (max-width: 1100px) {
            .button-online-requests {
                width: 55px;
                height: 55px;
                padding: 0;
                border: 0;
            }

            .button-online-requests__circle {
                width: 100%;
                height: 100%;
            }

            .button-online-requests__text {
                display: none;
            }
        }

        @media screen and (max-width: 1024px) {
            .button-online-requests {
                width: 45px;
                height: 45px;
            }
        }

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    &:not(&--home) {
        .button-online-requests {
            --button-online-requests-color-rgb: var(--color-primary-rgb);
        }
    }


    &__right{
        margin-left: auto;
        display: flex;
        align-items: center;
    }

    &__nav{
        display: flex;
        border-top: 1px solid var(--color-line);
        margin-right: 48px;
        opacity: 1;
        transition: opacity 0.3s ease;

        @media screen and (max-width: 850px) {
            margin-right: 30px;
        }
        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    &__nav-item{
        display: flex;
        align-items: center;

        &:not(:first-child){
            padding-left: 18px;
        }
    }

    &__nav-link{
        font-size: 2rem;
        color: var(--header-menu-color);
        text-decoration: none;
        padding: 18px 18px 0 18px;
        position: relative;
        cursor: pointer;
        transition: color 0.3s;

        @media screen and (max-width: 850px) {
            padding: 18px 12px 0 12px;
        }

        &::after{
            content: '';
            position: absolute;
            top: -1px;
            left: 0;
            right: 0;
            height: 0;
            opacity: 0;
            background-color: var(--color-secondary);
            transition: height 0.3s $easeOutQuart, opacity 0.3s;
        }

        &:hover{
            &::after{
                opacity: 1;
                height: 4px;
            }
        }
    }

    &__search-button-link{
        width: var(--header-buttons-size);
        height: var(--header-buttons-size);
        position: relative;
        display: block;
        cursor: pointer;

        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border: 1px solid var(--color-line);
            border-radius: 50%;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%) scale(1);
            transition: transform 0.3s $easeOutQuart, background-color 0.3s;
            z-index: -1;
            will-change: transform;
            background-color: var(--header-buttons-background);
        }
    }

    &__search-button-svg{
        width: 18px;
        height: 18px;
        fill: var(--header-menu-color);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        transition: fill 0.3s, opacity 0.3s;
        z-index: 2;
        opacity: 1;
    }

    &__search-button-close{
        width: 18px;
        height: 18px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        z-index: 2;
        opacity: 0;
        transition: opacity 0.3s;

        &::before,
        &::after{
            content: '';
            position: absolute;
            width: 18px;
            height: 2px;
            left: 50%;
            top: 50%;
            position: absolute;
            background-color: var(--header-menu-color);
            transform: translate(-50%,-50%) rotate(45deg);
            transition: background-color 0.3s;
        }

        &::after{
            transform: translate(-50%,-50%) rotate(-45deg);
        }
    }

    &__search-button-link:hover{
        .header__search-button-svg{
          fill: var(--header-buttons-color-hover);
        }
        .header__search-button-close::before,
        .header__search-button-close::after{
            background-color: var(--header-buttons-color-hover);
        }
        &::after{
            border: 1px solid var(--header-buttons-background-hover);
            background-color: var(--header-buttons-background-hover);
            transform: translate(-50%,-50%) scale(1.1);
        }
    }

}

