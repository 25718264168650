/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-dropdown {
    .overlay-dropdown {
        visibility: visible;
        pointer-events: all;

        &__section { transition: opacity 800ms $easeInOutQuart, visibility 0ms ease 800ms; }

        &__sub-section-container { transition: transform 800ms $easeInOutQuart; }

        &__background{
            opacity: 1;
        }
    }
}


/*
|--------------------------------------------------------------------------
| SECTION VISIBLE
|--------------------------------------------------------------------------
*/
@mixin section($section) {
    .show-overlay-dropdown[data-section="#{$section}"] {
        .header {
            [data-section="#{$section}"]::after {
                opacity: 1;
                height: 4px;
            }
        }
        
        .overlay-dropdown [data-section="#{$section}"] {
            opacity: 1;
            visibility: visible;
            transition: opacity 800ms $easeInOutQuart;

            .overlay-dropdown__sub-section-container {
                transform: none;
                transition: transform 800ms $easeInOutQuart;
            }
        }
    }
}

@include section(ville);
@include section(services);
@include section(loisirs);


/*
|--------------------------------------------------------------------------
| OVERLAY QUI FERME
|--------------------------------------------------------------------------
*/
.show-overlay-dropdown.closing-overlay-dropdown{
    .overlay-dropdown{

        .overlay-dropdown__background{
            opacity: 0;
            transition: opacity 0.5s 0.3s;
        }

    }
}