:root{
    --scroll-to-form__size: 160px;
    --scroll-to-form__icon-size: 25px;
    --scroll-to-form__font-size: 1.6rem;

    @media screen and (max-width: 1024px){
        --scroll-to-form__size: 140px;
        --scroll-to-form__icon-size: 22px;
        --scroll-to-form__font-size: 1.4rem;
    }

    @media screen and (max-width: 768px){
        --scroll-to-form__size: 120px;
        --scroll-to-form__font-size: 1.2rem;
    }
}

.scroll-to-form{
    position: absolute;
    width: var(--scroll-to-form__size);
    height: var(--scroll-to-form__size);
    transform: translate(-50%, -50%);
    font-size: var(--scroll-to-form__font-size);
    font-weight: bold;
    color: var(--color-primary);
    cursor: pointer;

    &__content{
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 100%;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    &__svg{
        width: var(--scroll-to-form__icon-size);
        height: var(--scroll-to-form__icon-size);
        fill: var(--color-primary);
    }

    &__text{
        margin-top: 10px;
    }

    &::after{
        content: '';
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: var(--color-tertiary);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) scale(1);
        transition: transform 0.3s ease;
        z-index: 1;
        will-change: transform;
    }

    &:hover{
        &::after{
            transform: translate(-50%,-50%) scale(1.08);
        }
    }
}