.home-cards{
    --home-cards__margin: 30px;

    @extend .top-padding-170;
    @media screen and (max-width: 1024px){ padding-top: 30px; }
    @media screen and (max-width: 768px){ --home-cards__margin: 0; }

    &__grid{
        display: flex;
        flex-wrap: wrap;
        margin-left: calc(var(--home-cards__margin) * -1);
        margin-right: calc(var(--home-cards__margin) * -1);
    }

    &__left{
        width: 45%;
        padding: 0 calc(var(--home-cards__margin));

        @media screen and (max-width: 1024px){
            width: 50%; 
        }

        @media screen and (max-width: 768px){
            width: 100%; 
        }
    }

    &__text{

        --circle-size: 800px;
        @media screen and (max-width: 1024px){
            --circle-size: 600px;
        }

        @media screen and (max-width: 768px){
            padding-bottom: var(--y-padding-100);
        }

        @extend .bottom-padding-70;
        padding-left: 30px;
        padding-right: 30px;
        position: relative;

        &::after{
            content: '';
            position: absolute;
            background-color: white;
            border-radius: 50%;
            width: var(--circle-size);
            height: var(--circle-size);
            left: -190px;
            top: -140px;
            z-index: 0;

            @media screen and (max-width: 1024px){
                left: -100px;
                top: -90px;
            }

            @media screen and (max-width: 768px){
                left: 0;
                top: calc(var(--y-padding-100) * -1);
                width: 45%;
                padding-bottom: 45%;
                height: 0;
            }
        }
    }

    &__text-dynamic{
        z-index: 1;
        position: relative;
    }

    &__right{
        width: 55%;
        padding: 0 calc(var(--home-cards__margin));

        @media screen and (max-width: 1024px){
            width: 50%; 
        }

        @media screen and (max-width: 768px){
            width: 100%; 
            padding-top: var(--x-padding-40);
        }
    }

    &__right-card{
        margin-right: -160px;
        @media screen and (max-width: 1300px){ margin-right: 0px; }
    }
}