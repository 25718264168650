.newsletter-form{
    --newsletter-form__submit-width: 80px;
    
    width: 100%;
    position: relative;

    &__input{
        background-color: white;
        border: 1px solid var(--color-primary);
        height: 68px;
        padding-left: 36px;
        font-size: 1.8rem;
        padding-right: var(--newsletter-form__submit-width);
        border-radius: 100px;
        width: 100%;
        font-family: var(--font-main);
        color: var(--color-primary);

        -webkit-appearance: none !important;
        -moz-appearance: none !important;

        @media screen and (max-width: 768px)  {
            font-size: 1.7rem;
            height: 60px;
        }

        &::placeholder {
            color: var(--color-primary);
            opacity: 1;
        }

    }

    &__submit{
        position: absolute;
        top: 0;
        right: 0;
        width: var(--newsletter-form__submit-width);
        height: 100%;
        cursor: pointer;
    }

    &__svg{
        width: 18px;
        height: 12px;
        position: absolute;
        fill: var(--color-secondary);
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) rotate(-90deg);
        transition: transform 0.3s;
    }

    &__submit:hover{
        .newsletter-form__svg{
            transform: translate(calc(-50% + 5px ),-50%) rotate(-90deg);
        }
    }

    .field__error{
        position: absolute;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 100px white inset !important;
        border-color: var(--color-primary) !important;
        -webkit-text-fill-color: var(--color-primary) !important;
    }
}