.page-card {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 105px;
    padding: 20px 30px;
    background-color: white;
    border: 1px solid rgba(var(--color-primary-rgb), 0.30);
    border-radius: 8px;
    text-decoration: none;
    transition: box-shadow 300ms, border-color 300ms;

    &:hover {
        box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
        border-color: transparent;
    }

    &__title {
        font-size: 1.6rem;
        font-weight: 700;
        line-height: 1.3em;
        color: var(--color-primary);
    }
}
