#page-map {
    position: relative;
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;

    --logo__color-1: white;
    --logo__color-2: white;
    --logo__color-3: white;

    // Modifier la position du bouton de zoom
    .gm-bundled-control-on-bottom {
        bottom: 150px !important;
    }

    .image {
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .xp60 { padding-right: 60px; padding-left: 60px;
        @media screen and (max-width: 1024px) { padding-right: 20px; padding-left: 20px; }
      }
    .rp60 { padding-right: 60px;
        @media screen and (max-width: 1024px) { padding-right: 50px; }
        @media screen and (max-width: 768px)  { padding-right: 30px; }
        @media screen and (max-width: 500px)  { padding-right: 25px; }
    }
    .lp60 { padding-left: 60px;
        @media screen and (max-width: 1024px) { padding-left: 50px; }
        @media screen and (max-width: 768px)  { padding-left: 30px; }
        @media screen and (max-width: 500px)  { padding-left: 25px; }
    }

    .map {
        position: relative;
        background: var(--color-primary);
        height: 100px;

        @media screen and (max-height: 650px) { height: 80px; }
        @media screen and (max-height: 550px) { height: 60px; }
        @media screen and (max-width: 1024px) { height: 80px; }
        @media screen and (max-width: 768px)  { height: 60px; padding: 0 20px; }
        @media screen and (max-width: 600px) { padding: 0 20px; }

        .grid {
            height: 100px;
            align-items: center;

            @media screen and (max-height: 650px) { height: 80px; }
            @media screen and (max-height: 550px) { height: 60px; }
            @media screen and (max-width: 1024px) { height: 80px; }
            @media screen and (max-width: 768px)  { height: 60px; }

            .column.last { text-align: right; }

            .col6{
                height: 100%;
            }

            .logo,
            .logo-mobile-map {
                display: block;
                height: 100%;
                svg{
                    fill: white;
                    height: 100%;
                }
            }

            .logo {

                svg{
                    width: 240px;
                }

                @media screen and (max-width: 1100px) {
                    display: none;
                }
            }

            .logo-mobile-map {
                display: none;
                width: 275px;

                svg{
                    width: 48px;
                }

                @media screen and (max-width: 1100px) {
                    display: block;
                }

                @media screen and (max-width: 400px) {
                    width: 200px;
                }
            }

            .back {
                position: relative;
                padding-right: 80px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: flex-end;

                @media screen and (max-height: 550px) { padding-right: 60px; }

                &:hover .close {
                    //transform: translateY(-50%) scale(1.2);

                    //&:before, &:after { background: #fff; }
                }

                span {
                    &:not(.close):not(.close-icon) {
                        font: 2.5rem/50px var(--font-main);
                        color: #fff;

                        @media screen and (max-width: 1024px) { font-size: 1.6rem; }
                        @media screen and (max-width: 768px)  { font-size: 1.4rem; line-height: 36px; }
                        @media screen and (max-width: 600px)  { display: none; }
                    }
                    &.close {
                        position: absolute;
                        top: 50%;
                        right: 0;
                        transform: translateY(-50%);
                        //background: #3d4865;
                        transition: transform 300ms $easeOutCubic;

                        @media screen and (max-height: 550px) { width: 36px; height: 36px; }
                        @media screen and (max-width: 768px)  { width: 36px; height: 36px; }

                        .close-icon{
                            &:before, &:after { background: #fff; }
                        }
                    }
                }
            }
        }
    }

    .navBlock {
        display: none;

        @media screen and (max-width: 1024px) { display: block; }

        > span {
            position: relative;
            height: 65px;
            padding: 0 50px;
            display: block;
            padding: 0 20px;
            font: 1.5rem/40px var(--font-main);
            color: var(--color-primary);
            background: #fff;
            border-bottom: 2px solid rgba(29, 41, 82, 0.15);
            cursor: pointer;

            @media screen and (max-width: 768px) { padding: 0 20px; }

            #mapCategoryName {

                @media screen and (max-width: 1024px) {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }

            .svg-container {
                position: absolute;
                top: 50%;
                right: 50px;
                width: 18px;
                height: 13px;
                transform: translateY(-50%);

                @media screen and (max-width: 768px) { right: 30px; width: 16px; }

                svg {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    fill: var(--color-primary);
                    transform: rotate(0deg);
                    transition: transform 300ms ease;
                }
            }

        }
    }

    .mapBlock {

        #googleMap {
            position: absolute;
            width: calc(100% - 360px);
            height: calc(100vh - 100px);
            top: 100px;
            right: 0;
            background: var(--color-background);

            @media screen and (max-height: 650px) { height: calc(100vh - 80px); top: 80px; }
            @media screen and (max-height: 550px) { height: calc(100vh - 60px); top: 60px; }
            @media screen and (max-width: 1024px) { height: calc(100vh - 145px); top: 145px; width: 100%; }
            @media screen and (max-width: 768px)  { height: calc(100vh - 125px); top: 125px; }
        }

		#googleMapLoading {
			position: absolute;
			top: 100px;
			left: 0;
			width: 100%;
			height: calc(100vh - 100px);
			background: rgba(0,0,0,0.2);
			z-index: 9;

			@media screen and (max-height: 650px) {
				top: 80px;
				height: calc(100vh - 80px);
			}
            @media screen and (max-height: 550px) {
				top: 60px;
				height: calc(100vh - 60px);
			}
            @media screen and (max-width: 1024px) {
				top: 80px;
				height: calc(100vh - 80px);
			}
            @media screen and (max-width: 768px)  {
				top: 60px;
				height: calc(100vh - 60px);
			}
		}
    }

    .btn {
        position: fixed;
        bottom: 45px;
        right: 80px;
        z-index: 8;
        cursor: pointer;

        @media screen and (max-width: 1024px) { right: auto; left: 50px; }
        @media screen and (max-width: 768px)  { bottom: 30px; left: 30px; }
        @media screen and (max-width: 600px)  { bottom: 20px; left: 20px; }
    }

    // Le bouton fermer
    .close {
        width: 50px;
        height: 50px;
        cursor: pointer;


        &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%,-50%) scale(1);
            border-radius: 50%;
            transition: transform 300ms $easeOutCubic;
            background: #3d4865;
            z-index: 1;
            will-change: transform;
        }

        &:hover {
            &::after{
                transform: translate(-50%,-50%) scale(1.1);
            }
        }

        .close-icon{
            top: 50%;
            left: 50%;
            position: absolute;
            width: 18px;
            height: 18px;
            transform: translate(-50%, -50%);
            z-index: 2;

            &:before, &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 2px;
                height: 18px;
                background: white;
                border-radius: 5px;
                transform: translate(-50%, -50%);
                transition: background 300ms;
            }

            &:before { transform: translate(-50%, -50%) rotate(45deg); }
            &:after  { transform: translate(-50%, -50%) rotate(-45deg); }
        }

        &.closeOverlay {
            width: 40px;
            height: 40px;

            &:before, &:after {
                background: var(--color-primary);
            }
        }
    }
}

/*** MAP OVERLAY ***/
$base-line-height: 24px;
$white: blue;
$off-white: rgba($white, 0.4);
$spin-duration: 1s;
$pulse-duration: 750ms;
@keyframes pulse 								{ 50% { background: #fff; }}
#googleMapLoading .loading-pulse 				{ position: absolute; left: 50%; top: 50%; width: ($base-line-height / 4); height: $base-line-height; background: $off-white; animation: pulse $pulse-duration infinite; animation-delay: ($pulse-duration / 3);
  &:before, &:after 							{ position: absolute; top: 50%; display: block; height: ($base-line-height / 1.5); width: ($base-line-height / 4); content: ''; background: $off-white; transform: translateY(-50%); animation: pulse $pulse-duration infinite; }
  &:before 										{ left: -($base-line-height / 2); }
  &:after 										{ left: ($base-line-height / 2); animation-delay: ($pulse-duration / 1.5); }}


.showOverlayLocations{
    #page-map .navBlock > span svg { transform: rotate(180deg); }
}

.mapPin {
    width: 40px;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
    display: block;

    .marker{
        fill: var(--color-primary);
        stroke: var(--color-primary);
        height: 40px;
        width: 40px;
        transition: all 300ms;
    }

    .icon {
        position: absolute;
        top: 7px;
        left: 50%;
        transform: translate(-50%,0);
        width: 1em;
        height: 1em;
        transition: all 300ms;

        fill: transparent;
        stroke: white;
        stroke-width: 5px;
        stroke-linecap: round;
    }

    &:hover,
    &.active {
        .marker{
            fill: var(--color-tertiary);
            stroke: var(--color-tertiary);
        }
        .icon {
            stroke: var(--color-primary);
        }
    }
}

// Pastilles de couleur des états des lieux
.etatWrapper {
	margin: 25px 0 25px 0;
}

.etat {

    position: relative;
    &::after{
        content: '';
        position: absolute;
        top: 0;
        right: -6px;
        background-color: red;
        height: 5px;
        width: 5px;
        border-radius: 50%;
    }
    &.open::after { background-color: #76cd95 }
    &.close2::after { background-color: #e14a4a }
	&.partial::after { background-color: yellow }

    // Pastille de l'overlay de détails
    &.detail {

		font-size: 14px;
		margin-bottom: 0;
		padding-left: 30px;

		&::before {
            padding-left: 30px;
        }

        &::after {
            height: 12px;
            width: 12px;
            left: 4px;
            right: auto;
            top: 50%;
            transform: translateY(-50%);
        }

		&.etat-open 	{ color: #76cd95 !important; }
        &.etat-close2  	{ color: #e14a4a !important; }
		&.etat-partial 	{ color: yellow !important; }

        &.etat-open::after		{ background-color: #76cd95 }
        &.etat-close::after 	{ background-color: #e14a4a }
		&.etat-partial::after 	{ background-color: yellow }

    }

    // Pastille de la puce sur la carte intéractive
    &.mapPin::after{
        height: 11px;
        width: 11px;
        transform: translate(50%, -50%);
        top: 5px;
        right: 5px;
    }
}

.etatnote {
	padding-left: 30px;
	font-size: 13px;
	line-height: 1.4em;
}

#overlayLocations {
    position: absolute;
    width: 100%;
    max-width: 360px;
    height: calc(100vh - 100px);
    top: 100px;
    left: 0;
    background: #fff;
    overflow: auto;
    z-index: 10;

    @media screen and (max-height: 650px) { height: calc(100vh - 80px); top: 80px; }
    @media screen and (max-height: 550px) { height: calc(100vh - 60px); top: 60px; }
    @media screen and (max-width: 1024px) {
        height: calc(100vh - 145px);
        top: 145px;
        opacity: 0;
        visibility: hidden;
        transform: translateY(50px);
    }
    @media screen and (max-width: 768px) {
        height: calc(100vh - 125px);
        top: 125px;
    }
    @media screen and (max-width: 500px) { width: 100%; max-width: 100%; }

    // &::-webkit-scrollbar { display: none; }

    .btnSubLvl1 {

        > span {
            display: block;
            position: relative;
            padding: 30px 30px 30px 90px;
            font: 1.8rem/1 var(--font-main);
            font-weight: 500;
            color: var(--color-primary);
            cursor: pointer;

            @media screen and (max-width: 1024px) { padding: 30px 30px 30px 60px; }
            @media screen and (max-width: 768px) { padding: 30px 20px 30px 60px; }
            @media screen and (max-width: 500px) { padding: 30px 20px 30px 50px; }

            .plus {
				position: absolute;
				width: 16px;
				height: 16px;
                top: 50%;
                left: 60px;
                transform: translateY(-50%);

                @media screen and (max-width: 1024px) { left: 20px; }

				&:before, &:after {
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					background: #000;
					transition: background 300ms, height 300ms ease;
				}
				&:before { width: 18px; height: 2px; }
				&:after  { width: 2px; height: 18px; }
			}
        }

        &.active .plus:after { height: 0px; }
    }

    .btnSubLvl2 {
        margin-top: 20px;

        &:first-child { margin-top: 35px; }
        &:last-child { margin-bottom: 35px; }

        > span {
            display: block;
            position: relative;
            padding-left: 40px;
            font: 1.6rem/1.3 var(--font-main);
            font-weight: bold;
            color: var(--color-primary);
            cursor: pointer;
            transition: color 300ms;

            svg {
                position: absolute;
                width: 18px;
                height: 18px;
                top: 50%;
                left: 0;
                fill: none;
                stroke: var(--color-primary);
                stroke-width: 5px;
                transform: translateY(-50%);
                transition: stroke 300ms;
                z-index: 2;
            }

            &::after{
                content: '';
                position: absolute;
                left: 0;
                top: 50%;
                background-color: white;
                transform: translate(-25%, -50%);
                transition: background-color 0.3s ease;
                width: 35px;
                height: 35px;
                border-radius: 50%;
                z-index: 1;
            }

            &:hover {
                &::after{
                    background-color: var(--color-tertiary);
                }
            }
        }

        &.active > span {
            &::after{
                background-color: var(--color-tertiary);
            }
        }
    }

    .subLvl1 {
        display: none;
        padding-left: 60px;
        opacity: 0;
        overflow: hidden;
        transform: translateY(30px);
        transition: opacity 300ms, transform 300ms, background-color 300ms;
        background-color: white;

        @media screen and (max-width: 1024px) { padding-left: 30px; }

        &.open {
            opacity: 1;
            transform: none;
            background-color: var(--color-background);
        }
    }

    .subLvl2 {
        display: none;
        padding-right: 20px;
        padding-left: 40px;
        opacity: 0;
        overflow: hidden;
        transform: translateY(30px);
        transition: opacity 400ms, transform 400ms;

        @media screen and (max-width: 500px) { padding-left: 20px; }
        @media screen and (max-width: 425px) { padding-left: 10px; }

        &.open {
            opacity: 1;
            transform: none;
        }

        li {
            &:first-child { margin-top: 30px; }
            &:last-child { margin-bottom: 15px; }
            &:not(:last-child) { margin-bottom: 20px; }

            span {
                font: 1.6rem/1.3 var(--font-main);
                color: rgba(var(--color-primary-rgb),0.65);
                cursor: pointer;
                font-weight: 500;
                transition: color 300ms;

                @media screen and (max-width: 500px) { font-size: 1.6rem; }

                &:hover, &:active, &:focus { color: var(--color-primary); }
            }

			&.active span { color: var(--color-primary); }
        }


    }
}

.showOverlayLocations {
    &.closingLocations #overlayLocations {
        opacity: 0;
        transform: translateY(50px);
    }

    #overlayLocations {
        opacity: 1;
        visibility: visible;
        transform: none;
        transition: opacity 300ms $easeInOutCubic, transform 300ms $easeInOutCubic;
    }
}


#overlayLocation {

    position: absolute;
    width: 100%;
    max-width: 360px;
    height: 100%;
    max-height: calc(100vh - 200px);
    left: 360px;
    bottom: -50px;
    background: #fff;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);

    @media screen and (max-height: 800px) { max-height: calc(100vh - 150px); }
    @media screen and (max-height: 700px) { max-height: calc(100vh - 125px); }
    @media screen and (max-height: 650px) { max-height: calc(100vh - 80px); }
    @media screen and (max-height: 550px) { max-height: calc(100vh - 60px); }
    @media screen and (max-width: 1024px) { max-height: calc(100vh - 145px); left: 0px; }
    @media screen and (max-width: 768px)  { max-height: calc(100vh - 125px); }
    @media screen and (max-width: 500px) { width: calc(100% - 40px); }

    .image {
        height: 200px;

        @media screen and (max-width: 500px) { height: 175px; }
        @media screen and (max-width: 425px) { height: 150px; }
    }

    .bande {
        height: 65px;
        background-color: var(--color-secondary);
    }

    .close {
        position: absolute;
        top: 13px;
        right: 20px;

        @media screen and (max-width: 768px) { top: 10px; right: 10px; width: 36px; height: 36px; }

        //&:before, &:after { background: #fff; }
    }

    .text {

        font: 1.6rem/1.625 var(--font-main);
        padding: 50px 48px;
        overflow-y: auto;
        overflow-x: hidden;

        height: calc(100% - 65px);

        &--with-image {
            height: calc(100% - 200px);

            @media screen and (max-width: 500px)  { height: calc(100% - 175px); }
            @media screen and (max-width: 425px)  { height: calc(100% - 150px); }
        }

        @media screen and (max-width: 1024px) { padding: 40px 50px; }
        @media screen and (max-width: 768px)  { padding: 30px 40px 70px; }
        @media screen and (max-width: 600px)  { padding: 30px 30px 70px; }
        @media screen and (max-width: 500px)  { padding: 30px 20px 70px; }
        @media screen and (max-width: 375px)  { padding: 20px 15px 70px; }

        // &::-webkit-scrollbar { display: none; }

        h3 {
          line-height: 1.2;
          font-size: 3rem;
          font-weight: normal;
          color: var(--color-primary);
        }


    }

    .info {

        margin-top: 25px;

        p, a {
            display: inline-block;
            position: relative;
            margin-left: 30px;
            margin-bottom: 15px;
            font: 1.6rem/1.3 var(--font-main);
            transition: color 300ms;
            word-break: break-word;

            svg {
                position: absolute;
                width: 18px;
                height: 18px;
                top: 1px;
                left: -30px;
                fill: none;
                stroke: var(--color-primary);
                stroke-width: 3px;
            }
        }

        p { color: var(--color-primary); }

        a {
            color: var(--color-primary);
            text-decoration: none;
            transition: box-shadow 0.3s;

            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                right: 0;
                top: calc(100% - 2px);
                background-color: var(--color-secondary);
                transition: top 0.3s;
                z-index: -1;
            }

            &:hover {
                &::after {
                    top: 0;
                }
            }
        }
    }

    .desc {
      margin-top: 30px;
        p {
            font: 1.6rem/1.3 var(--font-main);
            color: var(--color-primary);
        }
    }

    .relations {

      margin-top: 15px;

      h4 {
        margin-top: 20px;
        font: 700 1.5rem/1.3 var(--font-main);
        margin-bottom: 10px;
        color: var(--color-primary);
      }

      ul {
        list-style: none;
        margin-left: 0;
        padding-left: 1em;

        > li {
          margin-bottom: 7px
        }

        > li:before {
          display: inline-block;
          content: "-";
          width: 0.7em;
          margin-left: -1em;
        }

        a {
          font: rem(15)/1.3 var(--font-main);
          transition: color 300ms;
          vertical-align: top;

          color: var(--color-primary);
          text-decoration: none;
          box-shadow: inset 0 -0.1em 0 0 var(--color-secondary);
          transition: box-shadow 0.3s;
          &:hover {
              box-shadow: inset 0 -1.1em 0 0 var(--color-secondary);
          }


        }

        span.date {
            font-size: 1.3rem;
        }
      }
    }
}

.showOverlayLocation {

    &.closingLocation #overlayLocation {
        opacity: 0;
        bottom: -50px;
    }

    #overlayLocation {
        opacity: 1;
        bottom: 0px;
        visibility: visible;
        transition: opacity 300ms $easeInOutCubic, bottom 300ms $easeInOutCubic;
    }
}
