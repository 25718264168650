:root{
    --dropdown__menu-gutters: 80px; //Espacement entre les colonnes du menu
    --dropdown__distance-from-top: 190px;
    --dropdown__bottom-section-height: 110px;
    --dropdown__bottom-section-padding: 50px;

    --dropdown__hover-color: #525c75;

    @media screen and (max-width: 1200px) {
        --dropdown__menu-gutters: 60px;
    }
}

.overlay-dropdown{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    visibility: hidden;
    pointer-events: none;

    z-index: 49;

    &__container{
        background-color: var(--color-primary);
        overflow-y: scroll;
        height: 0;
        transition: height 0.8s ease;
        position: relative;

        // Cacher la scrollbar
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    &__menu{
        @extend .x-padding-80;
        min-height: 100vh;
        padding-top: var(--dropdown__distance-from-top);
        padding-bottom: calc(var(--dropdown__bottom-section-height) + var(--dropdown__bottom-section-padding));
        position: relative;
        overflow: hidden;
    }

    &__menu-wrapper{
        @extend .content-wrap;
        position: relative;
        transition: height 0.3s $easeOutQuart;
        height: 0;
    }

    &__close-button{
        position: absolute;
        color: white;
        font-size: 1.8rem;
        font-weight: 500;
        right: var(--x-padding-40);
        top: calc(var(--dropdown__distance-from-top) - 50px);
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s;
        padding-right: 33px;

        &:hover{
            opacity: 0.5;
        }
    }

    &__close-button-x{
        position: absolute;
        right: 0;
        top: 50%;
        width: 14px;
        height: 14px;
        transform: translate(0, calc(-50% + 2px));

        &::before,
        &::after{
            content: '';
            width: 16px;
            height: 1.3px;
            background-color: var(--color-secondary);
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%) rotate(45deg);
        }
        &::after{
            transform: translate(-50%,-50%) rotate(-45deg);
        }
    }

    &__section {
        visibility: hidden;
        opacity: 0;
        position: absolute;
        width: 100%;

        //padding-bottom: calc(var(--dropdown__bottom-section-height) + var(--dropdown__bottom-section-padding));

        &::-webkit-scrollbar { display: none; }
    }

    &__section-title{
        color: white;
        font-size: 5.5rem;
        padding-bottom: 50px;
    }

    &__section-wrapper{
        display: flex;
        flex-wrap: nowrap;
        margin: 0 calc(var(--dropdown__menu-gutters) / -2);
    }

    &__sub-section {
        width: 33.333333%;
        padding: 0 calc(var(--dropdown__menu-gutters) / 2);
    }

    &__sub-section-container {
        transform: translateY(100px);

        --card__content-padding: 45px 25px;

        @media screen and (max-width: 950px) {
            --card__content-title-size: 2.5rem;
        }
    }

    &__sub-section-link {
        position: relative;
        text-decoration: none;
    }

    &__sub-section-title {
        margin-bottom: 22px;
        padding-bottom: 20px;
        border-bottom: 1px solid rgba(var(--color-line-rgb),0.2);
        font-weight: 500;
        font-size: 2.5rem;
        color: white;

        &::after{
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 0;
            opacity: 0;
            background-color: var(--color-secondary);
            transition: height 0.3s $easeOutQuart, opacity 0.3s;
        }

        &:hover{
            &::after{
                opacity: 1;
                height: 4px;
            }
        }
    }

    &__list-item {
        &:not(:last-child) {
            margin-bottom: 16px;
        }
    }

    &__link {
        font-size: 1.8rem;
        line-height: 1.3em;
        color: white;
        opacity: 1;
        text-decoration: none;
        transition: opacity 0.3s;

        box-shadow: inset 0 0em 0 0 var(--dropdown__hover-color);
        transition: box-shadow 0.3s;

        &:hover{
            box-shadow: inset 0 -1.1em 0 0 var(--dropdown__hover-color);
        }
    }


    &__bottom{
        @extend .x-padding-80;
        background-color: white;
        //position: fixed;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    &__bottom-wrapper{
        @extend .content-wrap;
        display: flex;
        align-items: center;
        height: var(--dropdown__bottom-section-height);
    }

    &__bottom-contact{
        margin-right: auto;
    }

    &__bottom-address-search,
    &__bottom-map{
        padding-left: 50px;
    }

    &__bottom-button{
        color: var(--color-primary);
        position: relative;
        font-weight: bold;
        font-size: 1.4rem;
        padding: 22px 0;
        transition: color 0.3s;
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        line-height: 1.3em;
        text-transform: uppercase;
        cursor: pointer;
        padding-left: 20px;
    }

    &__bottom-button-svg{
        top: 50%;
        position: absolute;
        left: 0;
        transform: translate(0, -50%);
        margin-left: 0;
        width: 14px;
        height: 14px;
        fill: var(--color-primary);
        transition: fill 0.3s;
    }

    &__bottom-button:hover{
        color: var(--color-secondary);
        .overlay-dropdown__bottom-button-svg{
            fill: var(--color-secondary);
        }
    }

}
