.show-overlay-popup{

    .overlay-popup{
        visibility: visible;
        opacity: 1;

        &__background {
            opacity: 1;
        }
    }

}

// Overlay qui ferme
.show-overlay-popup.closing-overlay-popup{
    .overlay-popup{
        visibility: hidden;
        opacity: 0;

        &__background {
            opacity: 0;
        }
    }
}