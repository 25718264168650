.showcase{

    --column-margin: 116px;
    @media screen and (max-width: 1300px) { --column-margin: 70px; }
    @media screen and (max-width: 1024px) { --column-margin: 60px; }
    @media screen and (max-width: 768px) { --column-margin: 0; }

    &__wrapper{
        max-width: 960px;
        margin: auto;
    }
    &__grid{
        display: flex;
        flex-wrap: wrap;
        margin: 0 calc((var(--column-margin) / 2) * -1);
    }

    &__call-to-action{
        position: absolute;
        left: 50%;
        top: 0;
        transform: translate(-50%, 0);
        z-index: 2;
        @media screen and (max-width: 768px) {
            top: 45px;
            left: auto;
            right: 0;
        }
    }

    &__image,
    &__content{
        width: 50%;
        padding: 0 calc(var(--column-margin) / 2);
        @media screen and (max-width: 768px) { 
            width: 100%;
        }
    }

    &__picture-container{
        position: relative;
        border-radius: 8px;
        width: 100%;
        padding-bottom: 136%; //546px/400px;
        overflow: hidden;
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

        @media screen and (max-width: 768px) {
            padding-bottom: 109.2783505154639%;// 212px/194px
        }
    }

    &__picture-parallax{
        position: absolute;
        top: -10%;
        bottom: -10%;
        left: 0;
        right: 0;
    }

    &__image{
        @media screen and (max-width: 768px) {
            padding-right: 17%;
        }
    }

    &__picture{
       height: 100%;
       width: 100%; 
    }

    &__content{
        display: flex;
        align-items: flex-end;
    }

    &__content-dynamic{
        @extend .y-padding-30;
        @media screen and (max-width: 768px) { padding-bottom: 0; }
    }

    &__title{
        @extend .large-title;
        font-weight: bold;
        font-size: 6em;
        @media screen and (max-width: 1024px) { font-size: 4em; }
        @media screen and (max-width: 768px) { font-size: 3em; }
    }

}

.components .wave-section:first-child.eventComp {
    margin-top: -250px;
}
