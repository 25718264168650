.rubric-info{
    display: flex;
    align-items: center;

    &__picture {
        width: var(--rubric__thumb-width);
        height: var(--rubric__thumb-height);
        margin-right: 40px;
        overflow: hidden;
        flex-shrink: 0;
        border-radius: 50%;
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
    
        @media screen and (max-width: 600px) { display: none; }
    }
    
    &__picture-img {
        transition: transform 0.3s;
        transform: scale(1);
    }
    
    &__subtitle {
        margin-bottom: 9px;
        font-size: var(--rubric__subtitle-font-size);
        color: var(--rubric__color);
        line-height: 1.3em;
        transition: color 0.3s;
    }
    
    &__title {
        font-size: var(--rubric__font-size);
        line-height: 1.2;
        color:var(--rubric__color);
        transition: color 0.3s;
    }

    &__link-wrapper{
        margin-top: 9px;
    }

    &__link{
        font-size: 1.7rem;
    }
}