.control-multi-file-uploader {
    --form-upload-background: rgba(256,256,256,1);
    --form-upload-background-hover: rgba(256,256,256,0.6);
    --form-upload-x-background: rgba(0,0,0,1);
    --form-upload-x-background-hover: rgba(0,0,0,0.6);
    --form-upload-border-color: #b2b2b2;
    --form-upload-color: var(--color-primary);
}

.control-multi-file-uploader {
    background-color: var(--form-upload-background);
    border: 1px dashed var(--form-upload-border-color);
    border-radius: 10px;

    .container {
        position: relative;
        width: 100%;
        height: 100%;
    }

    .dz-clickable {
        height: 100px;
        cursor: pointer;
        transition-property: color, background-color;
        transition-duration: 300ms;

        &:hover { 
            background-color: var(--form-upload-background-hover);
        }
    }

    .content {
        font-size: 1.5rem;

        .placeholder {
            position: absolute;
            top: 50px;
            right: 20px;
            left: 20px;
            line-height: 1.3em;
            font-weight: 600;
            color: var(--form-upload-color);
            text-align: center;
            pointer-events: none;
            transform: translate(0, -50%);
            transition-property: color, background-color;
            transition-duration: 300ms;

            .upload-full-text {
                display: block;

                @media screen and (max-width: 560px) { display: none }
            }
            
            .upload-truncated-text {
                display: none;

                @media screen and (max-width: 560px) { display: block; }
            }
        }

        .dz-preview {
            display: flex;
            align-items: center;
            position: relative;
            min-height: 100px;
            padding-left: 120px;
            padding-right: 35px;
            margin: 20px;
            background-color: rgba(#f7f8f8, 0.3);

            .thumbnail {
                position: absolute;
                width: 100px;
                height: 100px;
                top: 0;
                left: 0;
            }

            .dz-details {
                flex-wrap: wrap;
                font-size: 1rem;
                line-height: 1.5em;
                color: #012040;

                .dz-filename,
                .dz-size {
                    width: 100%;
                    margin: 6px 0;
                }
            }

            .action-panel {
                position: absolute;
                width: 20px;
                height: 20px;
                top: 10px;
                right: 10px;
                color: white;
                background: var(--form-upload-x-background);
                border-radius: 50%;
                cursor: pointer;
                z-index: 4;
                transition-property: color, background-color;
                transition-duration: 300ms;

                &:hover {
                    background-color: var(--form-upload-x-background-hover);
                }

                a {
                    position: absolute;
                    top: 44%;
                    left: 50%;
                    color: white;
                    text-decoration: none;
                    transform: translate(-50%,-50%);
                }
            }

            .dz-error-mark {
                display: none;
            }
            
            .dz-error-message {
                position: absolute;
                bottom: 5px;
                color: white;
                text-decoration: none;
            }
        }
    }

    .container:hover .content .placeholder {
        //color: white;
    }
}