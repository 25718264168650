.layout{
    position: relative;

    &__title{
        @extend .large-title;
        @extend .bottom-padding-40;
        color: var(--color-primary);
    }

    &__dynamic,
    &__gallery,
    &__documents,
    &__rubrics{
        &:not(:last-child){
            @extend .bottom-padding-130;
            @media screen and (max-width: 758px){
                padding-bottom: 70px;
            }
        }
    }

    &__rubrics--no-title{
        margin-top: -60px;
    }

    &__documents-desc{
        @extend .bottom-padding-40;
    }
}

.section{

    &__padding{
        @extend .x-padding-40;
        @extend .top-padding-100;
        @extend .bottom-padding-130;
        position: relative;
    }

    &__wrapper{
        @extend .content-wrap;
    }
    &__wrapper--bit-smaller{
        @extend .content-wrap--bit-smaller;
    }
    &__wrapper--smaller{
        @extend .content-wrap--smaller;
    }

    &--gray{
        background-color: var(--color-background);
    }

    &__offset-wrapper {
        position: relative;
    }

    &__offset {
        position: relative;
        margin-right: calc((100vw - 100%) / 2 * -1);
    }

    &--not-last{
        .section__content{
            @extend .bottom-padding-170;
            @media screen and (max-width: 768px){
                padding-bottom: 90px;
            }
            @media screen and (max-width: 500px){
                padding-bottom: 120px;
            }
        }
    }

    &--hidden-overflow{
        overflow: hidden;
    }

    &--border-on-bottom{
        border-bottom: 1px solid var(--color-line);
    }

    &--more-top-padding{
        .section__padding{
            padding-top: var(--y-padding-170);
            @media screen and (max-width: 768px){
                padding-top: 100px;
            }
        }
    }

    &--no-y-padding{
        .section__padding{
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &--no-bottom-padding{
        .section__padding{
            padding-bottom: 0;
        }
    }
}

.wave-section{
    --wave-overflow: 30%;
    --wave-height: 240px;

    --other-events__background: var(--color-background);

    @media screen and (max-width: 1024px){ --wave-height: 180px; }
    @media screen and (max-width: 758px){ --wave-height: 150px; }

    position: relative;
    background-color: var(--color-background);
    &__wave{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: calc(100% - 2px);
        display: flex;
        pointer-events: none;
        overflow: hidden;
        justify-content: center;
    }
    &__parallax{
        width: 100%;
        display: flex;
        justify-content: center;
    }
    &__svg{
        height: var(--wave-height);
        width: calc(100% + var(--wave-overflow));
        margin-left: calc(var(--wave-overflow) * -1);
        margin-right: calc(var(--wave-overflow) * -1);
        min-width: 1000px;
        fill: var(--color-background);
    }
    &__back-to-top{
        position: absolute;
        top: 45px;
        right: var(--x-padding-40);
        z-index: 1;
    }

    &__content{
        z-index: 1;
        position: relative;
    }

    &__padding{
        @extend .x-padding-40;
        @extend .top-padding-100;
        @extend .bottom-padding-130;
    }

    &__wrapper{
        @extend .content-wrap;
    }

    &__offset-top{
        display: inline-block;
        margin-top: -160px;
        width: 100%;
        @media screen and (max-width: 768px) {
            margin-top: -120px;
        }
    }

    &--blue{ background-color: var(--color-primary) }
    &--blue &__svg{
        fill: var(--color-primary);
    }

    &--not-last{
        .wave-section__content{
            @extend .bottom-padding-170;
            @media screen and (max-width: 768px){
                padding-bottom: 90px;
            }
            @media screen and (max-width: 500px){
                padding-bottom: 120px;
            }
        }
    }

    &--preceded-by-image{
        padding-top: 285px;
        @media screen and (max-width: 1024px){
            padding-top: 220px;
        }
        @media screen and (max-width: 768px){
            //padding-top: 150px;
            padding-top: 200px;
        }
        @media screen and (max-width: 500px){
            padding-top: 180px;
        }
    }

    &--no-right-padding{
        .wave-section__padding{
            overflow: hidden;
        }
        .wave-section__offset-wrapper{
            position: relative;
        }
        .wave-section__offset{
            position: relative;
            margin-right: calc(((100vw - 100%)/2)*-1);
        }
    }

    &--less-top-padding{
        .wave-section__padding{
            padding-top: 0;
        }
    }
}

.section-negative-margin{
    margin-top: calc(var(--y-padding-170) * -1);
    @media screen and (max-width: 550px){
        margin-top: -170px;
    }
}


.page-top:has(.page-top__image-picture) + .components > .wave-section:nth-child(1){
    padding-top: 285px;
    @media screen and (max-width: 1024px){
        padding-top: 220px;
    }
    @media screen and (max-width: 768px){
        //padding-top: 150px;
        padding-top: 200px;
    }
    @media screen and (max-width: 500px){
        padding-top: 180px;
    }
}
