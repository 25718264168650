.calendar--open{
    .calendar-button{
        &__text-show{
            opacity: 0;
        }
    
        &__text-hide{
            opacity: 1;
        }

        &__svg{
            opacity: 0;
        }

        &__close{
            opacity: 1;

            &::before{
                transform: translate(-50%,-50%) rotate(45deg);
            }
            &::after{
                transform: translate(-50%,-50%) rotate(-45deg);
            }
        }
    }
}