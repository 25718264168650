@media print {

    *{
        color: black !important;
    }

    :root{
        --x-padding-180: 0 !important;
        --x-padding-120: 0 !important;
        --x-padding-80:  0 !important;
        --x-padding-50:  0 !important;
        --x-padding-40:  0 !important;
    }

    // Cacher plein de stuff -----------
    .header,
    .breadcrumbs,

    .alerts,
    .overlay-dropdown,
    .overlay-popup,
    .overlay-share,
    .overlay-search,
    .overlay-menu,
    .news-slider,

    .call-to-action,
    .back-to-top,

    .contact-form-wrapper,

    .footer{
        display: none !important;
    }

    // Désactiver le scrollfire
    .js-scrollfire{
        opacity: 1 !important;
        transform: none !important;
    }

    // Désactivber l'antiscroll si overlay ouvert
    .disabled-page-scroll{
        top: auto !important;
        position: static !important;
    }

    // Layout
    .wave-section{
        &__wave{
            display: none !important;
        }
        &__padding{
            padding-top: 0 !important;
        }

        &--not-last &__content{
            padding-bottom: 0;
        }
        &--preceded-by-image{
            padding-top: 0 !important;
        }
    }

    // Entêtes
    .page-top{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
        &__text{
            padding-right: 0 !important;
        }
        &__title{
            font-size: 6rem !important;
        }
        &__image{
            margin-left: 0 !important;
            margin-right: 0 !important;
            margin-top: 0 !important;
            margin-bottom: 0 !important;
        }
    }

    .page-top-2-cols{
        &__offset{
            margin-left: 0 !important;
        }
        &__text-wrapper{
            padding-left: 30px !important;
        }
        &__title{
            font-size: 6rem !important;
        }
        &__button{
            display: none;
        }
    }

    // Vitrines
    .showcase{
        --column-margin: 30px !important;
        &__title{
            font-size: 3rem !important;
        }
    }

    // Boutons
    .btn{
        &::after{
            background-color: #d3d3d3 !important;
            -webkit-print-color-adjust: exact !important;
        }
    }

    // Rubriques
    .rubric{
        padding-left: 0 !important;
        padding-right: 0 !important;

        &__content{
            height: auto !important;
        }
        &__transition{
            transform: none !important;
            opacity: 1 !important;
        }

    }

    .card{
        &__news-info,
        &__news-title{
            color: white !important;
            -webkit-print-color-adjust: exact !important;
        }

        &__news-info{
            font-size: 1.2rem;
        }

        &__news-title{
            font-size: 1.5rem;
        }

        &__container{
            transform: none;
        }

        &__overlay{
            -webkit-print-color-adjust: exact !important;
        }

        &__date-container,
        &__round{
            -webkit-print-color-adjust: exact !important;
        }

        &__image-container,
        &__image{
            -webkit-print-color-adjust: exact !important;
        }
    }

    .other-events{
        --other-events__margin: 30px !important;
        &__separation{
            display: none !important;
        }
    }

    .event-info{
        &__grid{
            flex-wrap: wrap !important;
        }
        &__item{
            flex-grow: 0 !important;
            width: 50% !important;
            flex-basis: auto !important;
        }
    }

    .jobs-top{
        &__content{
            padding-right: 0 !important;
        }
        &__title{
            font-size: 6rem !important;
        }
    }
    #form-carreer{ display: none; }

}