/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-search {

  .header__button-online-requests,
  .header__nav{
    opacity: 0;
  }

  // Bouton dans le menu
  .header{
    &__search-button-svg{ opacity: 0; }
    &__search-button-close{ opacity: 1; }
  }

  .overlay-search {
      width: 100%;

      visibility: visible;
      pointer-events: all;

      &__background {
        opacity: 1;
      }

      &__main-wrapper{
        opacity: 1;
        transform: none;
      }

  }

}


/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-search.closing-overlay-search {

  // Bouton dans le menu
  .header{
    &__search-button-svg{ opacity: 1; }
    &__search-button-close{ opacity: 0; }
  }

  .overlay-search {
    &__background {
        opacity: 0;
        transition: opacity 0.5s 0.3s;
    }
    &__main-wrapper {
        opacity: 0;
        transform: translateY(-100px);
    }
  }

}
