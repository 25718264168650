.show-overlay-breadcrumbs{

    .overlay-breadcrumbs{

        visibility: visible;
        pointer-events: all;

        .overlay-breadcrumbs__background{
            opacity: 1;
        }

        .overlay-breadcrumbs__transition{
            opacity: 1;
            transform: translate(0,0);
        }
    }

}

// Overlay qui ferme
.show-overlay-breadcrumbs.closing-overlay-breadcrumbs{
    .overlay-breadcrumbs{

        .overlay-breadcrumbs__transition{
            opacity: 0;
            transform: translate(0,20px);
            transition: transform 0.4s $easeOutCubic, opacity 0.4s $easeOutCubic;
        }

        .overlay-breadcrumbs__background{
            opacity: 0;
            transition: opacity 0.5s 0.3s;
        }

    }
}

// Flèche du drop
.show-overlay-breadcrumbs:not(.closing-overlay-breadcrumbs){
    .breadcrumbs__drop-svg{
        transform: translate(-50%, -50%) rotate(180deg);
    }
}