:root {
    --popup__x-height: 18px;
    --popup__close-height: 60px;
}

.overlay-popup {
    visibility: hidden;
    opacity: 0;
    transition: opacity 600ms, visibility 600ms;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1000;
    
    &__background {
        width: 100%;
        height: 100%;
        background: rgba(#000, 0.4); 
        display: block;
        cursor: auto;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transition: opacity 0.5s ease;
        opacity: 0;
    }

    &__container {
        width: 600px;
        background: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 110px 70px 70px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        border-radius: 10px;

        @media screen and (max-width: 768px) {
            width: 500px;
            padding: 100px 50px;
        }

        @media screen and (max-width: 600px) {
            width: 400px;
            padding: 100px 50px 50px;
        }

        @media screen and (max-width: 500px) {
            width: 300px;
            padding: 100px 25px 25px;
        }

        @media screen and (max-width: 400px) { width: 100%; }
    }

    &__close {
        width: var(--popup__close-height);
        height: var(--popup__close-height);
        position: absolute;
        top: 30px;
        right: 30px;
        cursor: pointer;

        @media screen and (max-width: 500px) { top: 25px; }

        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            transition: transform 300ms ease;
            background: var(--color-primary);
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%) scale(1);
            z-index: 1;
            will-change: transform;
        }

        &:hover{
            &::after{
                transform: translate(-50%,-50%) scale(1.1);
            }
        }
    }

    &__close-x{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        height: var(--popup__x-height);
        width: var(--popup__x-height);
        z-index: 2;
        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 2px;
            min-width: 2px;
            height: var(--popup__x-height);
            min-height: var(--popup__x-height);
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #fff;
            transition: background 300ms var(--ease__in-out-cubic);
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after { transform: translate(-50%, -50%) rotate(-45deg); }
    }

    &__text { text-align: center; }

    &__title {
        font-size: 3.5rem;
        font-weight: 500;
        color: var(--color-primary);
        line-height: 1.1;
        margin-bottom: 40px;

        @media screen and (max-width: 600px) { font-size: 25px; }
    }

    &__infos {
        font-size: 1.7rem;
        color: var(--color-primary);
        line-height: 1.5;
        margin-bottom: 50px;

        @media screen and (max-width: 600px) { 
            font-size: 1.4rem;
            margin-bottom: 20px;
        }
    }
}