:root{
    --call-to-action__size: 184px;
    --call-to-action__icon-size: 40px;
    --call-to-action__scroll-icon-size: 56px;
    @media screen and (max-width: 1300px) {
        --call-to-action__size: 140px;
    }
    @media screen and (max-width: 1024px) {
        --call-to-action__size: 120px;
        --call-to-action__icon-size: 30px;
        --call-to-action__scroll-icon-size: 40px;
    }
}

.call-to-action{
    position: relative;
    width: var(--call-to-action__size);
    height: var(--call-to-action__size);
    border-radius: 50%;
    cursor: pointer;
    &__svg-center{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        fill: var(--color-secondary);
        width: var(--call-to-action__icon-size);
        height: var(--call-to-action__icon-size);
    }
    &__ring{
        width: var(--call-to-action__size);
        height: var(--call-to-action__size);
        transition: transform 0.4s $easeOutQuart;
    }
    &__svg-ring{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        fill: var(--color-secondary);
        width: var(--call-to-action__size);
        height: var(--call-to-action__size);
    }

    &--scroll-down{
        .call-to-action__svg-center{
            transition: transform 0.3s;
            width: var(--call-to-action__scroll-icon-size);
            height: var(--call-to-action__scroll-icon-size);
            transform: translate(-50%, -50%) rotate(180deg) ;
        }
        .call-to-action__ring{
            animation: rotation 15s infinite linear;
        }
    }

    &--fast-links{
        .call-to-action__svg-center{
            width: 50px;
            height: 50px;
        }
    }

    &--no-hover{
        pointer-events: none;
    }

    &--rotate{
        .call-to-action__ring{
            animation: rotation 15s infinite linear;
        }
    }

}

.call-to-action:hover{
    .call-to-action__ring{
        transform: rotate(45deg);
    }
}

.call-to-action--scroll-down:hover{
    .call-to-action__svg-center{
        transform: translate(-50%, calc(-50% + 10px)) rotate(180deg);
    }
}


@keyframes rotation {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(-359deg); }
}