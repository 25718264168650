:root{
    --calendar__background: white;
    --calendar__header-color: var(--color-primary);

    --calendar__day-of-the-week-color: var(--color-primary); // Couleur des lettres des jours de la semaine ex: D,L,M,M,J,V,S

    --calendar__square-size: 37px;
    @media screen and (max-width: 1024px) { --calendar__square-size: 34px; }
    

    // Dates des mois précédants ou suivants --------------------
    --calendar__other-month-date-color: #9b9b9b;
    --calendar__other-month-date-dot-color: #9b9b9b;


    // Date avec événement --------------------------------------
    --calendar__date-color: var(--color-primary);
    --calendar__date-dot-color: var(--color-tertiary);
    
    --calendar__date-color-hover: #333333;
    --calendar__date-background-hover: #f0f5f8;
    --calendar__date-dot-color-hover: #898989;


    // Date d'aujourd'hui ---------------------------------------
    --calendar__today-background: var(--color-secondary);
    --calendar__today-color: white;
    --calendar__today-dot-color: var(--color-primary);

    --calendar__today-background-hover: var(--color-secondary);
    --calendar__today-color-hover: var(--color-secondary);
    --calendar__today-dot-color-hover:var(--color-secondary);


    // Date sélectionnée ----------------------------------------
    --calendar__active-color: var(--color-primary);
    --calendar__active-background: var(--color-tertiary);
    --calendar__active-dot-color: white;

    --calendar__active-color-hover: #333333;
    --calendar__active-background-hover: #e9e9e9;
    --calendar__active-dot-color-hover: var(--color-primary);
}

.calendar  {
    background: var(--calendar__background);
    position: relative;
}

.datepicker {
    position: relative;
    width: 100%;
    direction: ltr;

    table {
        margin: 0;
        user-select: none;   
        width: 100%;
        table-layout: fixed;
        word-wrap: break-word;
    }

    // HEAD ----------
    .datepicker-switch, .prev, .next {
        transition: opacity 300ms;
    }

    .prev, .next {
        width: 25px;
        font-size: 0;
        background-image: url('../medias/images/icons/pointer.svg');
        background-repeat: no-repeat;
        background-size: 15px 18px;
        cursor: pointer;

        &.disabled {
            visibility: hidden;
        }
    }

    .datepicker-switch {
        width: calc(100% - 50px);
        font-size: rem(18);
        line-height: 1em;
        font-weight: 500;
        color: var(--calendar__header-color);

        @media screen and (max-width: 425px) { font-size: rem(18); }
    }

    .prev {
        transform: rotate(90deg);
        background-position: 50% 50%;
        transition: background 300ms $easeOutCubic;

        &:hover {
            background-position: 50% 60%;
        }
    }

    .next {
        transform: rotate(-90deg);
        background-position: 50% 50%;
        transition: background 300ms $easeOutCubic;

        &:hover {
            background-position: 50% 60%;
        }
    }
    // ---------------

    // DAYS ----------
    .dow {
        width: 38px;
        padding-top: 30px;
        padding-bottom: 10px;
        font: 700 rem(12)/1 var(--font-main);
        font-weight: 700;
        color: var(--calendar__day-of-the-week-color);
        text-align: center;
        cursor: default;
    }
    // ---------------

    // DATES ---------
    .day {
        position: relative;
        width: var(--calendar__square-size);
        height: var(--calendar__square-size);
        font: 500 1.2rem/1 var(--font-main);
        color: var(--calendar__date-color);
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        z-index: 1;
        transition: color 0.3s;

        &::before {
            content: '';
            position: absolute;
            height: 100%;
            width: var(--calendar__square-size);
            background: transparent;
            border-radius: 50%;
            left: 50%;
            top: 0;
            transform: translate(-50%, 0);
            transition: background 0.3s;
            z-index: -1;
        }

        &::after {
            content: '';
            position: absolute;
            width: 5px;
            height: 5px;
            background: transparent;
            border-radius: 50%;
            left: calc(50% + 8px);
            top: 10px;
            transition: background 0.3s;
            z-index: 1;
        }

        &.disabled, &.disabled:hover {
            background: none;
            cursor: default;

            &:after{
                display: none;
            }
        }

        &:not(.disabled){
            // &:after{ background: var(--calendar__date-dot-color); }
            &:hover {
                &::before { background: var(--calendar__date-background-hover); }
            }
        }
    }

    .day.today {
        font-weight: bold;
        &::before {
            background: var(--calendar__today-background);
            border-radius: 0;
            width: 15px;
            height: 2px;
            top: auto;
            bottom: 8px;
        }
        // &::after { background: var(--calendar__today-dot-color); }

        &:hover:not(.disabled) {
            color: var(--calendar__today-color-hover);
            &::before { background: var(--calendar__today-background-hover); }
            // &::after { background: var(--calendar__today-dot-color-hover); }
        }
    }

    .day.active {
        color: var(--calendar__active-color);
        &::before { background: var(--calendar__active-background); }
        &::after { background: var(--calendar__active-dot-color); }
        
        &:hover {
            color: var(--calendar__active-color-hover);
            &::before { background: var(--calendar__active-background-hover); }
            &::after { background: var(--calendar__active-dot-color-hover); }
        }
    }

    .old, .new {
        color: var(--calendar__other-month-date-color) !important;
        &::before { background: transparent !important; }
        // &::after { background: var(--calendar__other-month-date-dot-color) !important; }
    }

}