.news-nav{
    @extend .top-padding-80;

    &__wrapper{
        @extend .content-wrap;
        border-top: 2px solid var(--color-primary);
        padding-top: 30px;
        display: flex;
        justify-content: space-between;
    }

    &__prev,
    &__next{
        font-size: 1.8rem;
        font-weight: 500;
        color: var(--color-primary);
        display: flex;
        align-items: center;
        text-decoration: none;

        span{
            padding-right: 20px;
            display: inline-block;
        }

        svg{
            width: 19px;
            height: 26px;
            fill: var(--color-primary);
            transform: rotate(0deg);
            transition: transform 0.3s $easeInOutCubic;
        }

        &:not(span):hover{
            svg{
                transform: rotate(0) translate(10px, 0);
            }
        }
    }

    &__prev{
        flex-direction: row-reverse;

        span{
            padding-left: 20px;
            padding-right: 0;
            display: inline-block;
        }

        svg{
            transform: rotate(180deg);
        }

        &:not(span):hover{
            svg{
                transform: rotate(180deg) translate(10px, 0);
            }
        }
    }

    span.news-nav__prev,
    span.news-nav__next{
        color: #b5b8c0;
        svg{
            fill: #b5b8c0;
        }
    }
}