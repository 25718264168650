:root {
    --alert-desktop-width: 380px;
    --alert-mobile-height: 45px;
    --alert-desktop-right: calc( var(--x-padding-40) + 10px);
    --alert-desktop-top: 160px;
    --alert-color: #ce2929;

    @media screen and (max-width: 1300px) { --alert-desktop-width: 320px; }
    @media screen and (max-width: 1200px) { --alert-desktop-width: 300px; }
    @media screen and (max-width: 900px)  { --alert-desktop-width: 275px; }
}

.show-mobile-alerts {
    @media screen and (max-width: 768px) { .alerts--mobile { top: 0px; }}
}

.alerts {
    // Desktop et mobile
    &__close {
        position: absolute;
        width: 20px;
        height: 20px;
        transition: opacity 300ms;
    }
    
    &__close:before, &__close:after {
        content: '';
        position: absolute;
        width: 2px;
        height: 14px;
        top: 50%;
        left: 50%;
        background: #fff;
    }
    
    &__close:before { transform: translate(-50%, -50%) rotate(45deg);  }
    &__close:after  { transform: translate(-50%, -50%) rotate(-45deg); }
    &__close:hover  { opacity: 0.6; }


    // Desktop
    &--desktop {
        position: absolute;
        width: var(--alert-desktop-width);
        padding: var(--alert-desktop-top) 0 10px;
        top: 0;
        right: var(--alert-desktop-right);
        height: 100%;
        z-index: 22;
        display: flex;
        pointer-events: none;

        @media screen and (max-width: 1200px) { width: 320px; }
        @media screen and (max-width: 900px)  { width: 275px; }
        @media screen and (max-width: 850px)  { bottom: 109px; }
        @media screen and (max-width: 768px)  { display: none; }
    }

    &--desktop &__container {
        position: relative;
        overflow: hidden;
    }

    &--desktop &__wrapper {
        /*scrollbar-width: none;
        border-radius: 10px;

        &::-webkit-scrollbar {
            display: none;
        }*/
        //overflow: hidden;
        width: 100%;
    }

    &--desktop &__sticky {
        position: sticky;
        top: 30px;
        display: flex;
    }

    &--desktop &__scroll {
        pointer-events: all;
        max-height: calc(100vh - var(--alert-desktop-top));
        overflow: auto;
        will-change: transform; //Empêche un glitch quand on scroll dans les alertes. Pourquoi? no fucking idea 
        width: 100%;

        scrollbar-width: none;
        border-radius: 10px;

        &::-webkit-scrollbar {
            display: none;
        }
    }

    &--desktop &__close {
        top: 20px;
        right: 20px;
        display: block;
        pointer-events: all;
        cursor: pointer;
    }

    &--desktop &__alert {
        padding: 30px 35px;
        margin-bottom: 10px;
        background: var(--alert-color);
        width: 100%;
        border-radius: 10px;
        display: block;
        text-decoration: none;
        pointer-events: all;
        
        @media screen and (max-width: 1200px) { padding: 30px 20px; }
    }

    &--desktop &__common-container {
        margin-bottom: 15px;
    }

    &--desktop &__common-text {
        font-size: 1.8em;
        font-weight: bold;
        color: #fff;
        text-transform: uppercase;
        vertical-align: middle;
    }

    &--desktop &__common-svg {
        position: relative;
        top: -1px;
        width: 16px;
        height: 16px;
        margin-right: 10px;
        fill: #fff;
        vertical-align: middle;
    }

    &--desktop &__subtitle {
        margin-bottom: 5px;
        font-size: 1.5rem;
        font-weight: 500;
        color: #fff;
        margin-bottom: 10px;
    }
    
    &--desktop &__title {
        color: #fff;
        font-size: 1.8rem;
        line-height: 1.4em;
    }

    &--desktop &__link &__common-container,
    &--desktop &__link &__subtitle,
    &--desktop &__link &__title {
        opacity: 1;
        transition: opacity 300ms;
    }
    
    &--desktop &__link:hover &__common-container,
    &--desktop &__link:hover &__subtitle,
    &--desktop &__link:hover &__title {
        opacity: 0.6;
    }


    // Mobile
    &--mobile {
        position: relative;
        width: 100%;
        //top: calc(0px - var(--alert-mobile-height));
        height: 0;
        top: 0;
        left: 0;
        opacity: 0;
        visibility: hidden;
        transition: height 300ms $easeOutCubic;
        z-index: 1;
        overflow: hidden;
        
        @media screen and (max-width: 768px) { opacity: 1; visibility: visible; height: var(--alert-mobile-height) }
    }
    
    &--mobile &__wrapper {
        display: flex;
        align-items: center;
        height: var(--alert-mobile-height);
        padding: 0 20px;
        background: var(--alert-color);
    }

    &--mobile &__close {
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
    }

    &--mobile &__nav {
        position: absolute;
        top: 50%;
        left: 10px;
        font-size: 1.3rem;
        color: #fff;
        transform: translateY(-50%);

        @media screen and (max-width: 500px) { font-size: 1.2rem; }
    }
    
    &--mobile &__slider {
        width: calc(100% - 50px);
        margin: 0 20px 0 auto;
    }

    &--mobile &__slider .slick-track, &--mobile &__slider .slick-slide { font-size: 0; }
    
    &--mobile &__slide {
        text-align: center;
    }

    &--mobile &__text {
        display: block;
        margin-bottom: 0;
        width: 100%;
        font-size: 1.6rem;
        color: #fff;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-decoration: none;

        @media screen and (max-width: 500px) { font-size: 1.3rem; }
    }

    &--mobile &__link {
        opacity: 1;
        transition: opacity 300ms;

    }

    &--mobile &__link:hover { opacity: 0.6; }
}