:root{
    --overlay-calendar__background-color: white;
    --overlay-calendar__padding: 50px 50px;
    --overlay-calendar__top-spacing: 20px;
    --overlay-calendar__width: 270px;
}

.overlay-calendar{
    position: absolute;
    left: 50%;
    transform: translate(-50%,0);
    margin-top: var(--overlay-calendar__top-spacing);
    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s ease;

    &__content{
        position: relative;
        background-color: var(--overlay-calendar__background-color);
        border-radius: 10px;
        box-shadow: 0 0 20px rgba(0,0,0,0.2);

        &::after{
            content: '';
            position: absolute;
            bottom: 100%;
            left: 20%;
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid var(--overlay-calendar__background-color);
        }
    }

    &__wrapper{
        overflow: hidden;
        padding: var(--overlay-calendar__padding);
    }

    &__transition{
        transform: translate(0,60px);
        opacity: 0;
        transition: transform 0.3s ease, opacity 0.3s ease;
    }

    &__inner{
        width: var(--overlay-calendar__width);
    }

    &__reset-button-container{
        text-align: center;
        margin-top: 25px;
    }

    &__reset-button{
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        font-size: 1.8rem;
        font-weight: 500;
        color: var(--color-primary);
        transition: color 0.3s ease;
    }

    &__reset-text{
        padding-right: 16px;
    }

    &__reset-svg{
        width: 22px;
        height: 19px;
        fill: var(--color-primary);
        transform: rotate(0deg);
        transform-origin: 42% 50%;
        transition: fill 0.3s ease, transform 0.3s ease;
    }

    &__reset-button:hover{ color: var(--color-secondary);}
    &__reset-button:hover &__reset-svg{
        fill: var(--color-secondary);
        transform: rotate(80deg)
    }
}