.contact-form-wrapper{
    position: relative;

    &__call-to-action{
        position: absolute;
        top: -100px;
        right: 0;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }
}
