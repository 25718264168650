.show-overlay-dropdown:not(.closing-overlay-dropdown){
    header{
        background-color: var(--color-primary);
    }
}

@media screen and (max-width: 768px) {
    .show-overlay-search:not(.closing-overlay-search){
        header{
            background-color: white;
        }
    }
}