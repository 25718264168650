:root{
    --news-slider__slides-margin: 50px;

    @media screen and (max-width: 768px){
        --news-slider__slides-margin: 30px;
    }
}

.news-slider{

    --text-color: white;
    --background-color: var(--color-primary);

    &--light {
        --text-color: var(--color-primary);
        --background-color: var(--color-background); 
    }

    &__grid{
        display: flex;
        flex-wrap: wrap;
    }

    &__text{
        width: 25%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        will-change: transform;

        position: relative;
        z-index: 4;
        background: var(--background-color);
        margin: -30px 0;
        @media screen and (max-width: 768px){
            margin: 0;
            justify-content: flex-start;
        }

        &::after{
            content: '';
            position: absolute;
            right: 100%;
            top: 0;
            bottom: 0;
            width: 100vw;
            background-color: var(--background-color);
        }

        @media screen and (max-width: 1024px){
            width: 40%;
        }

        @media screen and (max-width: 768px){
            width: 100%;
        }
    }

    &__text-wrapper{
        max-width: 100%;
        overflow: hidden;
    }

    &__title{
        color: var(--text-color);
        font-weight: bold;
        font-size: 13rem;
        position: relative;
        right: -35px;

        @media screen and (max-width: 1600px){
            position: static;
        }

        @media screen and (max-width: 768px){
            font-size: 6rem;
        }
    }

    &__title-break{
        display: block;
        padding-left: 0.7em;

        @media screen and (max-width: 768px){
            display: inline;
            padding-left: 0;
        }
    }

    &__button{
        @extend .top-padding-40;
        @media screen and (max-width: 768px){
            display: none;
        }
    }

    &__button-mobile{
        @extend .top-padding-40;
        display: none;
        @media screen and (max-width: 768px){
            display: block;
        }
    }

    &__slides-container{
        width: 75%;

        overflow: hidden;
        border-radius: 10px;

        @media screen and (max-width: 1024px){
            width: 60%;
        }

        @media screen and (max-width: 768px){
            padding-top: var(--y-padding-40);
            width: 100%;
        }

        --card__ratio: 143%; // 510/355

        .slick-track{
            will-change: transform;
            margin-left: 0;
        }

        .slick-list{
            margin-right: 180px;
            border-radius: 10px;
            font-size: 0;
            overflow: visible;

            @media screen and (max-width: 768px){
                margin-right: -90%;
            }
        }
        .slick-slider {
            margin-right: calc(var(--news-slider__slides-margin)*-1)
        }
        .slick-slide {
            margin-right: calc(var(--news-slider__slides-margin));
        }
    }

    .slick-prev.slick-arrow{
        display: none !important;
    }

    .slick-next.slick-arrow{
        position: absolute;
        right: 16%;
        top: 50%;
        width: 60px;
        height: 60px;

        @media screen and (max-width: 1024px){
            right: 10%;
        }

        @media screen and (max-width: 768px){
            display: none !important;
        }

        svg{
            position: absolute;
            left: 50%;
            top: 50%;
            fill: white;
            transform: translate(-50%,-50%);
            z-index: 2;
            width: 28px;
            height: 25px;
        }

        &::before{
            display: none;
        }

        &::after{
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            transform: translate(-50%,-50%) scale(1);
            left: 50%;
            top: 50%;
            background-color: var(--color-secondary);
            transition: transform 0.3s ease;
            z-index: 1;
        }

        &:hover{
            &::after{
                transform: translate(-50%,-50%) scale(1.1);
            }
        }
    }

}