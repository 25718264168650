:root{
    --search-form__height: 70px;
    --search-form__icon-size: 25px;

    @media screen and (max-width: 768px) {
        --search-form__height: 50px;
        --search-form__icon-size: 18px;
    }
}

.search-form{
    position: relative;
    &__input{
        border: 1px solid var(--color-primary);
        border-radius: var(--search-form__height);
        height: var(--search-form__height);
        width: 100%;
        padding: 0 100px 0 30px;
        font-size: 2.2rem;
        color: var(--color-primary);
        font-family: var(--font-main);
        display: block;

        -webkit-appearance: none !important;
        -moz-appearance: none !important;
    }
    &__x{
        position: absolute;
        background-color: var(--color-background);
        width: 22px;
        height: 22px;
        border-radius: 50%;
        top: 50%;
        right: 65px;
        transform: translate(0, -50%);
        cursor: pointer;
        opacity: 1;
        transition: opacity 0.3s ease;
        &::before,
        &::after{
            content: '';
            position: absolute;
            width: 10px;
            height: 1px;
            left: 50%;
            top: 50%;
            background-color: var(--color-primary);
            transform: translate(-50%,-50%) rotate(45deg);
        }
        &::after{
            transform: translate(-50%,-50%) rotate(-45deg);
        }
        &:hover{
            opacity: 0.4;
        }
    }

    &__input:placeholder-shown + &__x {
        opacity: 0;
        pointer-events: none;
    }

    &__btn{
        position: absolute;
        right: 0;
        width: 65px;
        top: 0;
        height: 100%;
        cursor: pointer;
        opacity: 1;
        border: none;
        background: transparent;
        transition: opacity 0.3s ease;

        &:hover{
            opacity: 0.5;
        }
    }
    &__button-svg{
        position: absolute;
        width: var(--search-form__icon-size);
        height: var(--search-form__icon-size);
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        fill: var(--color-primary);
    }
    .field__error{
        position: absolute;
    }

    input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: var(--color-primary);
        opacity: 1;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 100px white inset !important;
        -webkit-text-fill-color: var(--color-primary) !important;
    }
}
