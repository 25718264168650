//base, 1440, 1024, 768, 425, 375, 320

.huge-title { //h1
    font-size: 10rem;
    font-weight: bold;
    line-height: 1.15em;

    @media screen and (max-width: 1024px) { font-size: 6rem; }
    @media screen and (max-width: 768px) { font-size: 5rem; }
    @media screen and (max-width: 425px) { font-size: 4rem; }
}

.large-title { //h2
    font-size: 4rem;
    line-height: 1.15em;
    font-weight: 500;

    @media screen and (max-width: 1024px) { font-size: 3.2rem; }
    @media screen and (max-width: 768px) { font-size: 3rem; }
    @media screen and (max-width: 425px) { font-size: 2.6rem; }
}

.medium-title { //h3
    font-size: 3rem;
    line-height: 1.3em;
    font-weight: 500;

    @media screen and (max-width: 1024px) { font-size: 2.4rem; } //0.8
    @media screen and (max-width: 768px) { font-size: 2.25rem; } //0.75
    @media screen and (max-width: 425px) { font-size: 1.95rem; } //0.65
}

.small-title { //h4
    font-size: 2.5rem;
    line-height: 1.3em;
    font-weight: bold;

    @media screen and (max-width: 1024px) { font-size: 2rem; } //0.8
    @media screen and (max-width: 768px) { font-size: 1.875rem; } //0.75
    @media screen and (max-width: 425px) { font-size: 1.625rem; } //0.65
}

.minuscule-title {
    font-size: 1.3rem;
    line-height: 1.5em;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.paragraph{
    font-size: 2rem;
    line-height: var(--dynamic-p-font-line-height);

    @media screen and (max-width: 768px) { font-size: 1.6rem; }
    @media screen and (max-width: 375px) { font-size: 1.5rem; }
}

.introduction{
    font-size: 4.5rem;
    line-height: 1.5em;

    @media screen and (max-width: 1300px) { font-size: 3.5rem; }
    @media screen and (max-width: 1024px) { font-size: 3rem; }
    @media screen and (max-width: 768px) { font-size: 2.3rem; }
    @media screen and (max-width: 425px) { font-size: 2.2rem; }
    @media screen and (max-width: 375px) { font-size: 2.1rem; }
    @media screen and (max-width: 320px) { font-size: 2.0rem; }
}

