.no-results{
    color: var(--color-primary);

    &.news {
        color: white;
    }

    &__main{
        @extend .large-title;
    }
    &__sub{
        @extend .medium-title;
        @extend .top-padding-30;

        a{
            color: var(--color-primary);
            text-decoration: none;

            box-shadow: inset 0 -0.1em 0 0 var(--color-secondary);
            transition: box-shadow 0.3s;

            &:hover{
                box-shadow: inset 0 -1.1em 0 0 var(--color-secondary);
            }

        }
    }
}