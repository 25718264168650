:root{
    --breadcrumbs__height: 60px;
    --breadcrumbs__icon-size: 14px;
    --breadcrumbs__max-width: 280px;
    --breadcrumbs__min-width: 210px;
    --breadcrumbs__shadow: 0 0 14px rgba(0,0,0,0.2);

    @media screen and (max-width: 768px){
        --breadcrumbs__max-width: 100%;
    }
}

.breadcrumbs{

    font-size: 1.6rem;
    color: var(--color-primary);
    display: inline-flex;
    position: relative;
    max-width: 100%;

    &__current{
        background-color: white;
        border: 1px solid var(--color-line);
        border-radius: 8px;
        padding: 0 28px;
        height: var(--breadcrumbs__height);
        font-weight: bold;
        display: flex;
        align-items: center;
        cursor: pointer;
        position: relative;
        z-index: 2;
        transition: border 0.3s, box-shadow 0.3s, border-radius 0.3s;
        max-width: 100%;
    }

    &__current-text{
        padding-right: 28px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: var(--breadcrumbs__max-width);
        min-width: var(--breadcrumbs__min-width);
        user-select: none;
    }

    &__current-icon{
        width: var(--breadcrumbs__icon-size);
        height: var(--breadcrumbs__icon-size);
        position: relative;
    }

    &__current-arrow{
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%) rotate(90deg);
        width: var(--breadcrumbs__icon-size);
        height: var(--breadcrumbs__icon-size);
        transition: transform 0.3s;
    }

    &__drop-container{
        position: absolute;
        width: 100%;
        height: 0;
        overflow: hidden;
        box-shadow: none;
        border-radius: 8px;
        background-color: white;
        z-index: 1;
        transition: height 0.6s $easeOutQuart, box-shadow 0.3s;
    }

    &__drop{
        padding-top: calc( var(--breadcrumbs__height) + 30px);
        padding-bottom: 30px;
    }

    &__drop-link{
        text-decoration: none;
        color: rgba(var(--color-primary-rgb),0.6);
        display: block;
        font-weight: 500;
        padding: 12px 40px;
        transition: color 0.3s;
        position: relative;
        &:hover{
            color: rgba(var(--color-primary-rgb),1);
        }
        &--active{
            color: rgba(var(--color-primary-rgb),1);
            &::before{
                content: '';
                position: absolute;
                width: 6px;
                height: 6px;
                background-color: var(--color-primary);
                left: 15px;
                top: 21px;
                transform: translate(0,-50%);
                border-radius: 50%;
            }
        }
    }

    &:hover{
        .breadcrumbs__current{
            border: 1px solid transparent;
            box-shadow: var(--breadcrumbs__shadow);
        }
    }
}