:root {
    --dynamic-main-color: var(--color-primary);
    --dynamic-main-color-rgb: var(--color-primary-rgb);

    --dynamic-p-font-line-height: 1.7em; //Doit inclure le 'em' autrement les bullets des listes ne seront pas alignées  //34/20

    --dynamic-ol-indent: 50px;
    --dynamic-ol-indent-last: 40px;
    --dynamic-ul-indent: 20px;
    --dynamic-ul-bullet-size: 6px;
    --dynamic-ul-bullet-color: var(--color-primary);

    --dynamic-table-header-background: var(--color-primary);
    --dynamic-table-header-color: white;
    --dynamic-table-border-color: var(--color-line-2);
    --dynamic-table-secondary-header-background: var(--color-secondary);

    @media screen and (max-width: 768px) {
        --dynamic-ol-indent: 25px;
        --dynamic-ol-indent-last: 25px;
    }
}

.dark{
    --dynamic-main-color: white;
    --dynamic-main-color-rgb: 255, 255, 255;

    --dynamic-ul-bullet-color: white;
}


.dynamic{

    > :first-child { margin-top: 0 !important; }
    > :last-child { margin-bottom: 0 !important; }

    color: var(--dynamic-main-color);

    h1,h2,h3,h4,h5,h6{
        color: var(--dynamic-main-color);
    }

    h1{
        @extend .huge-title;
        margin-top: 100px;
        margin-bottom: 100px;

        @media screen and (max-width: 1024px) {
            margin-top: 50px;
            margin-bottom: 50px;
        }
    }

    h2{
        @extend .large-title;
        margin-top: 45px;
        margin-bottom: 25px;
    }

    h3{
        @extend .medium-title;
        margin-top: 45px;
        margin-bottom: 25px;
    }

    h4{
        @extend .small-title;
        margin-top: 45px;
        margin-bottom: 25px;
    }

    h2 + h3,
    h3 + h4,
    h4 + h5,
    h5 + h6{
        margin-top: 0;
    }

    p{
        --dynamic-p-margin: 25px;
        --dynamic-p-margin-under-button: 5px; //Marge sous les boutons si ils tombent sur 2 lignes
        --dynamic-p-margin-button: 40px; //Plus grosse marge pour paragraphe contenant des boutons

        @extend .paragraph;
        margin-top: var(--dynamic-p-margin);
        margin-bottom: var(--dynamic-p-margin);
        color: var(--dynamic-main-color);

        &.intro{
            @extend .introduction;
            margin-left: auto;
            margin-right: auto;
            max-width: 750px;
            position: relative;
            margin-bottom: 130px;

            @media screen and (max-width: 1024px){
                max-width: none;
                margin-bottom: 60px;
                padding-left: 100px;
            }

            @media screen and (max-width: 768px){
                padding-left: 40px;
            }

            .intro__circle{
                --circle-size: 330px;
                @media screen and (max-width: 1300px){ --circle-size: 350px; }
                @media screen and (max-width: 1024px){ --circle-size: 240px; }
                @media screen and (max-width: 768px){ --circle-size: 156px; }

                width: var(--circle-size);
                height: var(--circle-size);
                border-radius: 50%;
                background-color: white;
                display: block;
                position: absolute;
                left: -200px;
                top: -110px;
                z-index: -1;

                @media screen and (max-width: 1024px){
                    left: -15px;
                    top: -50px;
                }

                @media screen and (max-width: 768px){
                    top: -40px;
                }
            }
        }

        &.buttonMargin{
            margin-bottom: calc(var(--dynamic-p-margin) - var(--dynamic-p-margin-under-button));
            .btn,.btn--secondary{ // Ajouter une marge sous les boutons
                margin-bottom: var(--dynamic-p-margin-under-button);
                &:not(:last-child){
                    margin-right: 30px;
                }
            }
            &--first{ margin-top: var(--dynamic-p-margin-button) }
            &--last{ margin-bottom: calc(var(--dynamic-p-margin-button) - var(--dynamic-p-margin-under-button) ) }
        }

        &.captcha_note{
            color: var(--field-label-color);
            font-size: var(--field-label-font-size);
            font-weight: var(--field-label-font-weight);

            a{
                color: var(--field-label-color) !important;
                transition: color 0.3s ease !important;
                //box-shadow: none !important;

                svg{
                    display: none !important;
                }

                &:hover{
                    color: white !important;
                    box-shadow: inset 0 -0.1em 0 0 var(--color-secondary) !important;
                }
            }
        }
    }

    strong,b{
        font-weight: 700;
    }

    em,i{
        font-style: italic;
    }

    u{
        text-decoration: none;
        border-bottom: 1px solid var(--color-primary);
    }

    sup{
        font-size: 0.7em;
        vertical-align: baseline;
        position: relative;
        top: -0.3em;
    }
    sub{
        font-size: 0.7em;
        vertical-align: baseline;
        position: relative;
        top: 0.3em;
    }

    a:not(.btn):not(.btn--secondary){
        color: var(--dynamic-main-color);
        text-decoration: none;
        box-shadow: inset 0 -0.1em 0 0 var(--color-secondary);
        transition: box-shadow 0.3s;

        &:hover{
            box-shadow: inset 0 -1.1em 0 0 var(--color-secondary);
        }

        //Icones liens externes et téléchargement
        svg{
            margin-left: 8px;
            width: 11px;
            height: 11px;
            fill: var(--dynamic-main-color);
        }

        &[href$=".pdf"],
        &[href$=".doc"],
        &[href$=".zip"]{
            svg{
                width: 12px;
                height: 12px;
            }
        }
    }

    ol,
    ul{
        margin-top: 30px;
        margin-bottom: 30px;
        color: var(--dynamic-main-color);
        li{
            &:not(:first-child){ margin-top: 25px; }

            @extend .paragraph;

            ol,ul{
                margin-top: 20px;
                margin-bottom: 0;
                width: 100%;
                li{
                    &:not(:first-child){ margin-top: 20px; }
                }
            }
        }
    }

    ol{
        list-style: none;
        counter-reset: li;
        & > li{
            position: relative;
            padding-left: var(--dynamic-ol-indent);
            &::before{
                content: counter(li) ".";
                counter-increment: li;
                font-size: 2rem;
                font-weight: bold;
                position: absolute;
                display: inline-block;
                left: 0;
                top: calc(var(--dynamic-p-font-line-height) / 2);
                transform: translate(0, -50%);

                @media screen and (max-width: 768px) { font-size: 1.6rem; }
                @media screen and (max-width: 375px) { font-size: 1.5rem; }
            }

            ol > li{ // <ol> 2e niveau
                &::before{ content: counter(li, lower-alpha) "."; }
                ol > li{ // <ol> 3e niveau
                    &::before{ content: counter(li, lower-roman) "."; }
                    padding-left: var(--dynamic-ol-indent-last);
                }
            }
        }
    }

    ul{
        & > li{
            padding-left: var(--dynamic-ul-indent);
            position: relative;
            &::before{
                content: '';
                width: var(--dynamic-ul-bullet-size);
                height: var(--dynamic-ul-bullet-size);
                border: 1px solid var(--dynamic-ul-bullet-color);
                background:var(--dynamic-ul-bullet-color);
                position: absolute;
                left: 0;
                transform: translate(0,-50%);
                border-radius: 50%;
                box-sizing: border-box;
                top: calc(var(--dynamic-p-font-line-height) / 2);
            }

            & > ul{
                li{
                    &::before{ background: transparent; }
                    & > ul{
                        li{
                            &::before{ background: var(--dynamic-ul-bullet-color); }
                        }
                    }
                }
            }
        }
    }

    blockquote{
        position: relative;
        margin-top: 35px;
        margin-bottom: 35px;
        border-left: 1px solid var(--color-primary);
        padding-left: 50px;

        @media screen and (max-width: 768px) {padding-left: 35px; }
        @media screen and (max-width: 600px) {padding-left: 20px; }

        p{
            font-size: 2.8rem;
            line-height: 1.5em;

            @media screen and (max-width: 1024px) { font-size: 2.3rem; }
            @media screen and (max-width: 768px)  { font-size: 2rem; }
        }
    }

    .emphasis{
        //--dynamic-main-color: white;
        //--dynamic-ul-bullet-color: white;

        background-color: transparent;
        margin-top: 30px;
        margin-bottom: 65px;
        padding: 45px 60px;
        border: 1px solid var(--color-line);
        border-bottom: 5px solid var(--color-primary);

        @media screen and (max-width: 768px)  {
            padding: 30px;
        }

        & > :first-child{ margin-top: 0; }
        & > :last-child{ margin-bottom: 0}
    }

    .table-wrapper{
        overflow-x: auto;
        margin-top: 30px;
        margin-bottom: 65px;

        table{
            overflow-x: hidden;
            width: 100%;
            min-width: 600px;
            border-collapse: collapse;
            background-color: white;
            tr{
                th,td{
                    padding: 20px 35px;
                    color: var(--dynamic-main-color);
                    line-height: 1.5625em; // 25px/16px
                    border: 1px solid var(--dynamic-table-border-color);
                    @include fontsize(17, 0, 0, 16, 0, 15, 0);

                    & > :first-child{ margin-top: 0; }
                    //& > :last-child{ margin-bottom: 0}

                    & > p, & > pre, & > strong, & > em, & > a, & > span, & > sub, & > sup, & > ul, & > ol {
                        &:last-child {
                            margin-bottom: 0 !important;
                        }
                    }
                }
                th,
                td.header{
                    background-color: var(--dynamic-table-header-background);
                    font-weight: normal;
                    color: var(--dynamic-table-header-color);
                    border-top-color: var(--dynamic-table-header-background);
                    border-bottom-color: var(--dynamic-table-header-background);
                    text-align: left;

                    &:first-child{ border-left-color: var(--dynamic-table-header-background)}
                    &:last-child{ border-right-color: var(--dynamic-table-header-background)}
                }
                td{
                  &.secondary-header{
                    border-color: #c9dce5;
                    background-color: var(--dynamic-table-secondary-header-background);
                  }
                }
            }
        }
    }

    pre{
        font-family: var(--font-main);
        white-space: normal;
        font-size: 1.4em;
        line-height: 1.714285714285714em; // 24px/14px
        color: var(--dynamic-main-color);
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .table-wrapper,
    .emphasis{
        // Modifier la marge pour les notes sous les tableaux et bloc d'emphases
        & + pre{
            margin-top: -45px;
        }
        // Modifier la marge pour les tableaux sous les tableaux
        & + .table-wrapper,
        & + .emphasis{
            margin-top: -35px;
        }
    }

    &--smaller-text{
        p{
            font-size: 1.8em;
        }
    }

    /* ----- Image - No Caption ----- */

	img {

        position: relative;
        max-width: 100%;

        &.fr-dib {

			/* block */

            display: block;
			margin: 30px auto;
            float: none;
            vertical-align: top;

            &.fr-fil {
                margin-left: 0;
            }

            &.fr-fir {
                margin-right: 0;
            }
        }

        &.fr-dii {

			/* Inline */

            display: inline-block;
            float: none;
            vertical-align: bottom;
            margin: 10px 20px;
            max-width: calc(100% - (2 * 20px));

            &.fr-fil {
                float: left;
                margin: 10px 20px 10px 0;
                max-width: calc(100% - 20px);
            }

            &.fr-fir {
                float: right;
                margin: 10px 0 10px 20px;
                max-width: calc(100% - 20px);
            }
        }
    }

	/* ----- Image - With Caption ----- */

    .fr-img-caption {

		img {
			width: 100%;
		}

        &.fr-dib {

			/* block */

            display: block;
			margin-left: auto;
			margin-right: auto;
            float: none;
            vertical-align: middle;
			text-align: center;

            &.fr-fil {
                margin-left: 0;
				text-align: left;
            }

            &.fr-fir {
                margin-right: 0;
				text-align: right;
            }

			.fr-img-wrap {

				.fr-inner {

                    font-family: var(--font-main);
                    white-space: normal;
                    font-size: 0.7em;
                    line-height: 1.714285714285714em; // 24px/14px
                    color: var(--dynamic-main-color);
                    margin-top: 25px;
                    margin-bottom: 25px;
				}
			}
        }

        &.fr-dii {

			/* Inline */

            display: inline-block;
            float: none;
            vertical-align: bottom;
            margin: 10px 20px;
            max-width: calc(100% - (2 * 20px));
			text-align: center;

            &.fr-fil {
                float: left;
                margin-left: 0;
				text-align: left;
                max-width: calc(100% - 20px);
            }

            &.fr-fir {
                float: right;
				margin-right: 0;
                text-align: right;
                max-width: calc(100% - 20px);
            }
        }
    }

    video {
        max-width: 560px;
        max-height: 315px;
        width: 100%;
    }

    &--contact{

        @media screen and (max-width: 1024px) {
            h4{
                margin-top: 30px;
            }

            ol,
            ul{
                margin-top: 20px;
                li{
                    &:not(:first-child){ margin-top: 5px; }
                    ol,ul{
                        li{
                            &:not(:first-child){ margin-top: 5px; }
                        }
                    }
                }
            }
        }
    }

}
