:root{
    --other-events__margin: 160px;
    --other-events__separation-width: 80px;
    --other-events__vertical-spacing: 100px;
    --other-events__background: white;


    @media screen and (max-width: 1300px) {
        --other-events__margin: 100px;
    }

    @media screen and (max-width: 1024px) {
        --other-events__margin: 80px;
    }

    @media screen and (max-width: 768px) {
        --other-events__margin: 0;
        --other-events__vertical-spacing: 70px;
    }
}

.other-events{
    @extend .y-padding-150;
    position: relative;
    //overflow: hidden;

    @media screen and (max-width: 768px) {
        padding: 70px 0;
        overflow: visible;
    }

    &__grid{
        display: flex;
        flex-wrap: wrap;
        margin-left: calc(var(--other-events__margin) * -1);
        margin-right: calc(var(--other-events__margin) * -1);
        margin-bottom: calc(var(--other-events__vertical-spacing) * -1);
    }

    &__calendar-btn-container{
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%,0);
        z-index: 3;
    }

    &__separation{
        position: absolute;
        left: 50%;
        top: 0;
        //height: 100%;
        bottom: -80px;
        transform: translate(-50%,0);
        width: var(--other-events__separation-width);
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 1px;
            height: 100%;
            background-color: var(--color-primary);
            z-index: 1;
        }

        @media screen and (max-width: 768px) {
            position: relative;
            height: auto;
            transform: none;
            width: calc(100% + var(--x-padding-40) * 2 );
            left: auto;
            margin: 0 calc(var(--x-padding-40) * -1);
            margin-bottom: var(--y-padding-60);

            &::after{
                top: 50%;
                left: 0;
                width: 100%;
                height: 1px;
            }
        }
    }

    &__separation-text{
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        z-index: 2;
        font-size: 4rem;
        font-weight: bold;
        color: var(--color-primary);
        line-height: var(--other-events__separation-width);
        text-transform: uppercase;
        white-space: nowrap;

        @media screen and (max-width: 768px) {
            position: relative;
            transform: none;
            line-height: normal;
            top: auto;
            left: auto;
            text-align: center;
        }

        @media screen and (max-width: 550px) {
            font-size: 2.3rem;
        }
    }

    &__separation-text-rotation{
        transform: rotate(-90deg);

        @media screen and (max-width: 768px) {
            transform: none;
            display: inline-block;
            padding: 0 20px;
        }
    }

    &__separation-text-wrapper{
        background-color: var(--other-events__background);
        padding: 0 50px;
        will-change: transform;
        @media screen and (max-width: 768px) {
            transform: none !important; //Désactiver le parallax en mobile
        }
    }

    &__container{
        --card__ratio: 141.304%; // 455px / 322px
        display: flex;
        flex-direction: column;
        margin-bottom: var(--other-events__vertical-spacing);
        position: relative;

        @media screen and (max-width: 768px) {
            --card__ratio: 123.59550%; //330px / 267px
            transform: none !important; //Désactiver le parallax en mobile
        }

        width: 50%;
        padding: 0 calc(var(--other-events__margin));
        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__button{
        padding-bottom: var(--y-padding-80);
        @media screen and (max-width: 768px) {
            order: 2;
            padding-bottom: 0;
            padding-top: var(--y-padding-80);
        }
    }
    &__button--no-item{
        @media screen and (max-width: 768px) {
            padding-top: 0;
        }
    }

    &--with-gaps{
        @media screen and (max-width: 768px) {
            padding-top: 130px;
        }

        @media screen and (max-width: 500px) {
            padding-top: 100px;
        }
    }

    &--with-gaps &__container:nth-child(odd){
        top: 100px;
        @media screen and (max-width: 768px) {
            top: 0;
        }
    }

    &--with-wrapper{
        @extend .content-wrap--bit-smaller;
    }

    &--no-bar &__separation{
        &::after{
            display: none;
        }
    }

}