:root{
    --home-fast-links__title-size: 9.6rem;
    --home-fast-links__margin: 30px;
    @media screen and (max-width: 1300px) {
        --home-fast-links__title-size: 7rem;
    }
    @media screen and (max-width: 1024px) {
        --home-fast-links__title-size: 6rem;
    }
    @media screen and (max-width: 768px) {
        --home-fast-links__margin: 0;
    }
}

.home-fast-links{

    --text-color: var(--color-primary);
    
    &--light {
        --text-color: white;
    }

    &__grid{
        display: flex;
        flex-wrap: wrap;
        margin-left: calc(var(--home-fast-links__margin) * -1);
        margin-right: calc(var(--home-fast-links__margin) * -1);
    }

    &__left{
        width: 50%;
        padding: 0 calc(var(--home-fast-links__margin));
        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }

    &__call-to-action-container{
        padding-top: 120px;
        display: inline-block;
        position: relative;
        @media screen and (max-width: 1300px){ padding-top: 80px; }
    }

    &__call-to-action{
        position: absolute;
        top: -30px;
        right: -30px;

        @media screen and (max-width: 768px){ top: -10px; }
    }

    &__call-to-action-title{
        color: var(--text-color);
        font-size: var(--home-fast-links__title-size);
        line-height: 0.82291em; // 79/96
        font-weight: bold;
        @media screen and (max-width: 768px){ 
            transform: none !important;    
        }
    }

    &__break{
        display: block;
        padding-left: 0.7em;
    }

    &__right{
        width: 50%;
        padding: 0 calc(var(--home-fast-links__margin));
        @media screen and (max-width: 768px) {
            width: 100%;
            padding-top: var(--y-padding-60);
        }
    }
}