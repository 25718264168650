/*** ----- COLORS ----- ***/
:root{
    --color-primary: #273353; // Bleu
    --color-primary-rgb: 39, 51, 83; // Bleu

    --color-secondary: #adcad8; // Bleu pâle
    --color-secondary-2: #e6eff3; // Bleu plusse pâle

    --color-tertiary: #efc480; // Jaune

    --color-text: var(--color-primary); // Gris texte

    --color-background: #f3f1ef;
    --color-line: #c3c9cc;
    --color-line-rgb: 195, 201, 204;
    --color-line-2: #cacbd0;

    --color-error: #bd0000; // Rouge erreur

    --logo__color-1: #59873a;
    --logo__color-2: #044775;
    --logo__color-3: #24272a;
}

/*** ----- FONTS ----- ***/
:root{
    --font-main: 'Figtree', sans-serif;
}
