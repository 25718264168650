/*
|--------------------------------------------------------------------------
| OVERLAY VISIBLE
|--------------------------------------------------------------------------
*/
.show-overlay-menu {
    .header-mobile { box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15); }

    .header__menu-button{
        //background-color: var(--color-primary);
        //border-color: var(--color-primary);
        .hamburger__line{
            //background-color: white;
        }
    }
    
    .btn-menu {
        /*&__line1 { width: 0%; top: 16px; left: 50%; opacity: 0; }
        &__line2 { transform: rotate(45deg); width: 100%; }
        &__line3 { transform: rotate(-45deg); width: 100%; }
        &__line4 { width: 0%; top: 16px; left: 50%; opacity: 0; }*/
    }
    
    .overlay-menu {
        visibility: visible;
        pointer-events: all;
        
        &__background {
            opacity: 1;
        }
        &__container {
            left: 0;
        }
        &__main-level {
            opacity: 1;
            transform: none;
        }

    }

    &[data-section]:not([data-section=""]){
        .overlay-menu .overlay-menu__container-inner{
            transform: translate(-100vw,0);
        }
    }

}


/*
|--------------------------------------------------------------------------
| OVERLAY SE FERME
|--------------------------------------------------------------------------
*/
.show-overlay-menu.closing-overlay-menu {


    &__container-inner{
        transform: translate(-100vw,0);
    }
    
    .overlay-menu {
        &__background {
            opacity: 0;
            transition: opacity 0.5s 0.3s;
        }
        &__container {
            left: -100vw;
        }
    }
}


/*
|--------------------------------------------------------------------------
| SOUS-MENU OUVERT
|--------------------------------------------------------------------------
*/
.overlay-menu {
    .js-accordion-opened &__second-level-svg { transform: rotate(-90deg); }
    .js-accordion-opened &__third-level { opacity: 1; }
    &__second-level-li.js-accordion-opened &__third-level { margin-top: 10px; }
}

/*
|--------------------------------------------------------------------------
| SECTION VISIBLE
|--------------------------------------------------------------------------
*/
@mixin sectionMobile($section) {
    .show-overlay-menu[data-section="#{$section}"] {
        .overlay-menu__level-2-section[data-section="#{$section}"] {
            visibility: visible;
            position: static;
            transition: visibility 0s ease, position 0s ease, height 0s ease;
            //height: 800px;
        }
    }
}

@include sectionMobile(ville);
@include sectionMobile(services);
@include sectionMobile(loisirs);
