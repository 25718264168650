:root{
    --link-list__bullet-size: 60px;
    --link-list__bullet-margin: 30px;
    --link-list__bullet-icon-size: 26px;
    @media screen and (max-width: 1024px) {
        --link-list__bullet-size: 40px;
        --link-list__bullet-margin: 20px;
        --link-list__bullet-icon-size: 18px;
    }
}

.link-list{

    &__item{
        &:not(:first-child){
            margin-top: 40px;
        }
    }

    &__link{
        color: var(--text-color);
        font-size: 4rem;
        text-decoration: none;
        line-height: 1.15em;
        padding-left: calc(var(--link-list__bullet-size) + var(--link-list__bullet-margin));
        display: block;
        position: relative;
        pointer-events: none;

        @media screen and (max-width: 1024px) {
            font-size: 3rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 2.8rem;
            padding-left: 0;
        }
    }

    &__link-text{
        pointer-events: all;
        display: inline-block;
    }

    &__link-arrow{
        position: absolute;
        display: block;
        width: var(--link-list__bullet-size);
        height: var(--link-list__bullet-size);
        background-color: var(--color-tertiary);
        border-radius: 50%;
        top: 50%;
        left: 0;
        transform: translate(-50px, 0) translate(0, -50%);
        opacity: 0;
        transition: transform 0.3s $easeOutQuart, opacity 0.3s;

        @media screen and (max-width: 768px) {
            display: none;
        }
    }

    &__link-arrow-svg{
        position: absolute;
        left: 50%;
        top: 50%;
        //fill: white;
        width: var(--link-list__bullet-icon-size);
        height: var(--link-list__bullet-icon-size);
        transform: translate(-50%,-50%);

        fill: transparent;
        stroke: var(--color-primary);
        stroke-width: 3px;
    }

    &__link:hover &__link-arrow{
        transform: translate(0, 0) translate(0, -50%);
        opacity: 1;
    }

}