:root {
    --gallery_overlay-color: black;
    --gallery_overlay-last-opacity: 0.8;
    --gallery_overlay-opacity-hover: 0.5;

    --gallery_height: 400px;
    --gallery_width: 920px;
    --gallery_margin: 20px;

    @media screen and (max-width: 768px) {
        --gallery_height: 335px;
        --gallery_margin: 15px;
    }
    @media screen and (max-width: 500px) {
        --gallery_height: 200px;
        --gallery_margin: 10px;
    }
}

.gallery {
    display: flex;
    flex-flow: row nowrap;
    max-width: var(--gallery_width);
    height: var(--gallery_height);


    &__left {
        flex: 1;
        margin-right: var(--gallery_margin);
    }

    &__right {
        display: flex;
        flex-flow: column nowrap;
        justify-content: space-between;
        width: calc( (var(--gallery_height) - var(--gallery_margin)) / 2 );
    }

    &__block {
        position: relative;
        height: 100%;
    }

    &__block:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: var(--gallery_overlay-color);
        opacity: 0;
        transition: opacity 300ms;
        pointer-events: none;
    }

    &__link {
        display: block;
        height: 100%;
        outline: none;
    }
    
    &__block { display: none; }
    &__block-1, &__block-2, &__block-3 { display: block; }
    &__block-1 .gallery__link, &__block-2 .gallery__link, &__block-3 .gallery__link { display: block; height: 100%; }
    &__block-2, &__block-3 {
        height: calc( (var(--gallery_height) - var(--gallery_margin)) / 2 );
    }
    &__block-3:before{ opacity: var(--gallery_overlay-last-opacity); }
    &__block:hover:before { opacity: var(--gallery_overlay-opacity-hover); }

    &__picture {
        width: 100%;
        height: 100%;
    }

    &__plus {
        position: absolute;
        width: 20px;
        height: 20px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        pointer-events: none;

        &::before,
        &::after{
            content: '';
            position: absolute;
            background-color: white;
            height: 2px;
            width: 100%;
            transform: translate(-50%,-50%) rotate(0deg);
            top: 50%;
            left: 50%;
        }
        &::after{
            transform: translate(-50%,-50%) rotate(90deg);
        }
    }
}
