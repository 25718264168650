.thumb-event{
    display: block;
    text-decoration: none;
    color: var(--color-primary);
    
    &__content{
       padding-top: var(--y-padding-40); 
    }

    &__title{
        font-size: 4rem;
        line-height: 1.25em; //50/40

        @media screen and (max-width: 1024px) {
            font-size: 3rem;
        }
    }

    &__category{
        font-size: 1.8rem;
        line-height: 1.15em;
        font-weight: 500;
        padding-top: var(--y-padding-30); 
    }
}