.social{

    display: flex;

    &__item{
        margin-left: 20px;
        &:first-child{ margin-left: 0; }
    }

    &__link{
        border-radius: 50%;
        width: 45px;
        height: 45px;
        display: block;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            transform: translate(-50%,-50%);
            background-color: transparent;
            border: 1px solid var(--color-line);
            border-radius: 50%;
            z-index: 1;
            transition: transform 0.3s, background-color 0.3s;
        }
    }

    &__svg{
        position: absolute;
        width: 16px;
        height: 16px;
        fill: var(--color-primary);
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        transition: fill 0.3s;
        z-index: 2;
    }

    &__link:hover{
        .social__svg{
            fill: white;
        }

        &::after{
            background-color: var(--color-primary);
            border: 1px solid var(--color-primary);
            transform: translate(-50%,-50%) scale(1.1);
        }
    }
}