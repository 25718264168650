:root{
    --filter-section__padding-bottom: 160px;

    @media screen and (max-width: 1300px){
        --filter-section__padding-bottom: 200px;
    }

    @media screen and (max-width: 1024px){
        --filter-section__padding-bottom: 240px;
    }

    @media screen and (max-width: 768px){
        --filter-section__padding-bottom: 280px;
    }
}

.filter-section{
    @extend .y-padding-70;
    padding-bottom: var(--filter-section__padding-bottom);

    &__title{
        text-align: center;
        color: var(--color-primary);
        @extend .huge-title;
        @extend .bottom-padding-70;

        @media screen and (max-width: 500px){
            padding-bottom: 10px;
        }
    }

    &__call-to-action{
        position: absolute;
        right: var(--x-padding-40);
        top: 44px;
        @media screen and (max-width: 768px){
            display: none;
        }
    }

    &--less-bottom-padding{
        padding-bottom: var(--y-padding-70);
        @media screen and (max-width: 500px){
            padding-bottom: 10px;
        }
    }
}