:root{
    --hamburger-line-color: var(--color-primary);
    --hamburger-line-color-hover: white;
}
.dark-header{
    --hamburger-line-color: white;
    --hamburger-line-color-hover: var(--color-primary);
}

.hamburger {
    position: absolute;
    width: 14px;
    height: 12px;
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &__line {
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background: var(--hamburger-line-color);
        opacity: 1;
        left: 0%;
        transform: rotate(0deg);
        transition: all 0.3s;

        &:nth-child(1) { top: 0px; }
        &:nth-child(2), &:nth-child(3) { top: 5px; }
        &:nth-child(4) { top: 10px; }
    }
}

.hamburger-hover:hover{
    .hamburger {
        .hamburger__line {
            background: var(--hamburger-line-color-hover);
        }
    }
}