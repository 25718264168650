:root{
    --overlay-search__padding-left: 320px;
    --overlay-search__cta-width: 355px;
    --overlay-search__cta-gradient-opacity: 0.7;


}

.overlay-search {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    overflow: hidden;
    z-index: 50;

    visibility: hidden;
    pointer-events: none;

    &__background{
        opacity: 0;
        transition: opacity 0.5s;
    }
    
    &__container {
        position: absolute;
        left: var(--overlay-search__padding-left);
        bottom: 100%;
        top: 0;
        right: 0;
        background: white;
        overflow: hidden;
        transition: bottom 0.6s $easeInOutQuart;

        @media screen and (max-width: 1024px) {
            left: 0;
        }

        @media screen and (max-width: 768px) {
            overflow-y: auto;
            flex-direction: column;

            // Cacher la scrollbar
            -ms-overflow-style: none;
            scrollbar-width: none;
            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    &__container-flex {
        display: flex;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            min-height: 100%;
        }
    }

    &__main {
        flex-grow: 1;
        height: 100vh;
        overflow-y: auto;

        // Cacher la scrollbar
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar {
            display: none;
        }

        @media screen and (max-width: 768px) {
            height: auto;
            overflow-y: visible;
        }
    }

    &__main-wrapper {
        @extend .x-padding-120;
        @extend .y-padding-150;
        opacity: 0;
        transform: translateY(-100px);
        transition: transform 800ms $easeInOutQuart, opacity 800ms $easeInOutQuart;

        @media screen and (max-width: 1200px) {
            padding: var(--y-padding-50) var(--x-padding-50);
        }

        @media screen and (max-width: 1024px) {
            padding-top: 140px;
        }

        @media screen and (max-width: 768px) {
            padding-top: 120px;
            padding-bottom: 70px;
        }
    }

    &__title {
        font-size: 4rem;
        color: var(--color-primary);
        line-height: 1.275em; //51/40
        font-weight: 500;

        @media screen and (max-width: 600px) {
            font-size: 3rem;
        }
    }

    &__form {
        @extend .y-padding-50;
        margin-left: -30px;
        margin-right: -30px;

        @media screen and (max-width: 768px) {
            margin-left: -10px;
            margin-right: -10px;
        }
    }

    &__results-item {
        &:not(:first-child){
            margin-top: 25px;
        }
    }

    &__results-link {

        font-size: 2.5rem;
        color: var(--color-primary);
        text-decoration: none;
        opacity: 1;
        transition: opacity 0.3s ease;

        @media screen and (max-width: 768px) {
            font-size: 2rem;
        }

        mark{
            background-color: var(--color-secondary-2);
            color: var(--color-primary);
            font-style: normal;
            font-weight: normal;
        }

        &:hover{
            opacity: 0.5;
        }
    }

    &__button {
        @extend .top-padding-50;
    }

    &__map-cta {
        width: var(--overlay-search__cta-width);
        position: relative;
        overflow: hidden;
        text-decoration: none;
        flex-shrink: 0;

        @media screen and (max-width: 768px) {
            width: 100%;
            display: block;
        }
    }

    &__map-cta-text {
        position: absolute;
        z-index: 2;
        bottom: 0;
        padding: 60px 45px;
        transition: transform 0.3s $easeInOutQuart;

        @media screen and (max-width: 768px) {
            padding-left: var(--x-padding-50);
            padding-right: var(--x-padding-50);
            position: relative;
            padding: 30px 45px;
        }
    }

    &__map-title{
        color: white;
        font-size: 3.4rem;
        line-height: 1.1176em;//38/34
        font-weight: bold;

        @media screen and (max-width: 1200px) {
            font-size: 2.4rem;
        }

        @media screen and (max-width: 768px) {
            font-size: 2.8rem;
        }
    }

    &__map-button{
        display: flex;
        font-size: 1.4em;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.1em;
        padding-top: 30px;

        @media screen and (max-width: 768px) {
            padding-top: 20px;
        }
    }

    &__map-button-text{
        color: white;
        padding-right: 15px;
        transform: translate(0,0);
        transition: transform 0.3s ease;
    }

    &__map-button-svg{
        fill: white;
        width: 18px;
        height: 16px;
    }

    &__map-cta-gradient {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        z-index: 1;
        background: linear-gradient( 0deg , rgba(30, 30, 30, var(--overlay-search__cta-gradient-opacity)) 0%, rgba(36, 36, 36, 0) 100%);

        @media screen and (max-width: 768px) {
            height: 100%;
        }
    }

    &__map-cta-image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        transform: scale(1);
        transition: transform 0.3s ease;
    }

    &__map-cta:hover &__map-cta-text {
        transform: translate(0,-10px);
        @media screen and (max-width: 768px) {
            transform: none !important;
        }
    }
    &__map-cta:hover &__map-cta-image {
        transform: scale(1.05);
    }
}