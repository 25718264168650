:root {
    --document__font-size: 3rem;
    --document__subtitle-font-size: 2rem;

    --document__in-rubric-font-size: 1.8rem;
    --document__in-rubric-subtitle-font-size: 1.5rem;

    --document__height: 170px;
    --document__in-rubric-height: 64px;
    --document__thumb-width: 150px;
    --document__thumb-height: 150px;
    --document__thumb-width-rubric: 85px;
    --document__thumb-height-rubric: 85px;

    --document__color: rgba(var(--color-primary-rgb),1);
    --document__color-hover:rgba(var(--color-primary-rgb),0.6);

    @media screen and (max-width: 1024px) {
        --document__font-size: 22px;
        --document__height: 120px;
    }

    @media screen and (max-width: 768px) {
        --document__font-size: 2.1rem;
        --document__subtitle-font-size: 1.6rem;
        --document__height: 100px;
    }
}


// Documents normal
.documents {

    .document {
        position: relative;
        border-bottom: 1px solid var(--color-primary);
    
        &:first-child { border-top: 1px solid var(--color-primary); }
    
        &__link {
            position: relative;
            cursor: pointer;
            min-height: var(--document__height);
            display: flex;
            align-items: center;
            text-decoration: none;
            padding: 20px 40px;
            padding-right: 0;
        
            @media screen and (max-width: 1024px) {
                padding: 20px 30px;
                padding-right: 0;
            }
        
            @media screen and (max-width: 768px) {
                padding: 20px 10px;
                padding-right: 0;
            }
        }

        &__picture {
            width: var(--document__thumb-width);
            height: var(--document__thumb-height);
            margin-right: 40px;
            overflow: hidden;
            flex-shrink: 0;
            border-radius: 50%;
            -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
            @media screen and (max-width: 600px)  { display: none; }
        }
        &__picture-img {
            transform: scale(1);
            transition: transform 0.3s;
        }

        &__content {
            padding-right: 75px;
        }

        &__subtitle {
            margin-bottom: 6px;
            font-size: var(--document__subtitle-font-size);
            color: var(--document__color);
            line-height: 1.3em;
            transition: color 0.3s;
        }
    
        &__title {
            line-height: 1.2;
            color: var(--document__color);
            font-size: var(--document__font-size);
            transition: color 0.3s;
        }
    
        &__icon {
            position: absolute;
            top: 50%;
            right: 40px;
            transform: translateY(-50%);
        }
    
        &__svg {
            position: absolute;
            width: 20px;
            height: 20px;
            top: 50%;
            left: 50%;
            fill: var(--document__color);
            transform: translate(-50%, -50%);
            transition: transform 300ms $easeOutCubic, stroke 300ms;
            transition: fill 0.3s;
        }

        &__link:hover{
            .document__picture-img{
                transform: scale(1.1);
            }
            .document__subtitle{
                color: var(--document__color-hover);
            }
            .document__title{
                color: var(--document__color-hover);
            }
            .document__svg {
                fill: var(--document__color-hover);
            }
        }
    }

    &--in-rubric{
        max-width: 920px;
        .document{
            border: 0;
            margin-top: 8px;
            &:first-child{ border: 0; margin-top: 0; }

            .document__picture {
                width: var(--document__thumb-width-rubric);
                height: var(--document__thumb-height-rubric);
                z-index: 1;
                font-weight: normal;
                margin-right: 30px;
            }
            .document__link {
                padding: 25px 30px;
                min-height: var( --document__in-rubric-height);
                background-color: white;
                border-radius: 5px;
                overflow: hidden;
                &::after{
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    height: 0;
                    background-color: var(--color-primary);
                    transition: height 0.3s $easeOutQuart;
                    z-index: 0;
                }
            }
            .document__content{
                z-index: 1;
                font-weight: normal;
            }
            .document__subtitle{
                font-size: var(--document__in-rubric-subtitle-font-size);
            }
            .document__title {
                font-size: var(--document__in-rubric-font-size);
                color: var(--color-primary);
                position: relative;
                font-weight: normal;
            }
            .document__icon {
                z-index: 1;
            }
            .document__svg {
                width: 18px;
                height: 18px;
                fill: var(--color-primary);
            }
            .document__link:hover {
                .document__subtitle,
                .document__title{
                    color: white;
                }
                .document__svg {
                    fill: white;
                }
                &::after{
                    height: 100%;
                }
            }
        }
    }
}