:root {
    // Input and textarea
    --field-label-padding: 0 0 12px 0;

    --field-label-font-family: var(--font-main);
    --field-label-font-size: 1.5rem;
    --field-label-font-weight: normal;
    --field-label-line-height: 1;
    --field-label-text-transform: inherit;
    --field-label-letter-spacing: inherit;
    --field-label-color: var(--color-primary);

    --field-note-color: var(--color-primary);

    --field-padding: 20px 30px;

    --field-font-family: var(--font-main);
    --field-font-size: 1.6rem;
    --field-font-weight: normal;
    --field-line-height: 1.6;
    --field-text-transform: inherit;
    --field-letter-spacing: inherit;

    --field-color: var(--color-primary);
    --field-border-color: var(--color-line);
    --field-background: transparent;
    --field-autofill-background: white;

    --field-background-inactive: transparent;
    --field-color-inactive: #b2b2b2;

    --field-error-color: #e90000;

    //@media screen and (max-width: 600px) { --field-padding: 15px 20px; }


    // Radio and checkbox
    --box-dimension: 24px;
    --box-margin-right: 15px;

    --radio-checkbox-font-family: var(--font-main);
    --radio-checkbox-font-size: 2rem;
    --radio-checkbox-font-weight: normal;
    --radio-checkbox-line-height: 1.3em;
    --radio-checkbox-text-transform: inherit;
    --radio-checkbox-letter-spacing: inherit;
    --radio-checkbox-color: var(--color-primary);

    --radio-box-background: white;
    --radio-box-background-active: white;
    --radio-hook-dimension: 12px;

    --radio-dot-dimension: 8px;
    --radio-dot-color: var(--color-primary);
    --radio-dot-border: 1px solid var(--color-line);
    --radio-dot-border-active: 1px solid var(--color-line);

    --checkbox-box-background: white;
    --checkbox-box-background-active: var(--color-primary);
    --checkbox-border: 1px solid var(--color-line);
    --checkbox-border-active: 1px solid var(--color-primary);


    // Files
    --file-padding: 30px 60px 30px 40px;

    --file-font-family: var(--font-main);
    --file-font-size: 2rem;
    --file-font-weight: normal;
    --file-line-height: 1.5;
    --file-text-transform: inherit;
    --file-letter-spacing: inherit;
    --file-background-color: transparent;
    --file-color: var(--color-primary);
    --file-border-color: var(--color-line);
    --file-over-color: var(--color-primary);

    --file-x-svg-dimension: 18px;
    --file-x-svg-top: calc(50% - (var(--file-x-svg-dimension) / 2));
    --file-x-svg-right: 20px;

    --file-x-color: var(--file-color);
    --file-x-over-color: var(--file-over-color);

    --file-svg-color: var(--file-color);
    --file-svg-over-color: var(--file-over-color);


    // Select
    --tail-label-padding: 20px 30px;
    --tail-label-font-family: var(--font-main);
    --tail-label-font-size: 1.6rem;
    --tail-label-font-weight: normal;
    --tail-label-line-height: 1.5;
    --tail-label-text-transform: inherit;
    --tail-label-letter-spacing: inherit;

    --tail-label-color: var(--color-primary);
    --tail-label-color-active: var(--color-primary);
    --tail-label-background: transparent;
    --tail-label-background-active: white;
    --tail-label-background-hover: transparent;
    --tail-label-border: 1px solid var(--color-line);
    --tail-label-transition: background 500ms, border-color 500ms;

    --tail-label-pointer-color: var(--color-primary);
    --tail-label-pointer-color-active: white;

    --tail-option-padding: 20px 30px;
    --tail-option-font-family: var(--font-main);
    --tail-option-font-size: 1.6rem;
    --tail-option-font-weight: 500;
    --tail-option-line-height: 1.3;
    --tail-option-text-transform: inherit;
    --tail-option-letter-spacing: inherit;

    --tail-option-color: rgba(var(--color-primary-rgb), 0.6);
    --tail-option-background: white;

    --tail-option-color-hover: rgba(var(--color-primary-rgb), 1);
    --tail-option-background-hover: transparent;

    --tail-option-color-selected: rgba(var(--color-primary-rgb), 1);
    --tail-option-background-selected: transparent;

    --tail-scroll-color: var(--color-primary);

    --field-title-color: var(--color-primary);


    // Timepicker
    --timepicker-color: var(--color-primary);

}

.dark{
    --field-label-color: white;
    --field-color: white;
    --field-color-inactive: rgba(255,255,255,0.6);
    --field-border-color: white;
    --tail-label-border: 1px solid rgba(255,255,255,0.3);
    --field-autofill-background: var(--color-primary);

    --field-error-color: rgb(251,125,104);

    --file-color: white;
    --file-over-color: rgba(255,255,255,0.6);
    --file-background-color: rgba(255,255,255,0.1);
    --file-border-color: rgba(255,255,255,0);
    --file-svg-color: white;
    --file-svg-over-color: rgba(255,255,255,0.6);
    --file-x-color: white;
    --file-x-over-color: var(--file-over-color);

    --radio-box-background: var(--color-primary);
    --radio-box-background-active: var(--color-secondary);
    --radio-checkbox-color: var(--color-primary);
    --radio-dot-border: 1px solid white;
    --radio-checkbox-color: white;

    --checkbox-box-background: var(--color-primary);
    --checkbox-box-background-active: var(--color-secondary);
    --checkbox-border: 1px solid white;

    --tail-label-pointer-color: white;
    --tail-label-pointer-color-active: var(--color-primary);
    --tail-label-color: white;
    --tail-scroll-color: rgba(255,255,255,0.6);
    --tail-label-border: 1px solid white;

    --field-title-color: white;

}

.field {
    position: relative;
    margin-bottom: 50px;

    @media screen and (max-width: 1024px) { margin-bottom: 50px; }
    @media screen and (max-width: 768px) { margin-bottom: 40px; }

    // Erreur
    &__error {
        margin-top: 8px;
        font: 1.4rem/1 var(--field-font-family);
        color: var(--field-error-color);
    }

    &__note.dynamic{
        margin-top: 8px;
        color: var(--field-note-color);
        font-size: rem(14);
        p,li,table{
            font-size: inherit;
        }
    }

    // Placeholder
    ::-webkit-input-placeholder { color: var(--field-color-inactive); }
    ::-moz-placeholder { color: var(--field-color-inactive); }
    :-ms-input-placeholder {  color: var(--field-color-inactive); }
    :-moz-placeholder { color: var(--field-color-inactive); }

    // Titres
    &--title{
        @extend .small-title;
        margin-bottom: 30px;
        color: var(--field-title-color);
    }

    // Input, textarea
    &--input &__input,
    &--textarea &__input {
        width: 100%;
        padding: var(--field-padding);
        font-family: var(--field-font-family);
        font-size: var(--field-font-size);
        font-weight: var(--field-font-weight);
        line-height: var(--field-line-height);
        text-transform: var(--field-text-transform);
        letter-spacing: var(--field-letter-spacing);
        color: var(--field-color);
        background: var(--field-background-inactive);
        border: 1px solid var(--field-border-color);
        border-radius: 10px;
    }

    &--input &__label,
    &--textarea &__label {
        display: block;
        padding: var(--field-label-padding);
        font-family: var(--field-label-font-family);
        font-size: var(--field-label-font-size);
        font-weight: var(--field-label-font-weight);
        line-height: var(--field-label-line-height);
        text-transform: var(--field-label-text-transform);
        letter-spacing: var(--field-label-letter-spacing);
        color: var(--field-label-color);
    }

    &--input &__input.error,
    &--textarea &__input.error {
        border-color: var(--field-error-color);
        color: var(--field-error-color);
    }

    // Cacher l'icone de calendrier pour les champs "date" et l'icone d'horloge pour les champs "time"
    &--input &__input {
        &[type="time"],
        &[type="date"] {
            &::-webkit-calendar-picker-indicator {
                display: none;
            }

            &:in-range::-webkit-datetime-edit-year-field,
            &:in-range::-webkit-datetime-edit-month-field,
            &:in-range::-webkit-datetime-edit-day-field,
            &:in-range::-webkit-datetime-edit-text {
                color: transparent;
            }
        }
    }

    // Textarea
    &--textarea &__input {
        outline: none;
        resize: none;
        min-height: 50px;
        overflow: hidden;
    }

    // Checkbox et radio
    &--checkbox &__main-label,
    &--radio &__main-label {
        @extend .small-title;
        display: block;
        margin-bottom: 30px;
        color: var(--field-title-color);
    }

    &--checkbox &__label,
    &--radio &__label {
        display: flex;
        align-items: center;
		position: relative;
        margin-bottom: 20px;
		cursor: pointer;
    }

    &--checkbox &__note,
    &--radio &__note {
        margin-top: -15px;
        margin-bottom: 25px;
        font-size: 1.5rem;
        color: var(--field-title-color);
    }

    &--checkbox &__options,
    &--radio &__options {
        & :last-child{
            margin-bottom: 0;
        }
    }

    &--checkbox-inline &__label,
    &--radio-inline &__label {
        display: inline-flex;
        margin: 0 30px 20px 0;
    }

    &--checkbox-inline &__options,
    &--radio-inline &__options {
        margin-bottom: -20px;
    }


    &--checkbox &__text,
    &--radio &__text {
        flex: 1;
        font-family: var(--radio-checkbox-font-family);
        font-size: var(--radio-checkbox-font-size);
        font-weight: var(--radio-checkbox-font-weight);
        line-height: var(--radio-checkbox-line-height);
        text-transform: var(--radio-checkbox-text-transform);
        letter-spacing: var(--radio-checkbox-letter-spacing);
        color: var(--radio-checkbox-color);
        order: 2;
        user-select: none;
    }

    &--checkbox &__link {
        color: var(--dynamic-main-color);
        text-decoration: none;
        box-shadow: inset 0 -0.1em 0 0 var(--color-secondary);
        transition: box-shadow 0.3s;

        &:hover{
            box-shadow: inset 0 -1.1em 0 0 var(--color-secondary);
        }
    }

    &--checkbox &__input,
    &--radio &__input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    &--checkbox &__input:checked ~ &__box{
        background: var(--checkbox-box-background-active);
        border: var(--checkbox-border-active);
    }

    &--radio &__input:checked ~ &__box {
        background: var(--radio-box-background-active);
        border: var(--radio-dot-border-active);
    }

    &--checkbox &__input:checked ~ &__box:before,
    &--radio &__input:checked ~ &__box:before {
        transform: translate(-50%, -50%) scale(1);
    }

    &--checkbox &__box,
    &--radio &__box {
        position: relative;
        width: var(--box-dimension);
        height: var(--box-dimension);
        margin-right: var(--box-margin-right);
        order: 1;
        transition: background 225ms, border 225ms;

        &:before {
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%) scale(0);
            transition: transform 225ms;
        }
    }


    // Checkbox
    &--checkbox &__box {
        border-radius: 3px;
        border: var(--checkbox-border);
        background: var(--checkbox-box-background);
        &::before {
            width: var(--radio-hook-dimension);
            height: var(--radio-hook-dimension);
            background: url('../medias/images/icons/checkmark.svg') no-repeat center / contain;
        }
    }

    // Radio
    &--radio &__box {
        border-radius: 50%;
        border: var(--radio-dot-border);
        background: var(--radio-box-background);
        &::before {
            width: var(--radio-dot-dimension);
            height: var(--radio-dot-dimension);
            background: var(--radio-dot-color);
            border-radius: 50%;
        }
    }

    // File
    &--file &__input {
        position: absolute;
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        z-index: -1;
    }

    &--file &__label {
        display: block;
        position: relative;
        width: 100%;
        padding: var(--file-padding);
        cursor: pointer;
        background-color: var(--file-background-color);
        transition: color 300ms, background 300ms;
        border: 1px solid var(--file-border-color);
        border-radius: 10px;
    }

    &--file &__text {
        font-family: var(--file-font-family);
        font-size: var(--file-font-size);
        font-weight: var(--file-font-weight);
        line-height: var(--file-line-height);
        text-transform: var(--file-text-transform);
        letter-spacing: var(--file-letter-spacing);
        color: var(--file-color);
        transition: color 300ms;
    }

    &--file &__svg {
        position: absolute;
        width: var(--file-x-svg-dimension);
        height: var(--file-x-svg-dimension);
        top: var(--file-x-svg-top);
        right: var(--file-x-svg-right);
        fill: var(--file-svg-color);
        transition: fill 300ms, opacity 300ms;
    }

    &--file &__x {
        position: absolute;
        width: var(--file-x-svg-dimension);
        height: var(--file-x-svg-dimension);
        top: var(--file-x-svg-top);
        right: var(--file-x-svg-right);
        opacity: 0;
        z-index: 1;
        cursor: pointer;
        pointer-events: none;
        transition: opacity 300ms, visibility 0ms ease 300ms;
    }

    &--file &__x:before, &--file &__x:after {
        content: '';
        position: absolute;
        width: 16px;
        height: 2px;
        top: 50%;
        left: 50%;
        background: var(--file-x-color);
        transition: background 300ms;

        @media screen and (max-width: 600px) { width: 20px; }
    }

    &--file &__x:before {
        transform: translate(-50%, -50%) rotate(45deg);
    }

    &--file &__x:after {
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &--file[data-file]:not([data-file=""]) &__label {
        pointer-events: none;
    }

    &--file[data-file]:not([data-file=""]) &__svg {
        opacity: 0;
    }

    &--file[data-file]:not([data-file=""]) &__x {
        opacity: 1;
        visibility: visible;
        pointer-events: all;
    }

    &--file &__label:hover &__text {
        color: var(--file-over-color);
    }

    &--file &__label:hover &__svg {
        fill: var(--file-svg-over-color);
    }

    &--file &__x:hover:before, &--file &__x:hover:after {
        background: var(--file-x-over-color);
    }


    // Select
    &--select &__label {
        display: block;
        padding: var(--field-label-padding);
        font-family: var(--field-label-font-family);
        font-size: var(--field-label-font-size);
        font-weight: var(--field-label-font-weight);
        line-height: var(--field-label-line-height);
        color: var(--field-label-color);
    }

    &--select .tail-select {
        position: relative;
        z-index: 1;

        svg {
            position: absolute;
            width: 14px;
            height: 14px;
            top: 50%;
            right: 30px;
            transform: translateY(-50%);
            transition: transform 500ms, fill 500ms;
            fill: var(--tail-label-pointer-color);
        }
    }

    &--select .select-label {
        padding: var(--tail-label-padding);
        border: var(--tail-label-border);
        border-radius: 10px;
        background: var(--tail-label-background);
        transition: var(--tail-label-transition);
        cursor: pointer;
        user-select: none;

        &:hover { background: var(--tail-label-background-hover); }
    }

    &--select .label-inner {
        display: block;
        position: relative;
        font-family: var(--tail-label-font-family);
        font-size: var(--tail-label-font-size);
        font-weight: var(--tail-label-font-weight);
        line-height: var(--tail-label-line-height);
        text-transform: var(--tail-label-text-transform);
        letter-spacing: var(--tail-label-letter-spacing);
        color: var(--tail-label-color);
    }

    &--select .select-dropdown {
        position: absolute;
        width: 100%;
        top: 0%;
        left: 0;
        opacity: 0;
        background: #fff;
        border-top: 1px solid var(--color8);
        z-index: -1;
        visibility: hidden;
        pointer-events: none;
        transition: top 500ms var(--select-ease), opacity 500ms var(--select-ease), visibility 0ms ease 500ms;
        border-radius: 0 0 10px 10px;
    }

    &--select .dropdown-inner {
        overflow: auto;
        max-height: 195px;
        border-radius: 0 0 10px 10px;

        &::-webkit-scrollbar{
            -webkit-appearance: none;
            width: 5px;
            height: 5px;
            background-color: transparent;
        }
        &::-webkit-scrollbar-thumb{
            background-color: var(--tail-scroll-color);
        }
    }

    &--select .dropdown-option {
        padding: var(--tail-option-padding);
        font-family: var(--tail-option-font-family);
        font-size: var(--tail-option-font-size);
        font-weight: var(--tail-option-font-weight);
        line-height: var(--tail-option-line-height);
        text-transform: var(--tail-option-text-transform);
        letter-spacing: var(--tail-option-letter-spacing);
        color: var(--tail-option-color);
        background: var(--tail-option-background);
        cursor: pointer;
        transition: background 300ms, color 300ms;
        user-select: none;

        &:hover {
            color: var(--tail-option-color-hover);
            background: var(--tail-option-background-hover);
        }

        &.selected {
            color: var(--tail-option-color-selected);
            background: var(--tail-option-background-selected);
        }
    }

    // Select natif
    &--select select {
        width: 100%;
        padding: var(--tail-label-padding);
        font-family: var(--tail-label-font-family);
        font-size: var(--tail-label-font-size);
        font-weight: var(--tail-label-font-weight);
        line-height: var(--tail-label-line-height);
        text-transform: var(--tail-label-text-transform);
        letter-spacing: var(--tail-label-letter-spacing);
        color: var(--tail-label-color);
        border: var(--tail-label-border);
        border-radius: 10px;
        background: transparent;
        outline: none;
        -webkit-appearance: none;
        background: url('../medias/images/icons/pointer.svg') no-repeat calc(100% - 20px) 50% / 12px 12px;
    }

    select::-ms-expand { display: none; }

    &--select .tail-select.active {

        svg {
            transform: translateY(-50%) rotate(-180deg);
            fill: var(--tail-label-pointer-color-active);
        }

        .select-label {
            background: var(--tail-label-background-active);
            border-radius: 10px 10px 0 0;
            border-color: white;
            border-bottom-color: var(--color-line);
        }
        .label-inner{
            color: var(--tail-label-color-active);
        }
        .select-dropdown {
            opacity: 1;
            top: 100%;
            visibility: visible;
            pointer-events: all;
            transition: top 500ms var(--select-ease), opacity 500ms var(--select-ease);
        }
    }


    // Recherche
    &--search {
        text-align: right;
    }

    &--search &__input {
        width: 100%;
        height: 60px;
        padding-right: 130px;
        font-size: 3rem;
        line-height: 1.3;
        color: var(--color-primary);
        text-align: left;
        background: transparent;
        border: none;
        border-bottom: 1px solid var(--color-tertiary-2);
        transition: border 300ms;

        @media screen and (max-width: 1024px) { font-size: 2rem; }
        @media screen and (max-width: 500px) { font-size: 1.5rem; }
    }

    &--search[data-inputvalue]:not([data-inputvalue=""]) &__x {
        opacity: 1;
        visibility: visible;
        transition: opacity 300ms;
    }

    &--search {
        @media screen and (max-width: 600px) { margin-bottom: 0; }
        ::-webkit-input-placeholder { color: rgba(var(--color-primary), 0.2); font-weight: normal; }
        ::-moz-placeholder { color: rgba(var(--color-primary), 0.2); font-weight: normal; }
        :-ms-input-placeholder { color: rgba(var(--color-primary), 0.2); font-weight: normal; }
        :-moz-placeholder { color: rgba(var(--color-primary), 0.2); font-weight: normal; }
        margin-bottom: 0;
    }

    &--search &__x {
        position: absolute;
        width: 35px;
        height: 35px;
        top: 50%;
        right: 78px;
        transform: translateY(calc(-50% + -10px ));
        opacity: 0;
        visibility: hidden;
        cursor: pointer;
        transition: opacity 300ms, visibility 0ms ease 300ms;
        background: white;
        border-radius: 50%;

        &:before, &:after {
            content: '';
            position: absolute;
            width: 16px;
            height: 1px;
            top: 50%;
            left: 50%;
            background: var(--color-primary);
        }
        &:before { transform: translate(-50%, -50%) rotate(45deg); }
        &:after  { transform: translate(-50%, -50%) rotate(-45deg); }

        @media screen and (max-width: 1024px) {
            transform: translateY(-50%);
            right: 65px;
        }
        @media screen and (max-width: 500px) {
            right: 50px;
        }
    }

    &--search &__btn {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 50%;
        right: 0;
        stroke: #fff;
        transform: translateY(calc(-50% + -10px ));
        padding: 0;

        @media screen and (max-width: 1024px) {
            width: 50px;
            height: 50px;
            transform: translateY(-50%);
        }

        @media screen and (max-width: 500px) {
            width: 40px;
            height: 40px;
        }
    }

    &--search &__search-button-svg{
        position: absolute;
        left: 50%;
        top: 50%;
        width: 18px;
        height: 18px;
        transform: translate(-50%,-50%);
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active  {
        -webkit-box-shadow: 0 0 0 100px var(--field-autofill-background) inset !important;
        border-color: var(--field-border-color) !important;
        -webkit-text-fill-color: var(--field-label-color) !important;
    }


    // Timepicker
    &--timepicker {
        .bootstrap-datetimepicker-widget table td span:hover {
            background: none !important;
        }

        .bootstrap-datetimepicker-widget table td span {
            color: var(--timepicker-color);

            &:hover {
                opacity: .6;
            }
        }

        .bootstrap-datetimepicker-widget table td {
            width: 0 !important;
        }

        .glyphicon {
            position: relative;
            top: 1px;
            display: inline-block;
            font-family: "Glyphicons Halflings";
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        .glyphicon-time::before {
            content: "\e023";
        }

        .glyphicon-chevron-left::before {
            content: "\e079";
        }

        .glyphicon-chevron-right::before {
            content: "\e080";
        }

        .glyphicon-chevron-up::before {
            content: "\e113";
        }

        .glyphicon-chevron-down::before {
            content: "\e114";
        }

        .glyphicon-calendar::before {
            content: "\e109";
        }

        .timepicker .btn {
            display: inline-block;
            margin-bottom: 0;
            line-height: 1.42857143;
            -ms-touch-action: manipulation;
            touch-action: manipulation;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            position: relative !important;

            &::before {
                content: url("../medias/images/icons/pointer-timepicker.svg");
                position: absolute !important;
                display: block;
                width: 14px;
                height: 14px;
                background-size: 16px 16px;
                background-position: center center;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                z-index: 2;
                opacity: 1;
                transition: opacity 0.3s ease;
                will-change: transform;
            }

            &[data-action='incrementHours'] {
                &::before{ transform: translate(-50%,-50%) rotate(180deg); }
            }
            &[data-action='incrementMinutes'] {
                &::before{ transform: translate(-50%,-50%) rotate(180deg); }
            }

            &:hover {
                &::before {
                    opacity: 0.5;
                }
            }
        }

        .timepicker .btn::before {
            background: transparent;
        }

        .collapse {
            display: none;
        }

        .collapse.in {
            display: block;
        }

        .dropdown-menu {
            position: absolute;
            left: 0;
            z-index: 1000;
            display: none;
            float: left;
            min-width: 160px;
            padding: 5px 0;
            margin: 2px 0 0;
            font-size: 14px;
            text-align: left;
            list-style: none;
            background-color: #f7f8f8;
            box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
            -webkit-background-clip: padding-box;
            background-clip: padding-box;
            border-radius: 4px;
        }

        .list-unstyled {
            padding-left: 0;
            list-style: none;
        }


        .bootstrap-datetimepicker-widget {
            list-style: none;
            height: 225px;
        }

        .bootstrap-datetimepicker-widget.dropdown-menu {
            display: block;
            margin: 2px 0;
            padding: 4px;
            width: 19em;
            top: calc(100% + 20px) !important;
        }

        @media (min-width: 768px) {
            .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
                width: 38em;
            }
        }

        @media (min-width: 992px) {
            .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
                width: 38em;
            }
        }

        @media (min-width: 1200px) {
            .bootstrap-datetimepicker-widget.dropdown-menu.timepicker-sbs {
                width: 38em;
            }
        }

        .bootstrap-datetimepicker-widget.dropdown-menu.bottom::before {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #ccc;
            border-bottom-color: rgba(0, 0, 0, 0.2);
            top: -7px;
            left: 7px;
        }

        .bootstrap-datetimepicker-widget.dropdown-menu.bottom::after {
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-bottom: 6px solid white;
            top: -6px;
            left: 8px;
        }

        .bootstrap-datetimepicker-widget.dropdown-menu.top::before {
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-top: 7px solid #ccc;
            border-top-color: rgba(0, 0, 0, 0.2);
            bottom: -7px;
            left: 6px;
        }

        .bootstrap-datetimepicker-widget.dropdown-menu.top::after {
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid white;
            bottom: -6px;
            left: 7px;
        }

        .bootstrap-datetimepicker-widget.dropdown-menu.pull-right::before {
            left: auto;
            right: 6px;
        }

        .bootstrap-datetimepicker-widget.dropdown-menu.pull-right::after {
            left: auto;
            right: 7px;
        }

        .bootstrap-datetimepicker-widget .list-unstyled {
            margin: 0;
        }

        .bootstrap-datetimepicker-widget a[data-action] {
            padding: 6px 0;
        }

        .bootstrap-datetimepicker-widget a[data-action]:active {
            box-shadow: none;
        }

        .bootstrap-datetimepicker-widget .timepicker-hour,
        .bootstrap-datetimepicker-widget .timepicker-minute,
        .bootstrap-datetimepicker-widget .timepicker-second {
            width: 54px;
            font-weight: bold;
            font-size: 1.2em;
            margin: 0;
        }

        .bootstrap-datetimepicker-widget button[data-action] {
            padding: 6px;
        }

        .bootstrap-datetimepicker-widget .btn[data-action="showHours"]::after {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
        }

        .bootstrap-datetimepicker-widget .btn[data-action="showMinutes"]::after {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
        }

        .bootstrap-datetimepicker-widget .btn[data-action="togglePeriod"]::after {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
            content: "Toggle AM/PM";
        }

        .bootstrap-datetimepicker-widget .btn[data-action="clear"]::after {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
            content: "Clear the picker";
        }

        .bootstrap-datetimepicker-widget .btn[data-action="today"]::after {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
            content: "Set the date to today";
        }

        .bootstrap-datetimepicker-widget .picker-switch {
            text-align: center;
        }

        .bootstrap-datetimepicker-widget .picker-switch::after {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
            content: "Toggle Date and Time Screens";
        }

        .bootstrap-datetimepicker-widget .picker-switch td {
            padding: 0;
            margin: 0;
            height: auto;
            width: auto;
            line-height: inherit;
        }

        .bootstrap-datetimepicker-widget .picker-switch td span {
            line-height: 2.5;
            height: 2.5em;
            width: 100%;
        }

        .bootstrap-datetimepicker-widget table {
            width: 100%;
            margin: 0;
        }

        .bootstrap-datetimepicker-widget table td,
        .bootstrap-datetimepicker-widget table th {
            text-align: center;
            border-radius: 4px;
        }

        .bootstrap-datetimepicker-widget table th {
            height: 20px;
            line-height: 20px;
            width: 20px;
        }

        .bootstrap-datetimepicker-widget table th.picker-switch {
            width: 145px;
        }

        .bootstrap-datetimepicker-widget table th.disabled,
        .bootstrap-datetimepicker-widget table th.disabled:hover {
            background: none;
            color: #777777;
            cursor: not-allowed;
        }

        .bootstrap-datetimepicker-widget table th.prev::after {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
            content: "Previous Month";
        }

        .bootstrap-datetimepicker-widget table th.next::after {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
            content: "Next Month";
        }

        .bootstrap-datetimepicker-widget table thead tr:first-child th {
            cursor: pointer;
        }

        .bootstrap-datetimepicker-widget table td {
            height: 54px;
            line-height: 54px;
            width: 54px;
        }

        .bootstrap-datetimepicker-widget table td.cw {
            font-size: .8em;
            height: 20px;
            line-height: 20px;
            color: #777777;
        }

        .bootstrap-datetimepicker-widget table td.day {
            height: 20px;
            line-height: 20px;
            width: 20px;
        }

        .bootstrap-datetimepicker-widget table td.day:hover,
        .bootstrap-datetimepicker-widget table td.hour:hover,
        .bootstrap-datetimepicker-widget table td.minute:hover,
        .bootstrap-datetimepicker-widget table td.second:hover {
            background: #eeeeee;
            cursor: pointer;
        }

        .bootstrap-datetimepicker-widget table td.old,
        .bootstrap-datetimepicker-widget table td.new {
            color: #777777;
        }

        .bootstrap-datetimepicker-widget table td.today {
            position: relative;
        }

        .bootstrap-datetimepicker-widget table td.today:before {
            content: "";
            display: inline-block;
            border: solid transparent;
            border-width: 0 0 7px 7px;
            border-bottom-color: #337ab7;
            border-top-color: rgba(0, 0, 0, 0.2);
            position: absolute;
            bottom: 4px;
            right: 4px;
        }

        .bootstrap-datetimepicker-widget table td.active,
        .bootstrap-datetimepicker-widget table td.active:hover {
            background-color: #337ab7;
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }

        .bootstrap-datetimepicker-widget table td.active.today:before {
            border-bottom-color: #fff;
        }

        .bootstrap-datetimepicker-widget table td.disabled,
        .bootstrap-datetimepicker-widget table td.disabled:hover {
            background: none;
            color: #777777;
            cursor: not-allowed;
        }

        .bootstrap-datetimepicker-widget table td span {
            display: inline-block;
            width: 54px;
            height: 54px;
            line-height: 54px;
            margin: 2px 1.5px;
            cursor: pointer;
            border-radius: 4px;
        }

        .bootstrap-datetimepicker-widget table td span:hover {
            background: #eeeeee;
        }

        .bootstrap-datetimepicker-widget table td span.active {
            background-color: #337ab7;
            color: #fff;
            text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
        }

        .bootstrap-datetimepicker-widget table td span.old {
            color: #777777;
        }

        .bootstrap-datetimepicker-widget table td span.disabled,
        .bootstrap-datetimepicker-widget table td span.disabled:hover {
            background: none;
            color: #777777;
            cursor: not-allowed;
        }

        .bootstrap-datetimepicker-widget.usetwentyfour td.hour {
            height: 27px;
            line-height: 27px;
        }

        .bootstrap-datetimepicker-widget.wider {
            width: 21em;
        }

        .bootstrap-datetimepicker-widget .datepicker-decades .decade {
            line-height: 1.8em !important;
        }

        .input-group.date .input-group-addon {
            cursor: pointer;
        }

        .sr-only {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            padding: 0;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
        }
    }


    // Calendar
    &--calendar {
        .datepickerWrapper {
            position: absolute;
            width: 350px;
            padding: var(--overlay-calendar__padding);
            top: calc( 100% + 20px );
            left: 0;
            background-color: var(--overlay-calendar__background-color);
            border-radius: 10px;
            box-shadow: 0 0 20px rgba(0, 0, 10, 0.2);
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
            z-index: 100;
            transition: opacity 300ms, visibility 0ms ease 300ms;

            @media screen and (max-width: 500px) { padding: 40px }
            @media screen and (max-width: 425px) { width: 100%; }

            &::after {
                content: '';
                position: absolute;
                bottom: 100%;
                left: 20%;
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid var(--overlay-calendar__background-color);
            }

            &.show {
                opacity: 1;
                visibility: visible;
                pointer-events: all;
                transition: opacity 300ms;
            }

            // Enlever l'option mois
            .datepicker-switch {
                pointer-events: none;
            }
        }
    }
}
