:root{
    --list-category-size: 1.8rem;
    --list-title-size: 4.5rem;
    --list-text-size: 2rem;

    @media screen and (max-width: 1024px){
        --list-category-size: 1.6rem;
        --list-title-size: 3.5rem;
        --list-text-size: 1.8rem; 
    }

    @media screen and (max-width: 768px){
        --list-title-size: 2.5rem;
        --list-text-size: 1.6rem; 
    }
}

.list{

    &__item{
        &:first-child{ border-top: 1px solid var(--color-primary); }
        border-bottom: 1px solid var(--color-primary);
    }

    &__link{
        @extend .y-padding-50;
        padding-left: 15px;
        padding-right: 15px;
        display: block;
        text-decoration: none;
        color: var(--color-primary);
    }

    &__category,
    &__title,
    &__text,
    &__button{
        display: block;

        mark{
            background-color: var(--color-secondary-2);
            color: var(--color-primary);
            font-style: normal;
            font-weight: normal;
        }
    }

    &__category{
        font-size: var(--list-category-size);
        font-weight: 500;
        padding-bottom: 15px;
    }

    &__title{
        font-size: var(--list-title-size);
    }

    &__text{
        @extend .top-padding-40;
        font-size: var(--list-text-size);
        line-height: 1.7em; //34/20
    }

    &__button{
        @extend .top-padding-40;
    }

}