.calendar--open{
    .overlay-calendar{
        opacity: 1;
        pointer-events: all;

        &__transition{
            transform: translate(0,0);
            opacity: 1;
        }
    }
}