:root{
    --contact-infos__gutter: 50px;

    @media screen and (max-width: 1024px){
        --contact-infos__gutter: 0px;
    }
}

.contact-infos{
    &__grid{
        display: flex;
        flex-wrap: wrap;
        margin: 0 calc(var(--contact-infos__gutter)/-2);
    }
    &__general,
    &__schedule{
        width: 50%;
        padding: 0 calc(var(--contact-infos__gutter)/2);

        @media screen and (max-width: 1024px){
            width: 100%;

            &:not(:first-child){
                padding-top: 60px;
            }
        }
    }

    &__address{
        font-size: 2.5rem;
        line-height: 1.28em; //32px/25

        @media screen and (max-width: 600px){ font-size: 1.6rem; }
    }
}