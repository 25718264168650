:root {
    --rubric__font-size: 3rem;
    --rubric__subtitle-font-size: 2rem;

    --rubric__height: 170px;
    --rubric__thumb-width: 150px;
    --rubric__thumb-height: 150px;

    --rubric__color: var(--color-primary);
    --rubric__color-hover:rgba(var(--color-primary-rgb),0.6);

    --rubric__plus-size: 35px;
    --rubric__plus-color:var(--color-primary);
    --rubric__plus-color-hover: rgba(var(--color-primary-rgb),0.6);

    @media screen and (max-width: 1024px) {
        //--rubric__font-size: 3.5rem;
        --rubric__height: 150px;
        --rubric__plus-size: 30px;
    }

    @media screen and (max-width: 768px) {
        --rubric__thumb-width: 100px;
        --rubric__thumb-height: 100px;
        --rubric__subtitle-font-size: 1.6rem;
        --rubric__height: 100px;
        --rubric__plus-size: 20px;
    }

    @media screen and (max-width: 425px) {
        --rubric__font-size: 2.3rem;
    }
}

.rubric {
    position: relative;
    border-bottom: 1px solid var(--color-primary);
    background: transparent;
    transition: border-bottom 700ms, background-color 0.3s;
    overflow: hidden;

    padding-left: 40px;
    padding-right: 40px;

    @media screen and (max-width: 1024px) {
        padding-left: 30px;
        padding-right: 30px;
    }

    @media screen and (max-width: 768px) {
        padding-left: 10px;
        padding-right: 10px;
    }

    //&:first-child { border-top: 1px solid #b2b2b2; }

    &__header {
        position: relative;
        cursor: pointer;
        padding: 20px 0;
        padding-right: 115px;
        min-height: var(--rubric__height);
        display: flex;
        align-items: center;
    }

    &__picture {
        width: var(--rubric__thumb-width);
        height: var(--rubric__thumb-height);
        margin-right: 40px;
        overflow: hidden;
        flex-shrink: 0;
        border-radius: 50%;
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

        @media screen and (max-width: 600px) { display: none; }
    }

    &__picture-img {
        transition: transform 0.3s;
        transform: scale(1);
    }

    &__subtitle {
        margin-bottom: 9px;
        font-size: var(--rubric__subtitle-font-size);
        color: var(--rubric__color);
        line-height: 1.3em;
        transition: color 0.3s;
    }

    &__title {
        font-size: var(--rubric__font-size);
        line-height: 1.2;
        color:var(--rubric__color);
        transition: color 0.3s;
    }

    &__plus {
        position: absolute;
        width: var(--rubric__plus-size);
        height: var(--rubric__plus-size);
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        transition: transform 0.4s $easeOutQuart;

        &::before,
        &::after{
            content: '';
            position: absolute;
            width: var(--rubric__plus-size);
            height: 2px;
            background-color: var(--rubric__plus-color);
            left: 50%;
            top: 50%;
            transition: background-color 0.3s, opacity 0.3s;
            transform: translate(-50%,-50%);
            opacity: 1;
        }
        &::after{
            transform: translate(-50%,-50%) rotate(90deg);
        }
    }

    &__header:hover{
        .rubric__picture-img {
            transform: scale(1.1);
        }
        .rubric__subtitle {
            color:var(--rubric__color-hover);
        }
        .rubric__title {
            color:var(--rubric__color-hover);
        }
        .rubric__plus {
            &::before,
            &::after{
                background-color: var(--rubric__plus-color-hover);
            }
        }
    }

    &__content {
        height: 0;
        will-change: height;
        position: relative;
        @media screen and (max-width: 1024px) { padding: 0; }
    }

    &__mask{
        overflow: hidden;
    }

    &__transition {
        opacity: 0;
        transform: translateY(100px);
        transition: opacity 700ms $easeInOutQuart, transform 700ms $easeInOutQuart;
    }

    &__transition > :first-child { margin-top: 0; }

    &__info{
        margin-bottom: 50px;
        padding-right: 80px;
        @media screen and (max-width: 768px) { padding-right: 60px; }
        @media screen and (max-width: 600px) { padding-right: 0; }
    }

    &__dynamic {
        padding-right: 80px;
        @media screen and (max-width: 768px) { padding-right: 60px; }
        @media screen and (max-width: 600px) { padding-right: 0; }
        h3 {
            font-size: 2.9rem;
            margin-top: 50px;
            margin-bottom: 25px;
            line-height: 1.45em;
            font-weight: 500;

            @media screen and (max-width: 1024px) { font-size: 2.32rem; } //0.8
            @media screen and (max-width: 768px) { font-size: 2.175rem; } //0.75
            @media screen and (max-width: 425px) { font-size: 1.885rem; } //0.65
        }
        h4 {
            font-size: 2.2rem;
            margin-top: 50px;
            margin-bottom: 25px;
            line-height: 1.5em;
            font-weight: bold;

            @media screen and (max-width: 1024px) { font-size: 1.696rem; } //0.8
            @media screen and (max-width: 768px) { font-size: 1.5rem; } //0.75
        }
        blockquote p:first-child{
            margin-top: 0;
        }
    }

    &__dynamic, &__document, &__gallery{
        @extend .bottom-padding-60;
    }

    &__document:last-child{
        @extend .bottom-padding-90;
    }

    &__share {
        position: absolute;
        top: -30px;
        right: 0;
        height: 0px; //Si non, ça casse l'animation des rubriques

        opacity: 0;
        transition: opacity 700ms $easeInOutQuart;

        @media screen and (max-width: 768px) { top: -10px; }
        @media screen and (max-width: 600px) { display: none; }
    }

}


.fancybox-caption__body {
    font-family: var(font-main);
}
