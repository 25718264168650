:root{
    --banner-font-size: 10rem;
    --banner-font-size-long: 7rem;

    @media screen and (max-width: 1600px) {
        --banner-font-size: 7rem;
    }

    @media screen and (max-width: 1024px) {
        --banner-font-size: 5rem;
        --banner-font-size-long: 5rem;
    }

    @media screen and (max-width: 768px) {
        --banner-font-size: 4rem;
        --banner-font-size-long: 4rem;
    }
}

.home-slider{

    margin-top: -200px;
    padding-top: 200px;
    overflow: hidden;
    position: relative;

    @media screen and (max-width: 1024px) { 
        background-color: var(--color-primary);
    }

    &__wrapper{
        padding-top: 10px;
        @extend .right-padding-40;
        @extend .bottom-padding-130;
        @extend .left-padding-120;
        z-index: 1;
        position: relative;
        min-height: calc( 100vh - 140px);

        @media screen and (min-width: 1700px) { 
            padding-right: var(--x-padding-120);
        }

        @media screen and (max-width: 1600px) { 
            min-height: auto;
        }

        @media screen and (max-width: 1400px) { 
            padding-right: var(--x-padding-120);
        }
    }

    &__grid{
        display: flex;
        flex-wrap: wrap;
    }

    &__texts{
        width: 40%;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 768px) { 
               width: 100%;
        }
    }

    &__text{
        @extend .y-padding-40;
        margin-right: -30%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        @media screen and (max-width: 768px) { 
            margin-right: 0;
            padding-top: 0;
        }
    }

    &__text-title{
        color: white;
        font-size: var(--banner-font-size);
        font-weight: bold;
        line-height: 1.15em;
    }
    &__text-title--long{
        font-size: var(--banner-font-size-long);
    }

    &__text-button{
        @extend .top-padding-40;
    }

    &__images{
        width: 60%;
        position: relative;

        @media screen and (max-width: 768px) { 
            width: 100%;
        }
    }

    &__images-wrapper{

        border-radius: 8px;
        overflow: hidden;
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

        &::after{
            content: '';
            position: absolute;
            left: 0;
            right: 70%;
            top: 0;
            bottom: 0;
            pointer-events: none;

            background: #242424;
            background: linear-gradient(90deg, rgba(#242424,0.5) 0%, rgba(36,36,36,0) 100%);

            @media screen and (max-width: 768px) { 
                right: 0;
                top: 0;
                bottom: 70%;
                background: #242424;
                background: linear-gradient(-180deg, rgba(#242424,0.5) 0%, rgba(36,36,36,0) 100%);
            }
        }
    }

    &__image{
        width: 100%;
        padding-top: 73%;
        position: relative;
    }

    &__picture,
    &__video{
        position: absolute;
        top: -8%;
        left: 0;
        right: 0;
        bottom: -8%;
    }

    &__video video{
        height: 100%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    &__image--video &__picture{
        display: none;
        @media screen and (max-width: 768px) { 
            display: block;
        }
    }
    &__image--video &__video{
        @media screen and (max-width: 768px) { 
            display: none;
        }
    }

    &__nav{
        position: absolute;
        top: calc(100% + 25px);
        right: 0;
        height: 30px;
        width: 100%;
        max-width: 285px;
        display: flex;
        align-items: center;
        color: var(--color-primary);

        @media screen and (max-width: 500px) { 
            top: calc(100% + 12px);
        }
    }

    &__nav-pager{
        padding-right: 25px;
        font-size: 1.5rem;
        font-weight: 500;
    }

    &__nav-pager-number-current{
        font-weight: bold;
        font-size: 1.8rem;
        width: 24px;
        display: inline-block;
        text-align: center;
    }

    &__nav-separation{
        height: 100%;
        flex-grow: 1;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: var(--color-line-2);
        }
    }

    &__nav-arrows{
        margin-left: 20px;
        display: flex;
        justify-content: space-between;
        height: 100%;
        width: 64px;
    }

    &__nav-arrows-prev,
    &__nav-arrows-next{
        width: 18px;
        height: 100%;
        position: relative;
        cursor: pointer;
    }

    &__nav-arrows-svg{
        fill: var(--color-primary);
        width: 18px;
        height: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        transition: fill 0.3s;
    }

    &__nav-arrows-prev &__nav-arrows-svg{
        transform: translate(-50%,-50%) rotate(180deg);
    }

    &__nav-arrows-prev:hover &__nav-arrows-svg,
    &__nav-arrows-next:hover &__nav-arrows-svg{
        fill: var(--color-line-2);
    }

    &__call-to-action{
        position: absolute;
        left: 40%;
        bottom: 60px;
        transform: translate(-50%,0);
        z-index: 4;

        @media screen and (max-width: 1024px) {
            bottom: auto;
            left: auto;
            right: 0;
            top: 0;
        }
        @media screen and (max-width: 768px) {
            bottom: 58%;
            top: auto;
        }
        @media screen and (max-width: 580px) {
            bottom: 50%;
        }
    }

    &__yt {
        z-index: 2;
        position: absolute;
        right: 0;
        bottom: 0;
        padding: 20px 20px;
        color: white;
        text-decoration: none;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: 600;
        opacity: 1;
        transition: opacity 0.3s ease;
        display: flex;
        align-items: center;

        &:hover {
            opacity: 0.6;
        }
    }

    &__yt-arrow {
        margin-right: 15px;
        width: 0;
        height: 0;
        border-top: 9px solid transparent;
        border-bottom: 9px solid transparent;
        border-left: 13.5px solid #fff;
        border-radius: 3px;
    }

    &__blue{
        position: absolute;
        background-color: var(--color-primary);
        width: 50%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: -1;

        @media screen and (max-width: 1200px) { width: 45%; }

        @media screen and (max-width: 1024px) { display: none; }
    }

    &__wave{
        position: absolute;
        left: 100%;
        height: 120%;
        top: 0;
        bottom: 0;
        width: 160px;
        overflow: hidden;
    }

    &__wave-svg{
        fill: var(--color-primary);
        //width: 100vw;
        width: 115vh;
        min-width: 1000px;
        height: 160px;
        transform: rotate(90deg) translate(0, -100%);
        transform-origin: 0 0;
    }

    &__gray{
        display: none;
        position: absolute;
        background-color: var(--color-background);
        height: 50px;
        width: 100%;
        bottom: -2px;
        z-index: 0;
        //will-change: transform;

        @media screen and (max-width: 1024px) { 
            display: block;
        }
        @media screen and (max-width: 758px) { 
            height: 200px;
        }
    }

    &__mobile-wave{
        position: absolute;
        bottom: calc(100% - 2px );
        width: 120%;
        overflow: hidden;
    }

    &__mobile-wave-svg{
        height: 100px;
        width: 100%;
        fill: var(--color-background);
    }

    .slick-list{
        height: 100%;
        display: flex;
        align-items: center;
    }
    .slick-track{
        display: flex;
        align-items: center;

        @media screen and (max-width: 768px) { 
            align-items: start;
        }
    }

}