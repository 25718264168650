.background-overlay{
    background-color: rgba(black,0.4);
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity 800ms;
    z-index: -1;
}