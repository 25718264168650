.pages-list {
    --pages-list-gap-x: 20px;
    --pages-list-gap-y: 20px;
    --pages-list-nb-columns: 3;

    @media screen and (max-width: 1024px){
        --pages-list-nb-columns: 2;
    }

    @media screen and (max-width: 768px){
        --pages-list-gap-x: 0px;
        --pages-list-nb-columns: 1;
    }
}

.pages-list{
    &__container {
        width: 100%;
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        width: calc(100% + (var(--pages-list-gap-x)));
        margin-left: calc(0px - (var(--pages-list-gap-x) / 2));
        margin-right: calc(0px - (var(--pages-list-gap-x) / 2));
        margin-bottom: calc(0px - (var(--pages-list-gap-y)));
    }

    &__list-item {
        display: flex;
        justify-content: center;
        width: calc(100% / var(--pages-list-nb-columns));
        padding: 0 calc(var(--pages-list-gap-x) / 2);
        margin-bottom: var(--pages-list-gap-y);
    }
}
