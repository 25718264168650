:root{
    --form__title-color: var(--color-primary);
}
.dark{
    --form__title-color: white;
}

.form {
    &__title {
        @extend .large-title;
        font-size: 6rem;
        margin-bottom: var(--y-padding-70);
        color: var(--form__title-color);

        @media screen and (max-width: 1024px) { font-size: 3.2rem; }
        @media screen and (max-width: 768px) { font-size: 3rem; }
        @media screen and (max-width: 425px) { font-size: 2.6rem; }
    }

    &__title-and-button-online-requests {
        display: flex;
        justify-content: space-between;
        gap: 30px 0;
        margin-bottom: var(--y-padding-70);

        @media screen and (max-width: 600px) {
            flex-direction: column;
        }

        > * {
            margin: 0;
        }
    }

    &__description {
        margin-bottom: var(--y-padding-70);
    }

    &__grid-gaps{
        width: calc(100% + 80px);
        margin-left: -40px;
        margin-right: -40px;

        @media screen and (max-width: 1200px) {
            width: calc(100% + 50px);
            margin-right: -25px;
            margin-left: -25px;
        }
        @media screen and (max-width: 1024px) {
            width: calc(100% + 30px);
            margin-right: -15px;
            margin-left: -15px;
        }
        @media screen and (max-width: 900px) {
            width: calc(100% + 10px);
            margin-right: -5px;
            margin-left: -5px;
        }
    }

    &__column {
        padding-right: 40px;
        padding-left: 40px;

        @media screen and (max-width: 1200px) {
            padding-right: 25px;
            padding-left: 25px;
        }
        @media screen and (max-width: 1024px) {
            padding-right: 15px;
            padding-left: 15px;
        }
        @media screen and (max-width: 900px) {
            padding-right: 5px;
            padding-left: 5px;
        }
    }

    &__column--align-center {
        display: flex;
        justify-content: center;
    }

    .dynamic {
        div > :first-child { margin-top: 0 !important; }
        div > :last-child { margin-bottom: 0 !important; }
    }

    .oc-loading {
        pointer-events: none;
    }
}

#applicationFormWrapper, #messageWrapper {
    h3 {
        color: white;
        font-size: 20px;
    }
}

.form-note {
    color: white;
    margin-top: 10px;
    font-size: 1.5rem;
}
