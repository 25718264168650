:root{
    --card__ratio: 127%; // 710/555
    --card__gradient-height: 350px;
    --card__gradient-opacity: 0.7;
    --card__content-padding: 50px;
    --card__content-title-size: 3.4em;

    @media screen and (max-width: 768px) {
        --card__ratio: 100%;
    }
}

.card{

    //background-color: var(--color-background);
    display: block;
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);

    width: 100%;
    padding-bottom: var(--card__ratio);

    &__content{
        color: white;
        position: relative;
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: var(--card__content-padding);
        transform: translate(0,0);
        transition: transform 0.3s $easeOutQuart;
        will-change: transform;

        @media screen and (max-width: 768px) {
            padding: 50px 30px;
        }
    }

    &__title{
        font-size: var(--card__content-title-size);
        font-weight: bold;
    }

    &__news-infos{
        font-size: 1.5rem;
        padding-bottom: 18px;
        font-weight: 500;
        display: flex;
    }

    &__news-info{
        position: relative;
        &:not(:first-child){
            padding-left: 18px;

            &::before{
                content: '';
                position: absolute;
                top: 50%;
                left: 10px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: white;
                transform: translate(-50%,-50%);
            }
        }
    }

    &__news-title{
        font-size: 2.4rem;
        font-weight: bold;
        line-height: 1.3em;
    }

    &__date-container{
        z-index: 2;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--color-secondary);
    }

    &__date{
        font-size: 1.2rem;
        color: var(--color-primary);
        padding-bottom: 18px;
        text-align: center;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: 0.1em;
        z-index: 1;
        position: relative;
    }

    &__round{
        position: absolute;
        width: 200%;
        height: 300px;
        border-radius: 50%;
        bottom: -245px;
        left: 50%;
        transform: translate(-50%,0);
        background-color: var(--color-secondary);
        z-index: 0;
        transition: transform 0.3s $easeOutQuart;
    }

    &__button{
        font-size: 1.4rem;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.1em;
        margin-top: 18px;
        padding-right: 35px;
        position: relative;
        display: inline-block;

        @media screen and (max-width: 768px) {
            margin-top: 22px;
        }
    }

    &__button-svg{
        fill: white;
        width: 18px;
        height: 16px;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0,-50%);
        transition: right 0.3s $easeOutQuart;
    }

    &__image-container{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 0;
    }

    &__image{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        transform: scale(1);
        transition: transform 0.3s;
        will-change: transform;
    }

    &__overlay{
        z-index: 1;
        position: absolute;
        left: 0;
        right: 0;
        height: var(--card__gradient-height);
        bottom: 0;
        background: linear-gradient(0deg, rgba(#1e1e1e,var(--card__gradient-opacity)) 0%, rgba(36,36,36,0) 100%);
        will-change: transform;
    }

    &--has-parallax{
        .card__image-container{
            top: -6%;
            bottom: -6%;
        }
    }

    &--news{
        --card__gradient-opacity: 0.9;
        .card__content{
            padding: 30px 35px;
        }
    }

    &--event{
        .card__image-container{
            top: 0;
            bottom: 0;
        }
    }

}

.card-hover:hover,
.card:hover{
    .card__button-svg{
        right: -10px;
    }
    .card__round{
        transform: translate(-50%, -10px);
    }

    &.card--news{
        .card__content{
            transform: translate(0, -10px);
        }
    }

    .card__image{
        transform: scale(1.06);
    }

}