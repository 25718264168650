:root{
    --page-top-subtitle-size: 4.5rem;
    --page-top-subtitle-icon-size: 36px;

    @media screen and (max-width: 1024px){
        --page-top-subtitle-size: 3.5rem;
        --page-top-subtitle-icon-size: 30px;
    }

    @media screen and (max-width: 768px){
        --page-top-subtitle-size: 2.5rem;
        --page-top-subtitle-icon-size: 26px;
    }
}

.page-top{
    @extend .x-padding-40;
    @extend .y-padding-40;
    position: relative;
    z-index: 2;

    &__wrapper{
        @extend .content-wrap;
        margin-bottom: 130px;
    }

    &__wrapper--more-margin-bottom {
        margin-bottom: 200px;

        @media screen and (max-width: 1024px) {
            margin-bottom: 130px;
        }
    }

    &__text{
        position: relative;
        padding-right: 180px;
        @media screen and (max-width: 768px){
            padding-right: 0;
        }
    }

    &__call-to-action{
        position: absolute;
        right: -120px;
        top: 0;

        @media screen and (max-width: 1450px){ right: -60px; }
        @media screen and (max-width: 1300px){ right: -30px; }
        @media screen and (max-width: 1250px){ right: 0; }
        @media screen and (max-width: 768px) { display: none; }
    }

    &__infos{
        font-size: 1.5rem;
        padding-bottom: 30px;
        font-weight: 500;
        display: flex;
        color: var(--color-primary);
    }

    &__info{
        position: relative;
        &:not(:first-child){
            padding-left: 18px;

            &::before{
                content: '';
                position: absolute;
                top: 50%;
                left: 10px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: var(--color-primary);
                transform: translate(-50%,-50%);
            }
        }
    }

    &__title{
        @extend .huge-title;
        @extend .bottom-padding-60;
        color: var(--color-primary);

        &--with-subtitle{
            padding-bottom: var(--y-padding-30);
        }
    }

    &__subtitle{
        font-size: var(--page-top-subtitle-size);
        color: var(--color-primary);
        padding-bottom: var(--y-padding-60);

        @media screen and (max-width: 1024px){ padding-bottom: 80px; }
        @media screen and (max-width: 768px){ padding-bottom: 70px; }
    }

    &__subtitle-icon{
        width: var(--page-top-subtitle-icon-size);
        height: var(--page-top-subtitle-icon-size);
        fill: var(--color-primary);
        margin-right: 30px;
    }

    &__breadcrumbs{
        position: relative;
        z-index: 3;
    }

    &__image{
        padding-top: 54.435483%; // 675px / 1240px
        position: relative;
        margin-top: -30px;
        margin-left: -80px;
        margin-right: -80px;
        border-radius: 8px;
        overflow: hidden;
        background-color: var(--color-background);
        margin-bottom: -370px;
        z-index: 2;

        @media screen and (max-width: 1350px){
            margin-left: -40px;
            margin-right: -40px;
        }

        @media screen and (max-width: 1260px){
            margin-left: -20px;
            margin-right: -20px;
        }

        @media screen and (max-width: 1024px){
            margin-bottom: -320px;
        }

        @media screen and (max-width: 768px){
            margin-left: -10px;
            margin-right: -10px;
            padding-top: 100%;
        }
    }

    &__image-picture{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    &__image-credit{
        position: absolute;
        color: white;
        font-size: 1.1rem;
        font-weight: bold;
        bottom: 0;
        right: 0;
        padding: 12px 30px;
        background-color: rgba(var(--color-primary-rgb),0.6);
    }

    &__scroll-to-form-wrapper{
        position: relative;
    }

    &__scroll-to-form{
        position: absolute;
        top: 41%;
        right: 0;
        transform: translate(50%, 0);
        z-index: 2;
        right: -80px;

        @media screen and (max-width: 1500px){ right: -40px; }
        @media screen and (max-width: 1400px){ right: -20px; top: 20%;}
        @media screen and (max-width: 1350px){ right: 20px; }
        @media screen and (max-width: 1300px){ right: 70px; }
        @media screen and (max-width: 768px){ right: 70px; top: 15%; }
    }

    &--small-title{
        .page-top__title{
            font-size: 7rem;
            @media screen and (max-width: 1300px) { font-size: 5rem; }
            @media screen and (max-width: 1024px) { font-size: 4rem; }
        }
    }

}
