.page-top-2-cols{
    @extend .x-padding-40;
    @extend .bottom-padding-40;
    margin-bottom: 130px;
    position: relative;

    @media screen and (max-width: 768px) {
        margin-bottom: 0;
    }

    &__grid{
        display: flex;
        flex-wrap: wrap;

        @media screen and (min-width: 1500px) {
            max-width: 1300px;
            margin: auto;
        }
    }

    &__image-container{
        width: 33%;
        @media screen and (max-width: 768px) {
            width: 100%;
            order: 2;
        }
    }

    &__offset{
        margin-left: -40px;
        @media screen and (min-width: 1500px) {
            margin-left: 0;
        }
        @media screen and (max-width: 768px) {
            margin-left: 0;
        }
    }

    &__image{
        position: relative;
        padding-bottom: 149%; //745/500
        width: 100%;
        border-radius: 0 10px 10px 0;
        overflow: hidden;
        -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
        z-index: 2;

        @media screen and (min-width: 1500px) {
            border-radius: 10px;
        }
        @media screen and (max-width: 768px) {
            border-radius: 10px;
            padding-bottom: 100%;
            z-index: 1;
        }
    }

    &__picture{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__text-container{
        @extend .x-padding-80;
        display: flex;
        align-items: center;
        width: 66%;
        @media screen and (max-width: 1024px) {
            padding-right: 0;
        }

        @media screen and (max-width: 768px) {
            width: 100%;
            padding-left: 0;
            padding-right: 0;
            padding-bottom: var(--y-padding-60);
        }
    }

    &__text-container--no-image {
        width: 100%;
    }

    &__infos{
        font-size: 1.5rem;
        padding-bottom: 30px;
        font-weight: 500;
        display: flex;
        color: var(--color-primary);
    }

    &__info{
        position: relative;
        &:not(:first-child){
            padding-left: 18px;

            &::before{
                content: '';
                position: absolute;
                top: 50%;
                left: 10px;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: var(--color-primary);
                transform: translate(-50%,-50%);
            }
        }
    }

    &__title{
        @extend .huge-title;
        font-size: 7rem;
        color: var(--color-primary);
        @media screen and (max-width: 1300px) { font-size: 5rem; }
        @media screen and (max-width: 1024px) { font-size: 4rem; }
    }

    &__title-newsletter {
        @media screen and (max-width: 1200px) { max-width: 525px; }
    }

    &__button{
        @extend .top-padding-60;
    }

    &__event-info{
        @extend .top-padding-60;
        @extend .bottom-padding-60;
        position: relative;
        z-index: 2;

        @media screen and (max-width: 768px){
            padding-bottom: 0;
        }
    }

    &__search-text{
        @extend .top-padding-40;
        font-size: 2rem;
        color: var(--color-primary);
        line-height: 1.7em; //34/20
    }

    &__address-search{
        @extend .top-padding-60;
    }

    &__call-to-action{
        position: absolute;
        top: 20px;
        right: var(--x-padding-40);

        @media screen and (max-width: 1500px){
            --call-to-action__size: 125px;
            --call-to-action__icon-size: 30px;
        }

        @media screen and (max-width: 1024px){
            display: none;
        }
    }
}
